import React from "react";
import { connect } from "react-redux";
import API from "../../API";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Notification from "../../components/notifications/notification";
import Tile from "../../components/tile/tile";
import { getPhoneByMask, getPhoneRaw, notify } from "../../helper";
import { setProfileEmail, setProfileInfo, setProfilePhone } from "../../store/slices/app";

class Profile_Personal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isButtonLoading: false,
        }
        console.customLog(this.props.store.app.profile)
        this.staticId = 'profilePersonal_static';
    }
    componentDidMount() {
        this.update()
    }
    update = () => {
        this.setState({ ...this.state, isLoaded: false }, () => {

            API.get('/api/profile/user-info').then(res => {
                console.customLog(res.data);
                console.customLog(this.props)
                if (res.data.id) {
                    this.props.setProfileInfo(res.data);
                    this.setState({ ...this.state, isLoaded: true })
                }
            }).catch(err => {
                this.setState({ ...this.state, isLoaded: true })
                console.customLog(err);
                notify({
                    description: 'Возникла ошибка при загрузке данных профиля',
                    type: 'warning'
                })
                // this.throwError();
            })
        })
    }
    handleClick = () => {
        this.updateData()
    }
    updateData = () => {
        console.customLog(this)
        let phone = getPhoneRaw(this.props.store.app.profile.phone)
        // let phone = ''

        // debugger

        let url = '/api/profile/change-phone/' + phone
        if(!phone){
            url = '/api/profile/reset-phone/'
        }
        
        // return
        this.setState({ ...this.state, isButtonLoading: true }, () => {
            
            API.get(url).then(res => {
                console.customLog(res)
                if (res.status === 200) {
                    API.get('/api/profile/change-email/' + this.props.store.app.profile.email + '/').then(res => {
                        console.customLog(res)
                        if (res.status === 200) {
                            this.handleSuccess(res)
                        } else {
                            this.handleError();
                        }
                    }).catch(err => {
                        this.handleError();
                        console.customLog(err)
                    })
                } else {
                    this.handleError();
                }
            }).catch(err => {
                this.handleError();
                console.customLog(err)
            })
        })
    }
    handleSuccess = (res) => {
        this.props.setProfileInfo(res.data);
        this.setState({ ...this.state, isButtonLoading: false }, () => {
            notify({
                title: 'Учётная запись',
                description: 'Данные успешно изменены',
                type: 'success'
            })
        })
    }
    handleError = () => {
        this.setState({ ...this.state, isButtonLoading: false }, () => {
            notify({
                title: 'Учётная запись',
                description: 'Возникла ошибка при сохранении данных',
                type: 'warning'
            })
        })
    }
    render() {
        return (
            <div className="tiles __small" >
                <Tile
                    isLoading={!this.state.isLoaded}
                    header={{
                        title: "Персональные данные",
                        icon: <Icon src="icon__page_profile.svg" />,
                        note: 'Лицевой счёт ' + this.props.store.app.profile.id,
                    }}
                >
                    <Form
                        footer={{
                            actions: [
                                <Button type="secondary" text="Сохранить изменения" isLoading={this.state.isButtonLoading} onClick={this.handleClick} key={this.staticId + '_btn_0'} />,
                            ]
                        }}
                    >
                        <Input
                            title={"Телефон:"}
                            type={"phone"}
                            // readonly={true}
                            // value={"+7 999 899-32-99"}
                            hardValue={this.props.store.app.profile.phone !== '0' ? getPhoneByMask(this.props.store.app.profile.phone) : ''}
                            onChange={(e) => { this.props.setProfilePhone(getPhoneRaw(e.target.value)); console.customLog(getPhoneRaw(this.props.store.app.profile.phone)) }}
                        />
                        <Input
                            title={"Адрес e-mail:"}
                            type={"text"}
                            // value={"avi401@yandex.ru"}
                            hardValue={this.props.store.app.profile.email}
                            onChange={(e) => { this.props.setProfileEmail(e.target.value) }}
                        />
                    </Form>
                </Tile>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setProfileInfo,
    setProfileEmail: setProfileEmail,
    setProfilePhone: setProfilePhone,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile_Personal);
