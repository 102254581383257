import React from 'react';
import { ReactSVG } from 'react-svg'
import API, { CALENDAR_API } from '../../API';
import Notification from '../notifications/notification';
import Status from '../status/status';
import './styles.scss';

class WorkStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'info',
            description: 'Загрузка'
        }
        this.isInited = false
    }
    getTime = () => {
        API.get('/api/service/time').then(res => {
            this.initTime(res.data.currentTime)
            // console.customLog('TIME', res.data.currentTime)
        }).catch(err => {
            // console.customLog("TIME", err)
        })
    }
    initTime = (time) => {
        let t = time.split('.')[0];
        this.date = new Date(t)
        this.date.setHours(this.date.getHours() + 3)
        console.customLog('time', time, t, this.date)

        let d = `${this.date.getFullYear()}${this.date.getMonth() + 1}${this.date.getDate()}`
        CALENDAR_API.get(d).then(res => {
            console.customLog(res.data)
            this.setTime(true, res.data)
        }).catch(err => {
            console.customLog(err)
            this.setTime(false)
        })
    }
    setTime = (type, alt = null) => {
        let isDayOff = false
        let isWorkTime = 2
        if (type) {
            if (alt) {
                isDayOff = true
            } else {
                isDayOff = false
            }
        } else {
            if (this.date.getDay() === 0 || this.date.getDay() === 6) {
                isDayOff = true
            }
        }
        if (isDayOff) {
            this.setState({ ...this.state, type: 'error', description: 'Выходной' })
        } else {
            let hour = this.date.getHours();
            // let hour = 1
            this.props.workHours.map(el => {
                if (el === hour) {
                    isWorkTime = 0
                }
            })
            if (isWorkTime === 2) {
                let start = this.props.workHours[0];
                let end = this.props.workHours[this.props.workHours.length - 1];

                if (hour > start && hour < end) {
                    isWorkTime = 1
                }
            }
            if (isWorkTime === 0) {
                this.setState({ ...this.state, type: 'success', description: 'Работает' })
            } else if (isWorkTime === 1) {
                this.setState({ ...this.state, type: 'wait', description: 'Перерыв' })
            } else if (isWorkTime === 2) {
                this.setState({ ...this.state, type: 'error', description: 'Не работает' })
            }
        }
    }
    render() {
        if (!this.isInited) {
            this.isInited = true;
            this.getTime()
        }
        return (
            <div className={'workStatus ' + this.state.type}>
                {this.state.type === 'info' ? null :
                    // <Notification
                    //     iconName="icon__notification_clock.svg"
                    //     type={this.state.type}
                    //     description={this.state.description}
                    //     show={true}
                    // />
                    <Status type={this.state.type} needIcon={true}>{this.state.description}</Status>
                }
            </div>
        )
    }
}
export default WorkStatus;
