import React from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/input/input";
import Tile from "../../../components/tile/tile";
import { ReactComponent as Brand } from "../../../assets/brand.svg"
import Button from "../../../components/button/button";
import { Navigate } from "react-router-dom";
import API, { collectJSONFromInput, simplifyJSON } from "../../../API";
import { withRouter } from "../../../withRouter";
import Notification from "../../../components/notifications/notification";
import { getNormalError } from "../../../helper";

class Recover_Password extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isPassed: false,
            isInputsError: false,
            token: '',
            notification: {
                show: false,
                icon: 'icon__notification_calendar.svg',
                type: 'success',
                description: 'Передача показаний по 26 число каждого месяца'
            }
        }
        this.staticId = 'recoverPassword_static';
        this.input_passwordRef = React.createRef();
        this.input_passwordSuccessRef = React.createRef();
    }
    handleSubmit = () => {
        this.setState({...this.state, isInputsError: false})

        console.customLog('subnit')
        let json = collectJSONFromInput([this.input_passwordRef, this.input_passwordSuccessRef])

        if(json[0].value !== json[1].value) {
            this.setState({...this.state, isInputsError: true}, () => {
                this.throwError('Пароли не совпадают')
            })
            return;
        }

        json = simplifyJSON(json)
        console.customLog(json)
        this.setState({ ...this.state, isLoading: true, notification: { show: false } }, () => {
            API.post('/auth/change-password', json).then(res => {
                console.customLog(res.data);
                if (res.data.result) {
                    this.setState({ ...this.state, isLoading: false, isPassed: true })
                }
            }).catch(err => {
                console.customLog(err);
                this.throwError(err.response.data.message);
            })
        })
    }
    throwError = (text) => {
        let error = getNormalError(text);
        this.setState({
            ...this.state, isLoading: false, notification: {
                show: true,
                icon: 'icon__page_profile.svg',
                type: 'warning',
                description: error,
            }
        })
    }
    render() {
        if (this.state.isPassed) {
            return <Navigate to={"/recover/success"} replace={true} state={{ token: this.state.token }} />
        } else {
            return (
                <Tile className={"__facelifted"} >
                    <Form
                        onSubmit={this.handleSubmit}
                        header={{
                            image: <Brand />,
                            title: "Восстановить пароль",
                            // subtitle: "Пожалуйста, придумайте новый пароль",
                            back: true,
                        }}
                        notification={<Notification
                            iconName={this.state.notification.icon}
                            type={this.state.notification.type}
                            description={this.state.notification.description}
                            show={this.state.notification.show}
                        />}
                        footer={{
                            actionsAsCol: true,
                            actions: [
                                <Button type="mainPrimary" prop="button" text="Продолжить" key={this.staticId + '_btn_0'} />
                            ]
                        }}
                    >
                        <Input
                            title={"Введите новый пароль:"}
                            type={"password"}
                            name={"password"}
                            isError={this.state.isInputsError}
                            ref={this.input_passwordRef}
                        />
                        <Input
                            title={"Подтвердите пароль:"}
                            type={"password"}
                            name={"passwordSuccess"}
                            isError={this.state.isInputsError}
                            ref={this.input_passwordSuccessRef}
                        />
                    </Form>
                </Tile>
            )
        }
    }
}

export default withRouter(Recover_Password);
