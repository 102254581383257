import React from "react";
import Button from "../button/button";
import './styles.scss';

class MobileAppsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        }
        console.customLog(this.props)
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    render() {
        let i = 0;
        return (
            <div className="mobileApp__list">
                {this.props.data.map(el => {
                    i++;
                    return <MobileApp key={'MobileApp_' + i} data={el} />
                })}
            </div>
        )
    }
}

class MobileApp extends React.Component {
    constructor(props) {
        super(props)
        console.customLog(this.props)
    }
    render() {
        return (

            <div className="mobileApp__item">
                <div className="mobileApp__item_image">
                    <img src={require('../../assets/apps/' + this.props.data.image)} />
                </div>
                <Button
                    className={"mobileApp__item_link"}
                    type={'image'}
                    target={"_blank"}
                    href={this.props.data.link.url}
                    text={
                        <img src={require('../../assets/apps/' + this.props.data.link.image)} />
                    } />
            </div>
        )
    }
}

export default MobileAppsList;
