import React, { lazy } from "react";
import Icon from "../icon/icon";
import './styles.scss';

class TileEmptyBox extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className={"tile__emptyBox"}>
                {this.props.children ? this.props.children :
                    <>
                        <Icon src={"icon__tile_empty.svg"} />
                        <span className="tile__emptyBox_title">
                            {this.props.title ? this.props.title :
                            "Нет данных по выбранному лицевому счёту"
                            }
                        </span>
                    </>
                }
            </div>
        )
    }
}

export default TileEmptyBox;
