import React from "react";
import { connect } from "react-redux";
import MobileAppsList from "../../components/apps/apps";
import PageTitle from "../../components/pageTitle/pageTitle";
import Tile from "../../components/tile/tile";
import { setBillHeaderVisibility } from "../../store/slices/app";

class MobileApps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            // isLoading: true,
            paymentHistory: {
                account: ''
            }
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
        this.props.setBillHeaderVisibility(false)
    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        return (
            <div className={"page _history" + cl}>
                <div className="container">
                    <div className="_inner">
                        <PageTitle
                            // icon={<Icon src="icon__page_profile.svg" />}
                            text={"Мобильное приложение «Свет КСК»"}
                        />

                        <div className="tiles">
                            <Tile>
                                <MobileAppsList data={[
                                    {
                                        image: 'ref__qr_app_store.svg',
                                        link: {
                                            url: "https://apps.apple.com/ru/app/%D1%81%D0%B2%D0%B5%D1%82-%D0%BA%D1%81%D0%BA/id6444723387",
                                            image: 'ref__logo_app_store.svg',
                                        }
                                    },
                                    {
                                        image: 'ref__qr_google_play.svg',
                                        link: {
                                            url: "https://play.google.com/store/apps/details?id=ru.svet.kaluga.app_ksk&pli=1",
                                            image: 'ref__logo_google_play.svg',
                                        }
                                    },
                                    {
                                        image: 'ref__qr_ru_store.svg',
                                        link: {
                                            url: "https://apps.rustore.ru/app/ru.svet.kaluga.app_ksk",
                                            image: 'ref__logo_ru_store.svg',
                                        }
                                    },
                                    {
                                        image: 'ref__qr_nash_store.svg',
                                        link: {
                                            url: "https://store.nashstore.ru/store/634e77890a39b2a3148dd2bd",
                                            image: 'ref__logo_nash_store.svg',
                                        }
                                    },
                                ]} />
                            </Tile>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setBillHeaderVisibility: setBillHeaderVisibility
    // setAppHistoryMeters: setAppHistoryMeters,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileApps);
