import React from "react";
import API, { collectJSONFromInput, simplifyJSON } from "../../API";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Tile from "../../components/tile/tile";
import { notify } from "../../helper";

class Profile_Password extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isInputsError: false
        }
        this.input_passwordRef = React.createRef();
        this.input_passwordSuccessRef = React.createRef();
        this.staticId = 'profilePassword_static';
    }

    handleSubmit = () => {
        this.setState({ ...this.state, isInputsError: false })

        // console.customLog('subnit')
        let json = collectJSONFromInput([this.input_passwordRef, this.input_passwordSuccessRef])

        if (json[0].value !== json[1].value) {
            this.setState({ ...this.state, isInputsError: true }, () => {
                this.throwError('Пароли не совпадают')
            })
            return;
        }

        json = simplifyJSON(json)
        // console.customLog(json)
        API.post('/auth/change-password', json).then(res => {
            console.customLog(res);
            if (res.status === 200) {
                if (res.data.result) {
                    // this.setState({ ...this.state, isLoading: false, isPassed: true })
                    notify({
                        title: 'Учётная запись',
                        description: 'Пароль изменён',
                        type: 'success'
                    })
                }
            }
        }).catch(err => {
            console.customLog(err);
            this.throwError(err.response.data.message);
        })
    }
    throwError = (text) => {
        notify({
            title: 'Учётная запись',
            description: text,
            type: 'warning'
        })
    }
    render() {
        return (
            <div className="tiles __small">
                <Tile
                    header={{
                        title: "Смена пароля",
                        icon: <Icon src="tiles/icon__key.svg" />,
                    }}
                >

                    <Form
                        onSubmit={this.handleSubmit}
                        footer={{
                            actions: [
                                <Button type="secondary" prop="button" text="Сохранить изменения" key={this.staticId + '_btn_0'} />,
                            ]
                        }}
                    >
                        <Input
                            title={"Новый пароль:"}
                            type={"password"}
                            name={'password'}
                            isError={this.state.isInputsError}
                            ref={this.input_passwordRef}
                        />
                        <Input
                            title={"Введить новый пароль еще раз:"}
                            type={"password"}
                            name={"passwordSuccess"}
                            isError={this.state.isInputsError}
                            ref={this.input_passwordSuccessRef}
                        />
                    </Form>
                </Tile>
            </div>
        )
    }
}

export default Profile_Password;

