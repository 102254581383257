// import { Tooltip } from "chart.js";
import React from "react";
import { getFormattedNumber, getIconByName, _ZONES } from "../../helper";
import Icon from "../icon/icon";
import Tooltip from "../tooltip/tooltip";
import './styles.scss';

class Zone extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isTooltipActive: false
        }
    }
    toggleTooltip = type => {
        this.setState({ ...this.state, isTooltipActive: type })
    }
    getElement = () => {
        let zone = '';
        // console.customLog(this.props.type)
        if (this.props.type) {
            zone = _ZONES.filter(el => el.type.toLowerCase() === this.props.type.toLowerCase())[0]
        } else if (this.props.originalType) {
            zone = _ZONES.filter(el => el.originalType.toLowerCase() === this.props.originalType.toLowerCase())[0]
        } else {
            return null
        }

        // console.customLog(this.props.type)
        // console.customLog(this.props.originalType)
        // console.customLog(zone)
        // debugger

        let value = this.props.value ? this.props.value : 0;
        let endValue = this.props.formatted ? getFormattedNumber(value) : value;

        // debugger

        return (
            <div
                className={"zoneType__item tooltip__wrapper __" + zone.type}
                onMouseEnter={() => { this.toggleTooltip(true) }}
                onMouseLeave={() => { this.toggleTooltip(false) }}
            >
                {getIconByName(zone.icon)}
                {this.props.label ?
                    <span className="zoneType__name">
                        {zone.name}
                    </span>
                    : null}
                {this.props.value !== undefined ?
                    <span className="zoneType__value" >
                        {endValue}
                    </span>
                    : null}
                {!this.props.label ?
                    <Tooltip text={zone.name} isActive={this.state.isTooltipActive} />
                    : null}
            </div>
        )
    }
    render() {
        return (
            this.getElement()
        )
    }
}

export default Zone;
