import React from "react";
import { connect } from "react-redux";
import { getFormattedDate, getFormattedPrice } from "../../helper";
import Button from "../button/button";
import Notification from "./notification";
import { _SERVICE_DATA } from "../../App";

import './styles.scss';

class Notifications extends React.Component {
    constructor(props) {
        super();
        this.innerRef = React.createRef();

        this.isInited = false;
        this.state = {
            isHidden: true,
            height: null
        }
    }
    componentDidUpdate() {
        this.check()
        this.checkHeight()
    }
    componentDidMount() {
        // this.setState({ ...this.state, height: this.height }, this.check)
        // this.setState({ ...this.state, height: this.height }, this.check)
        this.check()
        this.checkHeight()
    }
    check() {
        this.height = this.innerRef.current.clientHeight;
        // console.customLog(this.props.path, 'PATH', this.height)
        if (this.props.path === '/' + this.props.store.app.account.number) {
            if (this.state.isHidden) {
                this.setState({ ...this.state, isHidden: false }, this.checkHeight)
            }
        } else {
            if (!this.state.isHidden) {
                this.setState({ ...this.state, isHidden: true }, this.checkHeight)
            }
        }
    }
    checkHeight = () => {
        if (this.height !== this.state.height) {
            this.setState({ ...this.state, height: this.height })
        }
    }
    buildItems = () => {
        let paying
        return this.props.items.map((el, i) => {
            let id = this.props.store.app.account.number + '_notif_' + i + "_"
            let description = []
            if (el.amount) {
                description.push(<div key={id +'amount'} className={"notification_description_el"}>
                    Cумма:&nbsp;
                    <b>{getFormattedPrice(el.amount)}</b>
                </div>)
                if (el.type !== "LIMITATION")
                    paying = {account: this.props.store.app.account.number,
                      type: el.type,
                      amount: el.amount}
                else paying = null
            }
            if (el.disconnectionDate) {
                description.push(<div key={id +'disconnectionDate'} className={"notification_description_el"}>
                    Планируемая&nbsp;дата&nbsp;отключения:&nbsp;
                    <b>{getFormattedDate(new Date(el.disconnectionDate))}</b>
                </div>)
            }
            if (el.stageProcedure) {
                description.push(<div key={id +'stageProcedure'} className={"notification_description_el"}>
                    Текущий&nbsp;этап&nbsp;процедуры:&nbsp;
                    <b>{el.stageProcedure}</b>
                </div>)
            }
            if (el.startDate) {
                description.push(<div key={id +'startDate'} className={"notification_description_el"}>
                    Дата&nbsp;начала:&nbsp;
                    <b>{getFormattedDate(new Date(el.startDate))}</b>
                </div>)
            }
            return <Notification
                key={id}
                iconName={'icon__notifications_warning.svg'}
                type={'warning'}
                asWarning={true}
                title={<b>{el.title}</b>}
                description={description}
                show={true}
                paying={paying}
            />
        })
    }
    getPromo = () => {
        if (!_SERVICE_DATA.promo.isActive) {
            return
        }
        if (!this.props.store.app.account.hasIsu) {
            return
        }
        let id = this.props.store.app.account.number + 'notif_promo';
        let i = 0;
        let actions = [];
        _SERVICE_DATA.promo.actions.map(el => {
            i++;
            let _id = id + "_" + i;
            actions.push(<Button type="inline" key={_id} text={el.name} href={el.link} target="_blank" />)
        })
        return <Notification
            key={id}
            iconName={'tiles/icon__counter.svg'}
            type={'info'}
            title={<b>Для просмотра данных с интеллектуального прибора учета электрической энергии необходимо пройти регистрацию в личном кабинете интеллектуальной системы учета (ЛК ИСУ)</b>}
            actions={actions}
            show={true}
        />
    }
    render() {
        // console.customLog(this.state)
        let cl = this.state.isHidden ? " __hidden" : "";
        if (!this.props.items.length && !_SERVICE_DATA.promo.isActive) {
            cl = " __hidden"
        }
        return ( // was style={{ height: this.state.height + 'px' }}
            <div className={"notifications" + cl} >
                <div className={"_outer"} ref={this.innerRef}>
                    <div className="container">
                        <div className="_inner">
                            {this.buildItems()}
                            {this.getPromo()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
// export default Notifications;
