import React, { lazy } from "react";
import Button from "../button/button";
import WorkStatus from "../workStatus/workStatus";
import './styles.scss';
import TileInfoBox from "./tileInfoBox";

class TileContactBox extends React.Component {
    getDescription = () => {
        let i = 0;
        return this.props.schedule.map(el => {
            i++;
            return <div key={'tileContactBox_description_' + i} className={"tile__contactBox_schedule_item"}>{el.title} <b>{el.value}</b></div>
        })
    }
    render() {
        let cl = this.props.vertical ? ' __vertical' : ''
        return (
            <div className={"tile__contactBox" + cl}>
                {this.props.vertical ?
                    <>
                        <div className={"tile__contactBox_schedule"}>
                            <TileInfoBox
                                title={"Режим работы:"}
                                description={this.getDescription()}
                            />
                        </div>
                        <div className={"tile__contactBox_notification"}>
                            <WorkStatus workHours={this.props.workHours} />
                        </div>
                    </>
                    :
                    <div className={"tile__contactBox_inner"}>
                        <div className={"tile__contactBox_schedule"}>
                            <TileInfoBox
                                title={"Режим работы:"}
                                description={this.getDescription()}
                            />
                        </div>
                        <div className={"tile__contactBox_notification"}>
                            <WorkStatus workHours={this.props.workHours} />
                        </div>
                    </div>
                }
                <div className={"tile__contactBox_info"}>
                    <a className="tile__contactBox_link" href={"tel:" + this.props.phoneRaw}>{this.props.phone}</a>
                    {this.props.email &&
                        <Button type={"inline"} href={"mailto:" + this.props.email} text={this.props.email} />
                    }
                </div>
            </div>
        )
    }
}

export default TileContactBox;
