import React from "react";
import Icon from "../../components/icon/icon";
import Tile from "../../components/tile/tile";
import { connect } from "react-redux";
import Table from "../../components/table/table";
import Button from "../../components/button/button";
import { setAppHistoryPayments } from "../../store/slices/history";
import { getFormattedNumberPrev, getFormattedPrice, newFile, notify } from "../../helper";
import API from "../../API";
import { setAccountPaymentDetails, setAccountPaymentDetailsRec, setAppAccount, setProfileInfo } from "../../store/slices/app";

class HomeInfoTile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: true,
            invoiceLoading: false,
            paymentHistory: {
                account: ''
            }
        }
        this.staticId = 'tile_info_' + this.props.store.app.account.number
    }
    componentDidUpdate() {
        if (this.props.store.app.account.number !== this.state.paymentHistory.account) {
            this.state.paymentHistory.account = this.props.store.app.account.number
            this.staticId = 'tile_info_' + this.props.store.app.account.number
            this.getHistory()
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    getPaymentDetails = () => {
        API.get('/api/pay/paymentDetails/' + this.props.store.app.account.number)
            .then(res => {
                console.customLog("GET PAYMENT DETAILS", res)
                this.props.setAccountPaymentDetails(res.data)
            }).catch(err => console.customLog(err))
    }
    getUserInfo = () => {
        API.get('/api/profile/user-info').then(res => {
            if (res.data.id) {
                this.props.setProfileInfo(res.data);
            }
            this.props.onLoad()
        }).catch(err => {
            console.customLog(err);
            notify({
                description: 'Возникла ошибка при загрузке данных профиля',
                type: 'warning'
            })
        })
    }
    getHistoryPayment = () => {
        API.get('/history/payments/' + this.props.store.app.account.number).then(res => {
            console.customLog('GET ACCOUNT PAYMENT ', res)
            let [acceptedSumm, acceptedSummCount] = this.getSummAccepted(res.data)
            this.setState({ ...this.state, acceptedSumm, acceptedSummCount, isLoading: false })
        }).catch(err => {
            console.customLog('GET ACCOUNT', err)
            this.setState({ ...this.state, isLoading: false })
            notify({
                title: 'Информация о лицевом счёте',
                description: 'Ошибка при загрузке данных',
                type: 'warning'
            })
        })
    }
    getHistory = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            API.get('/api/profile/account/' + this.props.store.app.account.number).then(res => {
                // console.customLog('GET ACCOUNT ', res)
                this.props.setAppAccount(res.data)
                this.getHistoryPayment()
                this.getPaymentDetails()
                this.getUserInfo()
            }).catch(err => {
                console.customLog('GET ACCOUNT', err)
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'Информация о лицевом счёте',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })
    }
    getTitle = () => {
        console.customLog(this);
        let title = 'Переплата';
        // let deb = -this.props.store.app.paymentDetails.amount
        let deb = -(this.props.store.app.account.balance.debt + this.props.store.app.account.balance.penalty - this.props.store.app.account.balance.accepted)

        if (deb == 0) {
            title = 'Баланс'
        }
        if (deb < 0) {
            title = "Долг"
        }
        return title;
    }
    getSummAccepted = (data) => {
        let law = false
        let summ = 0
        let count = 0;
        data.map(el => {
            if (!law) {
                // if (el.paymentType === 'accepted' || el.paymentType === 'processing') {
                if (el.paymentType === 'processing') {
                    summ = summ + el.amount
                    count++;
                }
                if (el.paymentType === 0) {
                    law = true
                }
            }
        })

        return [summ, count]
    }
    getInvoice = () => {

        this.setState({ ...this.state, invoiceLoading: true }, () => {
            notify({
                title: 'Счёт',
                description: 'Счёт формируется',
                type: 'info'
            })
            //API.get('/api/pay/invoice/' + this.props.store.app.account.number).then(res => {
            API({ method: 'get', url: '/api/pay/invoice/' + this.props.store.app.account.number, responseType: 'arraybuffer' }).then(res => {
                // console.customLog('GET INVOICE ', res)
                this.setState({ ...this.state, invoiceLoading: false })
                // notify({
                //     title: 'Счёт',
                //     description: 'Счёт успешно сформирован',
                //     type: 'success'
                // })
                let name = 'invoice_' + this.props.store.app.transmission.period + '_' + this.props.store.app.account.number + '.pdf'
                newFile(res.data, name, 'application/pdf')
            }).catch(err => {
                console.customLog('GET INVOICE', err)
                this.setState({ ...this.state, invoiceLoading: false })
                notify({
                    title: 'Счёт',
                    description: 'Ошибка при формировании счёта',
                    type: 'warning'
                })
                // TODO errors
            })
        })
    }
    render() {
        // let deb = -this.props.store.app.paymentDetails.amount
        let deb = -(this.props.store.app.account.balance.debt + this.props.store.app.account.balance.penalty - this.props.store.app.account.balance.accepted)

        let volume = deb >= 0 ? "__vol_i" : "__vol_d"

        if (isNaN(deb)) {
            deb = 0
            volume = ''
        }
        return (

            <Tile
                className={"__info"}
                isLoading={this.state.isLoading}
                header={{
                    icon: <Icon src={"tiles/icon__info.svg"} />,
                    title: 'Информация',
                    actions: [
                        <Button
                            key={this.staticId + '_btn_0'}
                            text={"Подробнее"}
                            type={"secondary"}
                            to={'/' + this.props.store.app.account.number + '/billing'}
                        // onClick={() => { console.customLog('test') }}
                        />,
                    ]
                }}
                footer={{
                    actions: [
                        this.props.store.app.account.hasInvoice ?
                            <Button
                                key={this.staticId + '_btn_1'}
                                text={"Скачать счёт"}
                                type={"secondary"}
                                // className={"__drop"}
                                icon={<Icon src={"icon__pdf_black.svg"} />}
                                isLoading={this.state.invoiceLoading}
                                onClick={this.getInvoice}
                            /> : null,
                        <Button
                            key={this.staticId + '_btn_2'}
                            text={"Оплатить"}
                            type={"primary"}
                            icon={<Icon src={"icon__tile_action_2.svg"} />}
                            to={'/' + this.props.store.app.account.number + '/payment'}
                        />,
                    ]
                }}
            >
                <>
                    <div className="_payment_title">{this.getTitle()}</div>
                    <div className="_payment_row">
                        <div className={"_payment_value " + volume}>{getFormattedPrice(Math.abs(deb))}</div>
                        {this.state.acceptedSumm ?
                            <div className="_payment_tip">
                                {this.state.acceptedSummCount > 1 ? "Ваши платежи" : "Ваш платеж"} на сумму {getFormattedPrice(this.state.acceptedSumm)} в обработке.<br /> Информация о балансе будет обновлена позже.
                            </div>
                            : null}
                    </div>
                    <div className="_payment_title">Основная информация</div>
                    <Table
                        id={'tile_info_' + this.props.store.app.account.number}
                        data={{
                            head: [
                                { name: 'Адрес', align: "left" },
                                { name: 'Лицевой счёт', align: "left" },
                            ],
                            body: [
                                [
                                    this.props.store.app.account.address,
                                    this.props.store.app.account.number
                                ],
                            ],
                        }} />
                </>
            </Tile>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppAccount: setAppAccount,
    setAccountPaymentDetails: setAccountPaymentDetails,
    setAccountPaymentDetailsRec: setAccountPaymentDetailsRec,
    setProfileInfo: setProfileInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeInfoTile);
