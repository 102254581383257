import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Switcher from "../../components/switcher/switcher";

import {
    Outlet,
    Navigate
} from 'react-router-dom';
import Tile from "../../components/tile/tile";
import TileInfoBox from "../../components/tile/tileInfoBox";
import { getFormattedDate, getFormattedPrice, getZoneTypeByArrLength, newFile, notify } from "../../helper";
import { connect } from "react-redux";
import TileEmptyBox from "../../components/tile/tileEmptyBox";
import API, { _SITE_URL } from "../../API";
import Button from "../../components/button/button";
import Table from "../../components/table/table";

class Billing_Documents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: true,
            files: [],
            account: '',
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    componentDidUpdate() {
        if (this.state.account !== this.props.store.app.account.number) {
            setTimeout(() => { this.setState({ ...this.state, isLoading: true, account: this.props.store.app.account.number, files: [] }) }, 1)
        } else {
            if (this.state.isLoading) {
                this.checkDocs()
            }
        }
    }

    checkDocs = () => {
        if (this.props.store.app.account.isBeforeTech) {
            API.get('/api/profile/doclist/' + this.props.store.app.account.number).then(res => {
                console.customLog(res)
                // debugger
                
                setTimeout(() => { this.setState({ ...this.state, isLoading: false, files: res.data }) }, 100)
            }).catch(err => {

                setTimeout(() => { this.setState({ ...this.state, isLoading: false }) }, 100)
            })
        } else {
            setTimeout(() => { this.setState({ ...this.state, isLoading: false }) }, 100)
        }
    }

    getDoc = (file, type) => {

        let url = type ? file.docRef : file.signatureRef;

        url = url.split('/service')[1]
        // this.setState({ ...this.state, invoiceLoading: false })

        API({ method: 'get', url: url, responseType: 'arraybuffer' }).then(res => {
            // this.setState({ ...this.state, invoiceLoading: false })
            // let name = 'file.pdf'
            // debugger
            // let name = type ? file.docFilename : file.docFilename + ".sig"; 
            // debugger
            if(type){

                newFile(res.data, file.docFilename, 'application/pdf')
            } else {
                newFile(res.data, file.docFilename + ".sig", 'application/pgp-signature')
            }
        }).catch(err => {
            console.customLog('GET INVOICE', err)
            // this.setState({ ...this.state, invoiceLoading: false })
            notify({
                title: 'Счёт',
                description: 'Ошибка при формировании документа',
                type: 'warning'
            })
            // TODO errors
        })
    }

    getTableBody = () => {
        let data = [];
        this.state.files.map(file => {
            let row = [];

            row[0] = file.docType
            row[1] = getFormattedDate(new Date(file.reqDate))
            row[2] = file.reqNum
            row[3] = <div className="actions">
                <Button
                    icon={<Icon src={"icon__pdf_black.svg"} />}
                    text={"Скачать PDF"}
                    type={"secondary"}
                    target={"_blank"}
                    onClick={() => {this.getDoc(file, true)}}
                    // href={_SITE_URL + file.docRef}
                    />
                <Button
                    icon={<Icon src={"icon__pdf_black.svg"} />}
                    text={"Электронная подпись"}
                    type={"secondary"}
                    target={"_blank"}
                    onClick={() => {this.getDoc(file, false)}}
                    // href={_SITE_URL + file.signatureRef}
                />
            </div>;

            data.push(row)
        })
        return data;
    }

    render() {
        return (
            <div className="tiles">
                <Tile
                    isLoading={this.state.isLoading}
                    header={{
                        title: "Документы",
                        icon: <Icon src="tiles/icon__info.svg" />,
                    }}
                    gapContent={true}
                >
                    {/* isBeforeTech: {this.props.store.app.account.isBeforeTech ? 'true' : 'false'} */}

                    {this.state.files.length ?
                        <Table data={{
                            head: [
                                { name: 'Документ', align: "left" },
                                { name: 'Дата', align: "left" },
                                { name: 'Заявка потребителя (регистрационный №)', align: "left" },
                                { name: 'Скачать', align: "left" },
                            ],
                            body: this.getTableBody()
                        }} />
                        : 
                        this.state.isLoading ? null : <TileEmptyBox/>}
                </Tile>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Billing_Documents);
