import React from "react";
import Button from "../button/button";
import './styles.scss';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef()
    }

    getHeader = () => {
        return (

            <>
                <>
                    <div className="form">
                        <div className="form_title">
                            <div className="_box">
                            </div>
                            <div className="_form"></div>
                            <div className="_btn"></div>
                        </div>
                    </div>
                </>
                {this.props.header.back ?
                    <div className="form__back">
                        <Button className="_btn_highlight __back" text="Назад" onClick={() => window.history.back()} />
                    </div>
                    : null}
                <div className="form__image">{this.props.header.image}</div>
                <div className="form__title">{this.props.header.title}</div>
                {this.props.header.subtitle ?
                    <div className="form__subtitle">{this.props.header.subtitle}</div>
                    : null}
            </>
        )
    }
    getFooter = () => {
        let items = [];
        this.props.footer.actions.map(el => {
            items.push(el)
        })
        let cl = this.props.footer.actionsAsCol ? '__col' : ""
        return (
            <div className={"form__actions " + cl}>
                {items}
            </div>
        )
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.props.onSubmit();
    }
    render() {
        return (
            <form className="form" onSubmit={this.handleSubmit}>
                {this.props.header ?
                    <div className="form__header">
                        {this.getHeader()}
                        {this.props.notification}
                    </div>
                    : null}
                <div className="form__body">
                    {this.props.children}
                </div>
                {this.props.footer ?
                    <div className="form__footer">
                        {this.getFooter()}
                    </div>
                    : null}
            </form>
        )
    }
}
class FormRow extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="form__row">
                {this.props.children}
            </div>
        )
    }
}

export default Form;
export { FormRow };
