import { createSlice } from '@reduxjs/toolkit'
import { deleteAuthToken } from '../../API';

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
      isUserLoggedIn: false,
    },
    reducers: {
      setLogged: (state) => {
          state.isUserLoggedIn = true;
      },
      setLogout: (state) => {
          deleteAuthToken()
          state.isUserLoggedIn = false;
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setLogged, setLogout } = AuthSlice.actions
  
  export default AuthSlice.reducer