import React from "react";
import API, { _SITE_URL } from "../../../../API";
import Button from "../../../../components/button/button";
import Icon from "../../../../components/icon/icon";
import Status from "../../../../components/status/status";
import { getFormattedDate, newFile, notify } from "../../../../helper";
import RequestForm from "./requestForm";
import './styles.scss';

class RequestItem extends React.Component {
    constructor(props) {
        super(props)
        this.needAnswer = false;
        this.fileRef = React.createRef()
        this.state = {
            file: false,
            isSending: false,
        }
        this.staticId = 'requestItem_' + (this.props.id ? this.props.id : Math.random());
    }
    initDialog = () => {
        let i = 0;
        console.customLog(this.props)
        if (!this.props.item.answers) {
            return
        }
        return this.props.item.answers.map(el => {
            let _i = i
            i++;
            if (el.author === '') {
                return this.getSelfMessage(el, _i)
            } else {
                return this.getSupportMessage(el, _i)
            }
        })
    }
    getSelfMessage = (el, i) => {
        // закрыто - зеленый
        // требует уточнения - желтый
        // отправлено - не показываем
        // на рассмотрении - желтый
        let id = this.staticId + '_message_self_' + i
        return (
            <div key={id} className="request__dialogItem __self">
                <div className="_author">
                    {this.getStatus(el.status)}
                    <div className="_icon __self">
                        <Icon src={"icon__dialog_self.svg"} />
                    </div>
                    <div className="_author_name">
                        {i === 0 ? "Ваш вопрос" : "Ваш ответ"}
                    </div>
                </div>
                {this.getMessageBody(el)}
            </div>
        )
    }
    getSupportMessage = (el, i) => {
        let id = this.staticId + '_message_self_' + i
        return (
            <div key={id} className="request__dialogItem __support">
                <div className="_author">
                    <div className="_icon">
                        <Icon src={"icon__dialog_company.svg"} />
                    </div>
                    <div className="_author_name">
                        {el.author}
                    </div>
                    {this.getStatus(el.status)}
                    {el.status === 'на рассмотрении' ?
                        <div className="_message_date">
                            {getFormattedDate(new Date(el.date), true, true)}
                        </div>
                        : null}
                </div>
                {el.status !== 'на рассмотрении' ?
                    this.getMessageBody(el)
                    : null}
            </div>
        )
    }
    getMessageBody = (el) => {
        return (
            <div className="_message">
                <div className="_message_body">
                    {el.text}
                </div>
                <div className="_message_footer">
                    <div className="_message_files">
                        {/* <div className="_file">
                            {this.getFileIcon('file.jpeg')}
                            file.jpeg
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.jpg')}
                            file.jpg
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.tiff')}
                            file.tiff
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.png')}
                            file.png
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.doc')}
                            file.doc
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.docx')}
                            file.docx
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.xls')}
                            file.xls
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.xlsx')}
                            file.xlsx
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.pdf')}
                            file.pdf
                        </div>
                        <div className="_file">
                            {this.getFileIcon('file.bmp')}
                            file.bmp
                        </div> */}
                        {el.files ?
                            el.files.map(file => {
                                return <div onClick={() => this.getFile(file)} className="_file">
                                    {this.getFileIcon(file.fileName)}
                                    {file.fileName}
                                </div>
                            })
                            : null}
                    </div>
                    <div className="_message_date">
                        {getFormattedDate(new Date(el.date), true, true)}
                    </div>
                </div>
            </div>
        )
    }

    getFile = (file) => {
        let url = _SITE_URL + file.url;
        console.customLog(url)

        //API.get(url).then(res => {
        API({ method: 'get', url: url, responseType: 'arraybuffer' }).then(res => {
            console.customLog(res.data, file.fileName, file.contentType)
            newFile(res.data, file.fileName, file.contentType)
        }).catch(err => {
            console.customLog(err)
        });
    }

    getFileIcon = (type) => {
        let icon = '';
        // debugger
        let _type = type.split('.');
        _type = _type[_type.length - 1].toLowerCase();

        switch (_type) {
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'tiff':
                icon = 'icon__file_image.svg'
                break;
            case 'doc':
            case 'docx':
                icon = 'icon__file_document.svg'
                break;
            case 'xls':
            case 'xlsx':
                icon = 'icon__file_table.svg'
                break;
            case 'pdf':
                icon = 'icon__pdf_black.svg';
                break;
            default:
                icon = 'icon__btn_upload.svg'
                break;
        }
        // switch (type) {
        //     case "data:image/jpg":
        //     case "data:image/png":
        //     case "data:image/tiff":
        //     case "data:image/pdf":
        //     case "data:image/jpeg":
        //         icon = 'icon__file_image.svg'
        //         break;
        //     case "data:application/vnd.ms-excel":
        //         icon = 'icon__file_table.svg'
        //         break;
        //     case "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        //         icon = 'icon__file_document.svg'
        //         break;
        //     default:
        //         icon = 'icon__btn_upload.svg'
        //         break;
        // }

        return <Icon src={icon} />
    }

    getAnswer = () => {
        if (!this.needAnswer) {
            return
        }
        return (
            <RequestForm isSending={this.state.isSending} onSend={this.send} />
        )
    }

    send = (data) => {
        this.setState({ ...this.state, isSending: true }, () => {
            API.post('/question/reply/' + this.props.item.id, data).then(res => {
                this.setState({ ...this.state, isSending: false }, () => {
                    notify({
                        title: 'Центр поддержки',
                        description: 'Сообщение успешно отправлено',
                        type: 'success'
                    })
                    this.props.update()
                })
                console.customLog(res)
            }).catch((err) => {
                console.customLog(err)
                notify({
                    title: 'Центр поддержки',
                    description: 'Произошла ошибка при отправке',
                    type: 'warning'
                })
            })
        })
    }

    getStatus = (text) => {
        let st = ""
        switch (text.toLowerCase()) {
            case "требует уточнения":
                st = "wait"
                this.needAnswer = true
                break;
            case "на рассмотрении":
                st = "wait"
                this.needAnswer = false
                break;
            case "отправлено":
                st = false
                this.needAnswer = false
                break;
            case "закрыто":
                this.needAnswer = false
                st = "success"
                break;
        }
        if (st) {
            let _text = text.split('');
            _text[0] = _text[0].toUpperCase();
            _text = _text.join('')
            return (
                // <div className={"__status" + st}>{_text}</div>
                <Status type={st} small={true}>{_text}</Status>
            )
        }
    }

    getItem = () => {
        return (
            <div className="request__dialog">
                {this.initDialog()}
                {this.getAnswer()}
            </div>
        )
    }
    render() {
        return (
            this.props.item ? this.getItem() : null
        )
    }
}

export default RequestItem;
