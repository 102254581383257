import React from "react";
import Zone from "./zone";
import './styles.scss';
import { getZone, sortZonesByDefault, _ZONES, _ZONE_TYPE_GROUP } from "../../helper";

class ZoneList extends React.Component {
    constructor(props) {
        super(props)
        this.staticId = 'zoneList_' + (this.props.id ? this.props.id : Math.random())
    }
    getZones = () => {
        let i = 0;

        let zones = [];
        let law = false;
        this.props.zones.map(el => {
            if (el.name) {
                law = true;
            }
        })

        if (law) {
            // zones = sortZonesByDefault(this.props.zones)
            // debugger
            this.props.zones.map(el => {
                let zone = Object.assign({}, el, getZone(el.name))
                zones.push(zone)
            })
            return zones.map(el => {
                i++;
                return <Zone {...el} key={this.staticId + '_zone_' + i} type={el.type} />
            })
            // return zones
        } else {
            return this.props.zones.map(el => {
                i++;
                return <Zone {...el} key={this.staticId + '_zone_' + i} type={_ZONE_TYPE_GROUP[this.props.zones.length - 1][i - 1]} />
            })
        }
    }
    render() {
        return (
            <div className="zoneType">
                <div className="zoneType__list">
                    {this.getZones()}
                </div>
            </div>
        )
    }
}

export default ZoneList;
