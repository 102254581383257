import { createSlice } from '@reduxjs/toolkit'

export const HistorySlice = createSlice({
    name: 'history',
    initialState: {
        payments: [],
        meters: [],
        consumptions: [],
        calculations: [],
        limit: 3
    },
    reducers: {
        setAppHistoryPayments: (state, action) => {
            state.payments = action.payload;
        },
        setAppHistoryMeters: (state, action) => {
            state.meters = action.payload;
        },
        setAppHistoryConsumptions: (state, action) => {
            state.consumptions = action.payload;
        },
        setAppHistoryCalculations: (state, action) => {
            state.calculations = action.payload;
        },
        setAppHistoryLimit: (state, action) => {
            console.customLog(action.payload)
            state.limit = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setAppHistoryPayments, setAppHistoryMeters, setAppHistoryConsumptions, setAppHistoryCalculations, setAppHistoryLimit } = HistorySlice.actions

export default HistorySlice.reducer