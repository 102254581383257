import React from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import Ico_phone from '../../assets/icon__header_phone.svg';
import './styles.scss';
import Icon from "../icon/icon";
import { _SERVICE_DATA } from "../../App";
import { connect } from "react-redux";
import Button from "../button/button";

class Header extends React.Component {
    constructor(props) {
        super(props)
        console.customLog(this, this.props.store.app.account.number)
        console.customLog(_SERVICE_DATA)
    }
    render() {
        return (
            <header>
                <div className="container">
                    <div className="_inner">
                        <div className="_row">
                            <div className="_col">
                                <Link to="/" className="logo">
                                    <Logo />
                                </Link>
                            </div>
                            <div className="_col _info">
                                Личный кабинет
                                {!this.props.store.auth.isUserLoggedIn &&
                                    _SERVICE_DATA.oldVersion.isActive &&
                                    <Button type={"inline"} text={_SERVICE_DATA.oldVersion.name} href={_SERVICE_DATA.oldVersion.link} target="blank" />

                                }
                            </div>
                            <div className="_col _ctc">
                                <div className="_contacts">
                                    <div className="_icon">
                                        <img src={Ico_phone} />
                                    </div>
                                    <div className="_contacts_list">
                                        <div className="_contacts_item">
                                            <div className="_contacts_item_box">
                                                <div className="_title">
                                                    «Горячая линия»
                                                </div>
                                                <a href={`tel:${_SERVICE_DATA.contacts.hotline.phone_raw}`} className="_link">
                                                    {_SERVICE_DATA.contacts.hotline.phone}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="_contacts_item">
                                            <div className="_contacts_item_box">
                                                <div className="_title">
                                                    Техподдержка личного кабинета
                                                </div>
                                                <a href={`tel:${_SERVICE_DATA.contacts.support.phone_raw}`} className="_link">
                                                    {_SERVICE_DATA.contacts.support.phone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.alt ?

                                <div className="_col">
                                    <div className="_actions">
                                        <NavLink to="support">
                                            <Icon src={"icon__header_request.svg"} />
                                            <span>Техподдержка</span>
                                        </NavLink >
                                    </div>
                                </div>
                                :
                                <div className="_col">
                                    <div className="_actions">
                                        <NavLink to="requests" className={this.props.store.app.ui.requestIndicator ? " __indi" : ""}>
                                            {/* <NavLink to="req"> */}
                                            <Icon src={"icon__header_request.svg"} />
                                        </NavLink >
                                        {/* <NavLink to="notification">
                                            <Icon src={"icon__header_notification.svg"} />
                                        </NavLink > */}
                                        <NavLink to={"profile"}>
                                            <Icon src={"icon__header_profile.svg"} />
                                        </NavLink >
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

