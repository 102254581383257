import React from "react";
import './styles.scss';

class Select extends React.Component {
    constructor(props) {
        super(props)
        this.isInited = false;
        this.id = this.props.id ? this.props.id : Math.random()
        this.state = {
            isActive: false,
            currentOption: {
                value: null,
                text: 'Выберите'
            }
        }
    }
    componentDidUpdate() {

        if (!this.isInited) {
            this.init();
        }
    }
    init = () => {
        this.isInited = true
        this.props.options.map(el => {
            if (el.default) {
                this.set(el)
            }
        })
    }
    getOptions = () => {
        let i = 0;
        return (
            this.props.options.map(el => {
                i++;
                return <div key={'select_' + this.id + "_" + i} className="select__option" onClick={() => { this.set(el) }}>{el.text}</div>
            })
        )
    }
    getCurrentOption = () => {
        return this.state.currentOption.text
    }
    set = (el) => {
        this.props.onChange(el);
        this.setState({ ...this.state, currentOption: el }, () => {
            this.hide()
        })
    }
    show = () => {
        this.setState({ ...this.state, isActive: true });
    }
    hide = () => {
        this.setState({ ...this.state, isActive: false });
    }
    toggle = () => {
        if (!this.state.isActive) {
            this.show();
        } else {
            this.hide();
        }
    }
    render() {
        let cl = this.props.className ? " __" + this.props.className : "";
        cl += this.state.isActive ? ' __active' : "";
        return (
            <div className={"select" + cl} onMouseLeave={this.hide}>
                <div className="select__wrapper">
                    <div className="select__head" onClick={this.toggle}>
                        {this.getCurrentOption()}
                    </div>
                    <div className="select__body">
                        {this.getOptions()}
                    </div>
                </div>
            </div>
        )
    }
}

export default Select;
