import React from "react";
import './styles.scss';

const QrPage = params => {
    const {address, sum, callback} = params; 
    return !!address && <div className="sbp">
        <div className="sbp-hdr">
            <span>Оплата {sum} руб.</span>
            <span className="sbp-return" onClick={callback}>
                Вернуться в Личный кабинет ПАО КСК</span>            
        </div>
        <iframe src={address} className="ifr"/>
    </div>
}

export default QrPage
