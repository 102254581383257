import { configureStore } from '@reduxjs/toolkit'
import AppSlice from './slices/app'
import AuthSlice  from './slices/auth'
import HistorySlice from './slices/history'

export default configureStore({
    reducer: {
        auth: AuthSlice,
        app: AppSlice,
        history: HistorySlice,
    },
})