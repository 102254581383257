import React, { lazy } from "react";
import './styles.scss';
import Icon from "../../components/icon/icon";
import Button from "../button/button";

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false
        }
    }
    show = () => {
        console.customLog('show' + this.state.isActive)
        this.onShow()
        this.setState({ ...this.state, isActive: true })
    }
    hide = () => {
        console.customLog('hide' + this.state.isActive)
        this.onHide()
        if (!this.props.isClosable) {
            this.setState({ ...this.state, isActive: false })
        }
    }
    onShow = () => {
        if (this.props.onShow) {
            this.props.onShow()
        }
    }
    onHide = () => {
        if (this.props.onHide) {
            this.props.onHide()
        }
    }
    render() {
        let clAct = this.state.isActive ? " __active" : "";
        return (
            <div className={"modal" + clAct}>
                <div className="modal__shadow" onClick={this.hide}>
                </div>
                <div className="modal__outer">
                    <div className="modal__inner">
                        <div className="modal__close" onClick={this.hide}>
                        </div>
                        <div className="modal__content">
                            {this.props.icon ?
                                <div className="modal__icon">
                                    {this.props.icon}
                                </div>
                                : null}
                            {this.props.title ?
                                <div className="modal__title">
                                    {this.props.title}

                                </div>
                                : null}
                            {this.props.subtitle ?
                                <div className="modal__subtitle">
                                    {this.props.subtitle}
                                </div>
                                : null}
                            {this.props.actions ?
                                <div className="modal__actions">
                                    {this.props.actions}
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;
