import React, { useState } from "react";
import { connect } from "react-redux";
import API, { checkAuthToken } from "../../API";
import Button from "../../components/button/button";
import Chart from "../../components/chart/chart";
import Icon from "../../components/icon/icon";
import Meter from "../../components/meter/meter";
import Notification from "../../components/notifications/notification";
import Table from "../../components/table/table";
import Tile from "../../components/tile/tile";
import HomeHistoryTile from './tile_history'
import HomeConsumptionTile from "./tile_consumption"
import HomeViewTile from "./tile_view"
import HomeInfoTile from "./tile_info"
import { deleteCookie, getCookie, getFormattedPrice, getZoneTypeByArrLength, notify, setCookie } from "../../helper";
import { withRouter } from "../../withRouter";
import { setAccountSelect, setBackButtonVisibility, setBillHeaderVisibility } from "../../store/slices/app";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Notfound from "../notfound/notfound";
import { setLogout } from "../../store/slices/auth";
import { useEffect } from "react";


const HomeContainer = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [shouldRender, setRender] = useState(<></>)
    const [isStoreSetted, setStore] = useState(false)

    useEffect(() => {
        if(isStoreSetted){
            setBackUrl()
            // console.customLog(location, isStoreSetted)
            // debugger
        }
    }, [location, isStoreSetted])

    useEffect(() => {
        // console.customLog(props.store)
        // debugger
        if(props.store.app.accounts.length > 0){
            console.customLog("SET STORE")
            setStore(true)
        }
        
    }, [props.store])

    function setUI(type) {
        if (props.store.app.ui.backButton !== type) {
            props.setBackButtonVisibility(type)
        }
        if (props.store.app.ui.billHeader !== type) {
            props.setBillHeaderVisibility(type)
        }
    }

    function setBackUrl() {
        console.customLog("SET BACK URL")
        var isCookieChecked = checkAuthToken()
        let cookie = getCookie('backUrl');

        const throwError = () => {
            setCookie('backUrl', location.pathname)
            notify({
                title: 'Время сессии истекло',
                description: 'Авторизуйтесь повторно',
                type: 'warning'
            })
            // setRender(<Notfound noAuth={true}/>)
            // props.setLogout()

            props.setLogout()
            navigate('/auth');
        }

        if (isCookieChecked) {
            API.get('/api/profile/user-info').then(res => {
                if (!res.data.id) {
                    throwError()
                } else {
                    if(!cookie){
                        checkUrl()
                    }
                }
            }).catch(err => {
                throwError()
            })
        } else {
            throwError()
        }


        if (cookie) {
            if (location.pathname !== cookie) {
                navigate(cookie);
                deleteCookie('backUrl');
                return false
            }
        }
        return true
    }

    function checkUrl() {
        console.customLog("CHECK URL")
        let path = location.pathname;
        path = path.split('/');
        let _path = path[1]
        console.customLog(_path)
        if (parseInt(_path) === parseInt(props.store.app.account.number)) {
            // debugger
            if(parseInt(_path) != _path){
                setRender(<Notfound />)
                return
            }
            console.customLog("NAVIGATE", 1, _path, props.store.app.account.number)
            setUI(true)
            setRender(<Outlet />)
            return
        }
        if (parseInt(_path)) {
            let law = false
            props.store.app.accounts.map(el => {
                if (parseInt(el.number) === parseInt(_path)) {
                    law = el.number
                }
            })
            if (law) {
                console.customLog("NAVIGATE", 2)
                props.setAccountSelect(law);
                setUI(true)
                path[1] = law
                // setRender(<Navigate to={path.join('/')} />)
                navigate(path.join('/'))
                return 
            } else {
                console.customLog("NAVIGATE", 3)
                setUI(false)
                // path[1] = this.props.store.app.account.number
                setRender(<Notfound />)
                return 
            }
        }
        if (_path === '') {
            console.customLog("NAVIGATE", 4)
            path[1] = props.store.app.account.number
            setUI(true)
            navigate(path.join('/'))
            // setRender(<Navigate to={path.join('/')} />)
            return
        }
        if (!_path) {
            console.customLog("NAVIGATE", 4)
            path[1] = props.store.app.account.number
            setUI(true)
            navigate(path.join('/'))
            // setRender(<Navigate to={path.join('/')} />)
            return
        } else {
            setUI(false)
            // debugger
            console.customLog("NAVIGATE", 5)
            setRender(<Notfound />)
            return
        }
    }
    return shouldRender
}

const mapStateToProps = (state) => ({
    store: state,
});

const mapDispatchToProps = {
    setAccountSelect: setAccountSelect,
    setBackButtonVisibility: setBackButtonVisibility,
    setBillHeaderVisibility: setBillHeaderVisibility,
    setLogout: setLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);