import React, { lazy } from "react";
import './styles.scss';

class PageTitle extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="pageTitle">
                <div className="pageTitle__col">
                    <div className="pageTitle__box">
                        {this.props.icon}
                        {this.props.text}
                    </div>
                </div>
                <div className="pageTitle__col">
                    <div className="pageTitle__actions">
                        {this.props.actions}
                    </div>
                </div>
            </div>
        )
    }
}

export default PageTitle;
