import React, { lazy } from "react";
import './styles.scss';

class TileInfoBox extends React.Component {
    render() {
        return (
            <div className={"tile__infoBox"}>
                {this.props.title ?
                    <div className={"tile__infoBox_title"}>
                        {this.props.title}
                    </div>
                : null}
                {this.props.description ?
                    <div className={"tile__infoBox_description"}>
                        {this.props.description}
                    </div>
                : null}
            </div>
        )
    }
}

export default TileInfoBox;
