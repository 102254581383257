import React from "react";
import './styles.scss';
import { Line, Bar } from 'react-chartjs-2';
import Icon from "../icon/icon";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
// ChartJS.register(ArcElement);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class Chart extends React.Component {
    constructor(props) {
        super(props);
        this.ref__tooltip = React.createRef();
        this.ref__legend = React.createRef();

        this.options = this.props.options;
        this.options.plugins.tooltip = {
            enabled: false,
            position: 'nearest',
            external: this.externalTooltipHandler
        }

    }
    htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
            const _LEGEND = this.ref__legend.current;
            const _BOX = _LEGEND.querySelector('.chart__legend_box');

            _BOX.innerHTML = "";

            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach(item => {
                const div = document.createElement('div');
                div.classList.add('chart__legend_box_item')

                div.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                const boxSpan = document.createElement('span');
                const textContainer = document.createElement('p');
                const text = document.createTextNode(item.text);

                boxSpan.style.background = item.fillStyle;
                textContainer.appendChild(text);

                if (item.hidden) {
                    div.classList.add('__hidden')
                } else {
                    div.classList.remove('__hidden')
                }

                div.append(boxSpan)
                div.append(textContainer)
                _BOX.append(div)
            });
        }
    }
    externalTooltipHandler = (context) => {
        const { chart, tooltip } = context;
        const tooltipEl = this.ref__tooltip.current;
        const _TITLE = tooltipEl.querySelector('.chart__tooltip_title');
        const _BOX = tooltipEl.querySelector('.chart__tooltip_box');
        const _ARROW = tooltipEl.querySelector('.chart__tooltip_arrow');

        _BOX.innerHTML = "";

        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            _TITLE.innerText = titleLines

            bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                let txt = "";
                if (body) {
                    txt = body[0].split(" ");
                    txt = txt[txt.length - 1];
                }

                const div = document.createElement('div');
                const span = document.createElement('span');
                const text = document.createTextNode(txt + ' ' + chart.config.options.scales.y.title.text);

                span.style.backgroundColor = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;

                div.classList.add('chart__tooltip_box_item')

                div.append(span)
                div.append(text)
                _BOX.append(div)
            });;
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        tooltipEl.style.opacity = 1;
        // debugger
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        let arrtransform = 0

        // console.customLog(
        //     tooltip.width,
        //     tooltipEl.clientWidth,
        //     (tooltip.caretX + tooltip.width / 2) > chart.chartArea.right,
        //     (tooltip.caretX - tooltip.width / 2) < chart.chartArea.left,
        //     tooltip.caretX,
        //     tooltip.width / 2,
        //     tooltip,
        //     (tooltip.x - (positionX + chart.chartArea.right - tooltip.width - chart.chartArea.left)) - 16,
        //     chart,
        // )

        if ((tooltip.caretX + tooltipEl.clientWidth / 2) > chart.chartArea.right) {
            // tooltipEl.style.left = positionX + chart.chartArea.right - tooltip.width / 2 - chart.chartArea.left - 0 + 'px';
            tooltipEl.style.left = chart.chartArea.right - (tooltipEl.clientWidth / 2) + 10 + 'px';
            arrtransform = (tooltip.caretX - (chart.chartArea.right - (tooltipEl.clientWidth / 2))) - 10
        }
        if ((tooltip.caretX - tooltipEl.clientWidth / 2) < chart.chartArea.left) {
            tooltipEl.style.left = positionX + chart.chartArea.left + tooltipEl.clientWidth / 2 - 11 + 'px';
            arrtransform = (tooltip.caretX - (positionX + chart.chartArea.left + tooltipEl.clientWidth / 2)) + 11
        }
        _ARROW.style.transform = 'translateX(' + arrtransform + 'px)';
    }
    render() {
        return (
            <div className="chart_wrapper">
                <div className="chart">
                    {this.props.type === 'line' ?
                        <Line options={this.options} data={this.props.data} plugins={[this.htmlLegendPlugin]} />
                        : null}
                    {this.props.type === 'bar' ?
                        <Bar options={this.options} data={this.props.data} plugins={[this.htmlLegendPlugin]} />
                        : null}
                    <div className="chart__tooltip" ref={this.ref__tooltip}>
                        <div className="chart__tooltip_arrow">
                            <div className="chart__tooltip_arrow_inner">

                            </div>
                        </div>
                        <div className="chart__tooltip_title"></div>
                        <div className="chart__tooltip_box"></div>
                    </div>
                    <div className={"chart__legend" + ' tile__footer' + (this.props.inTile ? " __inTile" : "")} ref={this.ref__legend} id="legend-container">
                        <div className="chart__legend_box">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Chart;
