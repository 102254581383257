import React, { lazy } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "../../withRouter";
import './styles.scss';

class Switcher extends React.Component {
    constructor(props) {
        super(props)
        this.id = this.props.id ? this.props.id : Math.random()
    }
    getItems = () => {
        let i = 0;
        return this.props.items.map(el => {
            i++;
            return (
                <div className="switcher__item" key={'switcher_' + this.id + i}>
                    <NavLink  to={el.link}>{el.text}</NavLink>
                </div>
            )
        })
    }
    getCurItemActions = () => {
        return this.props.items.map(el => {
            if (el.link === this.props.location.pathname) {
                return el.actions
            }
        })
    }
    render() {
        return (
            <div className="switcher_wrapper">
                <div className="switcher_col">
                    <div className="switcher_outer">
                        <div className="switcher_scroller">
                            <div className="switcher">
                                {this.getItems()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="switcher_col">
                    <div className="switcher_actions">
                        {this.getCurItemActions()}
                        {this.props.actions}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Switcher);
