import React from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/input/input";
import Tile from "../../../components/tile/tile";
import { ReactComponent as Brand } from "../../../assets/brand.svg"
import Button from "../../../components/button/button";
import Notification from "../../../components/notifications/notification";
import { connect } from "react-redux";
import { setLogged } from '../../../store/slices/auth'
import { setAppAccounts } from "../../../store/slices/app";
import API, { collectJSONFromInput, setAuthToken, simplifyJSON, _OPTIONS } from "../../../API"
import store from "../../../store/store";
import { getCookie, getNormalError, setCookie } from "../../../helper";
import { withRouter } from "../../../withRouter";
import { _SERVICE_DATA } from "../../../App";

class Auth extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            notification: {
                show: false,
                icon: 'icon__notification_calendar.svg',
                type: 'success',
                description: 'Передача показаний по 26 число каждого месяца'
            }
        }
        this.lastAccount = getCookie('lastAccount')
        this.staticId = 'auth_static_'

        this.input_accountRef = React.createRef();
        this.input_passwordRef = React.createRef();
    }
    handleSubmit = () => {
        console.customLog('subnit')
        let json = collectJSONFromInput([this.input_accountRef, this.input_passwordRef])
        json = simplifyJSON(json)
        console.customLog(json)
        this.setState({ ...this.state, isLoading: true, notification: {show: false} }, () => {
            API.post('/auth/sign-in', json).then(res => {
                console.customLog(res.data);
                if (res.data.token) {
                    setAuthToken(res.data.token)
                    this.setApp()
                } else {
                    this.throwError('Неверные данные');
                }
            }).catch(err => {
                console.customLog(err);
                this.throwError(err.response.data.message);
            })
        })
    }
    setApp = () => {
        API.get('/api/profile/accounts/').then(res => {
            console.customLog(res.data);
            this.props.setAppAccounts(res.data)
            this.props.setLogged()
            this.setState({ ...this.state, isLoading: false })
            // debugger
            if(Array.isArray(res.data)){
                let targetAcc = res.data.filter(el => el.main)[0]
                setCookie('lastAccount', targetAcc.number)
            }
            // debugger
            // this.props.navigate('/' + res.data.filter(el => el.main)[0].number)
            this.props.navigate('/')
        }).catch(err => {
            console.customLog(err);
            // this.setState({ ...this.state, isLoading: false })
            this.throwError(err.response.data.message);
        })
    }
    throwError = (text) => {
        let error = getNormalError(text);
        this.setState({
            ...this.state, isLoading: false, notification: {
                show: true,
                icon: 'icon__page_profile.svg',
                type: 'warning',
                description: error,
            }
        })
    }
    render() {
        return (
            <Tile className={"__facelifted"} >
                <Form
                    onSubmit={this.handleSubmit}
                    header={{
                        image: <Brand />,
                        title: "Вход в личный кабинет",
                    }}
                    notification={<Notification
                        iconName={this.state.notification.icon}
                        type={this.state.notification.type}
                        description={this.state.notification.description}
                        show={this.state.notification.show}
                    />}
                    footer={{
                        actionsAsCol: true,
                        actions: [
                            <Button type="mainPrimary" prop="button" text="Войти" isLoading={this.state.isLoading} key={this.staticId + '_btn_0'} />,
                            <div className="form__action_text" key={this.staticId + '_text_0'}>Если Вы впервые на сайте,<br/>
                            заполните форму <Button text="регистрации" to="/registration" type={'inline'} /></div>,
                            // <Button type="secondary" text="Регистрация" to="/registration" key={this.staticId + '_btn_1'} />,
                            <Button type="inline" text="Я не помню пароль" to="/recover" key={this.staticId + '_btn_2'} />,
                        ]
                    }}
                >
                    <Input
                        title={"Номер лицевого счёта:"}
                        type={"text"}
                        name={"account"}
                        // value={"325026442"}
                        value={this.lastAccount ? this.lastAccount : process.env.REACT_APP_TESTDATA_AUTH_ACCOUNT}
                        ref={this.input_accountRef}
                    />
                    <Input
                        title={"Пароль:"}
                        type={"password"}
                        name={"password"}
                        // value={"123"}
                        value={process.env.REACT_APP_TESTDATA_AUTH_PASSWORD}
                        ref={this.input_passwordRef}
                    />
                </Form>
            </Tile>
        )
    }
}


const mapStateToProps = (state) => ({
    state
});

const mapDispatchToProps = { setLogged, setAppAccounts };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));
