
import { Helmet } from 'react-helmet';

export default function Analytics(props) {
    let analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
    let analyticsSrc = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsId
    return (
        <>
            {analyticsId &&
                <Helmet>
                    <script async src={analyticsSrc}></script>
                    <script>
                        {`  
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            
                            gtag('config', '${analyticsId}');
                            `}
                    </script>
                </Helmet>
            }
        </>
    )
}