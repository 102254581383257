import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Switcher from "../../components/switcher/switcher";

import {
    Outlet,
    Navigate
} from 'react-router-dom';
import Tile from "../../components/tile/tile";
import TileInfoBox from "../../components/tile/tileInfoBox";
import { getFormattedPrice, getZoneTypeByArrLength } from "../../helper";
import { connect } from "react-redux";

class Billing_Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: true,
            account: ''
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    componentDidUpdate() {
        if (this.state.account !== this.props.store.app.account.number) {
            setTimeout(() => { this.setState({ ...this.state, isLoading: true, account: this.props.store.app.account.number }) }, 1)
        } else {
            if (this.state.isLoading) {
                setTimeout(() => { this.setState({ ...this.state, isLoading: false }) }, 100)
            }
        }
    }

    getTitle = () => {
        console.customLog(this);
        let title = 'Переплата';
        let deb = -this.props.store.app.account.balance.debt
        if (deb == 0) {
            title = 'Баланс'
        }
        if (deb < 0) {
            title = "Долг"
        }
        return title;
    }

    render() {
        let isMeterIsset = this.props.store.app.account.meterNumber ? true : false
        let deb = -this.props.store.app.account.balance.debt
        return (
            <div className="tiles __small">

                <Tile
                    isLoading={this.state.isLoading}
                    header={{
                        title: "Основная информация",
                        icon: <Icon src="tiles/icon__info.svg" />,
                    }}
                    gapContent={true}
                >
                    <TileInfoBox
                        title={"Адрес:"}
                        description={this.props.store.app.account.address}
                    />
                    <TileInfoBox
                        title={"Лицевой счёт:"}
                        description={this.props.store.app.account.number}
                    />
                    <TileInfoBox
                        title={"Наименование счётчика:"}
                        description={isMeterIsset ? this.props.store.app.account.meterName : "-"}
                    />
                    <TileInfoBox
                        title={"Список зон:"}
                        description={isMeterIsset ? getZoneTypeByArrLength(this.props.store.app.account.tarifs.length) : "-"}
                    />
                </Tile>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Billing_Main);
