import './css/styles.scss';
import { useEffect, useState } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  BrowserRouter,
  redirect,
} from 'react-router-dom';

import Home from './screens/home/home';
import Profile from './screens/profile/profile';
import Profile_Accounts from './screens/profile/profile_accounts';
import Profile_Password from './screens/profile/profile_password';
import Profile_Personal from './screens/profile/profile_personal';
import Profile_Add from './screens/profile/profile_add';
import Requests from './screens/requests/requests';
import Requests_List from './screens/requests/requests_list';
import Requests_Questions from './screens/requests/requests_questions';
import Requests_Contacts from './screens/requests/requests_contacts';
import Requests_Create from './screens/requests/requests_create';
import AuthReg from './screens/authreg/authreg';
import Support from './screens/authreg/support/support';
import Auth from './screens/authreg/auth/auth';
import Reg from './screens/authreg/reg/reg';
import Reg_Code from './screens/authreg/reg/reg_code';
import Reg_Success from './screens/authreg/reg/reg_success';
import Reg_Index from './screens/authreg/reg/reg_index';
import Recover from './screens/authreg/recover/recover';
import Recover_Index from './screens/authreg/recover/recover_index';
import Recover_Code from './screens/authreg/recover/recover_code';
import Recover_Password from './screens/authreg/recover/recover_password';
import Recover_Success from './screens/authreg/recover/recover_success';
import History_Use from './screens/history/history_use';
import History_Stat from './screens/history/history_stat';
import History_Payment from './screens/history/history_payment';
import History_View from './screens/history/history_view';
import History from './screens/history/history';
import Payment from './screens/payment/payment';
import Billing from './screens/billing/billing';
import NotFound from './screens/notfound/notfound';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import Notifications from './components/notifications/notifications';
import BackButton from './components/backButton/backButton';
import store from './store/store';
import BillHeader from './components/billHeader/billHeader';
import NotificationFeed from './components/notifications/notificationFeed';
import { setAppAccounts } from './store/slices/app';
import { setLogged } from './store/slices/auth';
import API, { checkAuthToken, setAuthToken, setAuthTokenByCookie } from './API';
import PaymentSuccess from './screens/payment/paymentSuccess';
import PaymentAction from './screens/payment/paymentAction';
import PaymentDecline from './screens/payment/paymentDecline';
import HomeContainer from './screens/home/homeContainer';
import AuthregContainer from './screens/authreg/authregContainer';
import Mobileapp from './screens/mobileapp/mobileapp';
import { checkLocalRequests, deleteCookie, getCookie, setCookie } from './helper';
import Billing_Main from './screens/billing/billing_main';
import Billing_Balance from './screens/billing/billing_balance';
import Billing_Documents from './screens/billing/billing_documents';
import ButtonToTop from './components/button/buttonToTop';
import Analytics from './components/analytics/analytics';

let _SERVICE_DATA = false;

function App() {
  const location = useLocation()
  const storeApp = useSelector((state) => state)
  const dispatch = useDispatch()
  const [path, setPath] = useState('/');
  const navigate = useNavigate()

  const [service, setService] = useState(false)
  const [tokenChecked, setTokenChecked] = useState(false)

  useEffect(() => {
    setPath(location.pathname)
    checkReq()
  }, [location])

  useEffect(() => {
    // Your code here
    fetch(process.env.REACT_APP_LOCALE_DATA_PATH)
      .then((response) => {
        return response.json()
      }).then(data => {
        _SERVICE_DATA = data
        setService(_SERVICE_DATA)
        console.customLog(_SERVICE_DATA)
      })
  }, []);
  useEffect(() => {
    if (!checkAuthToken()) {
      setTokenChecked(true)
      checkReq()
      return
    }
    setAuthTokenByCookie()
    API.get('/api/profile/accounts/').then(res => {
      console.customLog(res.data);
      store.dispatch(setAppAccounts(res.data))
      store.dispatch(setLogged())
      setTokenChecked(true)
      checkReq()
      // debugger
      // setCookie('lastAccount', '')
      // console.customLog('TEST', store.app.account.number)
      // navigate(store.app.account.number)
    }).catch(err => {
      console.customLog(err);
      setTokenChecked(true)
      this.throwError(err.response.data.message);
    })
  }, []);

  function checkReq() {
    console.customLog('checkReq', storeApp.auth.isUserLoggedIn)
    if (storeApp.auth.isUserLoggedIn) {
      API.get('/question/answers').then(res => {
        console.customLog(res, "ANSWER E")
        checkLocalRequests(res.data.count)
        // checkLocalRequests(0)
        // debugger
      }).catch(console.customLog)
    }
  }
  if (!service) {
    return (<></>)
  }
  if (!tokenChecked) {
    return (<></>)
  }

  console.customLog(storeApp)
  return (

    <div id="__site_wrapper" className="App">
      {storeApp.auth.isUserLoggedIn ?
        <>
          <Header />
          <main>
            <BillHeader path={path} bills={storeApp.app.accounts} />
            {/* <BackButton path={path} /> */}
            <BackButton path={path} />
            <NotificationFeed />
            <Notifications path={path} items={storeApp.app.account.warnings} />
            <ButtonToTop />
            <section className="content">
              <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path={"/"} index element={<HomeContainer />} />
                <Route path={"/:accountId"} element={<HomeContainer />}>
                  <Route
                    path="/:accountId"
                    index
                    element={<Home />}
                  />
                  <Route path="/:accountId/APPROVED"
                    loader={({ params }) => {
                      throw redirect(`/${params.accountId}`)
                    }}
                    />
                  <Route path="history" element={<History />}>
                    <Route path="view" index element={<History_View />} />
                    <Route path="payment" element={<History_Payment />} />
                    <Route path="stat" element={<History_Stat />} />
                    <Route path="use" element={<History_Use />} />
                  </Route>
                  <Route path="payment" element={<Payment />}>
                    <Route index element={<PaymentAction />} />
                    <Route path="APPROVED" element={<PaymentSuccess />} />
                    <Route path="DECLINED" element={<PaymentDecline />} />
                    {/* <Route path="payment" element={<PaymentDecline />} /> */}
                  </Route>
                  <Route path="billing" element={<Billing />}>
                    <Route path="info" index element={<Billing_Main />} />
                    <Route path="balance" element={<Billing_Balance />} />
                    <Route path="documents" element={<Billing_Documents />} />
                    {/* <Route path="use" element={<History_Use />} /> */}
                  </Route>
                  {/* <Route path="billing" element={<Billing />} /> */}
                  <Route path="*" element={<NotFound inHome={true} />} />
                </Route>
                <Route path="profile" element={<Profile />}>
                  <Route path="personal" index element={<Profile_Personal />} />
                  <Route path="accounts" element={<Profile_Accounts />} />
                  <Route path="password" element={<Profile_Password />} />
                  <Route path="add" element={<Profile_Add />} />
                </Route>
                <Route path="requests" element={<Requests />}>
                  <Route path="list" index element={<Requests_List />} />
                  <Route path="questions" element={<Requests_Questions />} />
                  <Route path="contacts" element={<Requests_Contacts />} />
                  <Route path="create" element={<Requests_Create />} />
                </Route>
                <Route path="app" element={<Mobileapp />} />
                {/* <Route path="*" element={<NotFound inHome={true} />} /> */}
              </Routes>
            </section>
          </main>
          <Footer />
        </>
        :
        <>
          <Header alt={true} />
          <main>
            <Routes>
              <Route path="/" element={<AuthregContainer />} >
                <Route element={<AuthReg />} >
                  <Route path="auth" index element={<Auth />}></Route>
                  <Route path="registration" element={<Reg />}>
                    <Route path="*" index element={<Reg_Index />} />
                    <Route path="code" element={<Reg_Code />} />
                    <Route path="success" element={<Reg_Success />} />
                  </Route>
                  <Route path="recover" element={<Recover />}>
                    <Route path="*" index element={<Recover_Index />} />
                    <Route path="code" element={<Recover_Code />} />
                    <Route path="newpassword" element={<Recover_Password />} />
                    <Route path="success" element={<Recover_Success />} />
                  </Route>
                  <Route path="support" element={<Support />}></Route>
                </Route>
                <Route path="*" element={<NotFound inHome={false} />} />
              </Route>
            </Routes>
          </main>
        </>
      }
    </div >
  );
}

function AppContainer() {
  return (
    <>
    <Provider store={store}>
      {/* <BrowserRouter basename='/lk/'> */}
      <BrowserRouter basename={process.env.REACT_APP_LOCAL_URI}>
        {/* <Router> */}
        <App />
      </BrowserRouter>
    </Provider>
    <Analytics />
    </>
  )
}

export default AppContainer;

export {
  _SERVICE_DATA
}
