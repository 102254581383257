import React from "react";
import Icon from "../../components/icon/icon";
import Tile from "../../components/tile/tile";
import { connect } from "react-redux";
import Table from "../../components/table/table";
import Button from "../../components/button/button";
import { getMonthByNumber, getPaymentStatus, getPaymentType, getZoneTypeByArrLength, notify, sortZonesByDefault } from "../../helper";
import { setAppHistoryMeters, setAppHistoryPayments } from "../../store/slices/history";
import { getHistoryMeters, getHistoryPayments, getHistoryPaymentsItems } from "../history/helper";
import Meter from "../../components/meter/meter";
import Notification from "../../components/notifications/notification";
import API, { simplifyJSON } from "../../API";
import { setAccountTransmission } from "../../store/slices/app";
import Modal from "../../components/modal/table";
import TileEmptyBox from "../../components/tile/tileEmptyBox";

class HomeViewTile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: false,
            account: '',
            zones: [],
            inputs: [],
            errorInputs: [],
            loadingBtn: false,
            alreadySended: false,
            isInputsValid: false,
            modal: {
                title: '',
            },
            notification: {
                type: 'info',
                description: 'Передача показаний по 26 число каждого месяца',
            }
        }
        this.staticId = 'tile_view_' + this.props.store.app.account.number
        // this.sendModal = React.createRef();
        this.confirmModal = React.createRef();
    }
    componentDidUpdate() {

        if (this.props.store.app.account.number !== this.state.account) {
            this.staticId = 'tile_view_' + this.props.store.app.account.number
            this.state.account = this.props.store.app.account.number
            this.getHistory()
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    getHistory = () => {
        this.setState({ ...this.state, inputs: [], errorInputs: [], isLoading: true }, () => {
            API.get('/api/profile/transmission-details/' + this.props.store.app.account.number)
                .then(res => {
                    console.customLog("GETHISORY", res)
                    let _data = { ...res.data }
                    // debugger
                    _data.zones = sortZonesByDefault(_data.zones);
                    // debugger
                    this.props.setAccountTransmission(_data)
                    this.getHistoryMeters()
                }).catch(err => console.customLog(err))
        })
    }
    getHistoryMeters = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            getHistoryMeters(this.props.store.app.account.number, this.props.setAppHistoryMeters, () => {
                if (this.props.store.app.account.meterNumber) {

                    let lastHistoryItem = this.props.store.history.meters[0];
                    let transItem = this.props.store.app.transmission;

                    const elseFunc = () => {
                        this.setState({
                            ...this.state, isLoading: false, alreadySended: false, inputs: [], notification: {
                                type: 'info',
                                description: 'Передача показаний по 26 число каждого месяца',
                            }
                        }, () => { this.checkInputs() })
                    }
                    
                    if(lastHistoryItem){
                        if(Array.isArray(lastHistoryItem.period)){
                            let lastHistoryItem_date = lastHistoryItem.period.split('-');
                            let transItem_date = transItem.period.split('-');
        
                            if (lastHistoryItem_date[0] === transItem_date[1] && lastHistoryItem_date[1] === transItem_date[0]) {
                                this.setState({ ...this.state, isLoading: false, alreadySended: true, inputs: lastHistoryItem.values, notification: { type: 'success', description: 'Показания за текущий месяц уже переданы' } }, () => { this.checkInputs() })
                            } else {
                                elseFunc()
                            }
                        } else {
                            elseFunc()
                        }
                    } else {
                        elseFunc()
                    }

                } else {
                    this.setState({ ...this.state, isLoading: false })
                }

            }, () => {
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'Показания счётчиков',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })
    }
    send = () => {
        let data = {
            "accountNumber": this.props.store.app.account.number,
            "indications": this.state.inputs,
            "period": "2021-06"
        }
        // console.customLog(data)
        if (!this.state.alreadySended) {
            this.setState({
                ...this.state,
                loadingBtn: true,
            }, () => {
                API.post('api/profile/send-meter-lk', data).then(res => {
                    if (res.data.result) {
                        // this.setState({
                        //     ...this.state, loadingBtn: false, modal: { ...this.state.modal, title: 'Показания за текущий месяц переданы' }
                        // }, () => {
                        //     this.sendModal.current.show()
                        //     this.getHistory();
                        // })
                        this.setState({ ...this.state, loadingBtn: false }, () => {
                            // this.sendModal.current.show()
                            notify({
                                title: "Показания",
                                description: "Показания за текущий месяц переданы",
                                type: 'success'
                            })
                            this.getHistory();
                        })
                    }
                }).catch(err => {
                    // this.setState({
                    //     ...this.state, loadingBtn: false, modal: { ...this.state.modal, title: 'Возникла ошибка при передаче данных' }
                    // }, () => {
                    //     this.sendModal.current.show()
                    //     this.getHistory();
                    // })
                    this.setState({ ...this.state, loadingBtn: false }, () => {
                        // this.sendModal.current.show()
                        notify({
                            title: "Показания",
                            description: "Возникла ошибка при передаче показаний",
                            type: 'warning'
                        })
                        this.getHistory();
                    })
                    console.customLog(err)
                })
            })
        } else {
            this.confirmModal.current.show()
        }
    }
    checkInputs = () => {
        let isValid = true;
        // console.customLog(this.state.inputs, this.state.errorInputs, this.state.isInputsValid, this.state.inputs, this.props.store.app.transmission.zones)
        for (let i = 0; i < this.state.inputs.length; i++) {
            if (this.state.errorInputs[i]) {
                isValid = false
            }
        }

        if (this.state.inputs.length !== this.props.store.app.transmission.zones.length) {
            isValid = false
        }

        this.setState({ ...this.state, isInputsValid: isValid })
    }
    render() {
        let isMeterIsset = this.props.store.app.account.meterNumber ? true : false;
        // console.customLog('isMeterIsset', isMeterIsset)
        return (
            <Tile
                className={"__view"}
                isLoading={this.state.isLoading}
                header={{
                    icon: <Icon src={"tiles/icon__counter.svg"} />,
                    title: 'Показания счётчиков',
                    // note: isMeterIsset ? 'Номер счётчика ' + this.props.store.app.account.meterNumber : '',
                    actions: [
                        <Button
                            key={this.staticId + '_btn_0'}
                            text={"Подробнее"}
                            type={"secondary"}
                            to={'/' + this.props.store.app.account.number + '/history/view'}
                        // onClick={() => { console.customLog('test') }}
                        />,
                    ]
                }}
                footer={{
                    alignEnd: true,
                    note: isMeterIsset ? 'Текущие показания необходимо вводить целым числом до запятой' : "",
                    actions: [
                        isMeterIsset ? <Button
                            key={this.staticId + '_btn_1'}
                            text={"Передать"}
                            isDisabled={!this.state.isInputsValid}
                            type={"primary"}
                            isLoading={this.state.loadingBtn}
                            icon={<Icon src={"icon__tile_action_1.svg"} />}
                            onClick={this.send}
                        /> : null
                    ]
                }}
            >
                {!isMeterIsset ?
                    this.state.isLoading ? null : <>
                        <TileEmptyBox>
                            <Icon src={"icon__tile_view_empty.svg"} />
                            <div className="tile__emptyBox_title">Нет прибора учета электроэнергии</div>
                        </TileEmptyBox>
                    </>
                    :
                    <>
                        <Notification
                            iconName="icon__notification_calendar.svg"
                            type={this.state.notification.type}
                            description={this.state.notification.description}
                            show={true}
                        />
                        <Meter
                            data={this.props.store.app.transmission}
                            accountNumber={this.props.store.app.account.number}
                            meter={{
                                number: this.props.store.app.account.meterName,
                                // name: getZoneTypeByArrLength(this.props.store.app.account.zones.length)
                                name: isMeterIsset ? '№' + this.props.store.app.account.meterNumber : ''
                            }}
                            inputs={this.state.inputs}
                            inputErrors={this.state.errorInputs}
                            setInputs={(inputs, errorInputs) => {
                                this.setState({ ...this.state, inputs, errorInputs }, () => {
                                    this.checkInputs();
                                });
                            }}
                        />
                        <Modal
                            ref={this.confirmModal}
                            icon={<Icon src="icon__confirm_modal.svg" />}
                            title={'Показания за текущий месяц уже переданы. Передать повторно?'}
                            actions={[
                                <Button
                                    key={this.staticId + '_btn_2'}
                                    onClick={() => {
                                        this.setState({ ...this.state, alreadySended: false }, () => {
                                            this.confirmModal.current.hide();
                                            setTimeout(() => {
                                                this.send()
                                            }, 200)
                                        })
                                    }}
                                    type={"primary"}
                                    text={"Да"}
                                />,
                                <Button
                                    key={this.staticId + '_btn_3'}
                                    onClick={() => { this.confirmModal.current.hide() }}
                                    type={"secondary"}
                                    text={"Нет"}
                                />
                            ]}
                        />
                    </>}
            </Tile>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAccountTransmission: setAccountTransmission,
    setAppHistoryMeters: setAppHistoryMeters,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeViewTile);
