import React from "react";
import { NavLink } from "react-router-dom";
import ChatItem from "./chatItem";
import './styles.scss';

class Chat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: this.props.messages,
        }
        this.scroll = React.createRef()
    }
    componentDidMount() {
        this.scrollToEnd()
    }
    componentDidUpdate() {
        this.scrollToEnd()
    }
    scrollToEnd = () => {
        this.scroll.current.scrollTop = this.scroll.current.scrollHeight
    }
    send = () => {
        this.scrollToEnd();
    }
    getItems = () => {
        let html = []
        this.state.items.map(el => {
            let isForeign = el.userId !== 0
            html.push(
                <ChatItem
                    key={el.id}
                    text={el.text}
                    datetime={el.datetime}
                    isForeign={isForeign}
                />
            )
        })
        return html
    }
    render() {
        return (
            <div className={"chat"}>
                <div className={"chat__inner"}>
                    <div className={"chat__view"}>
                        <div className={"chat__scroller"} ref={this.scroll}>
                            <div className={"chat__list"}>
                                {this.getItems()}
                            </div>
                        </div>
                    </div>
                    <div className={"chat__actions"}>
                        <div className={"chat__actions_list"}>
                            <div className={"chat__actions_add"}>
                                {this.props.actions.add}
                            </div>
                            <div className={"chat__actions_input"}>
                                {this.props.actions.input}
                            </div>
                            <div className={"chat__actions_send"}>
                                {this.props.actions.send}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Chat;
