import React from "react";
import './styles.scss';

class Status extends React.Component {
    constructor(props) {
        super(props)
    }
    
    render() {
        let cl = this.props.type ? " __" + this.props.type : "";
        cl += this.props.needIcon ? ' __withIcon' : "";
        cl += this.props.small ? ' __small' : "";
        return (
            <div className={"status" + cl}>
                {this.props.children}
            </div>
        )
    }
}

export default Status;
