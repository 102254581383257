import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import API from "../../API";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import PageTitle from "../../components/pageTitle/pageTitle";
import PaymentForm from "../../components/paymentForm/paymentForm";
import Tile from "../../components/tile/tile";
import { getFormattedNumber, getFormattedNumberPrev, getRawNumber, notify } from "../../helper";
import { setAccountPaymentDetails, setAccountPaymentDetailsRec, setAccountTransmission } from "../../store/slices/app";
import { setAppHistoryMeters } from "../../store/slices/history";
import { getHistoryMeters } from "../history/helper";

class Payment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
        };
    }
    handleClick = () => {

    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)

    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        return (
            <div className={"page _payment" + cl}>
                <div className="container">
                    <div className="_inner">
                        <PageTitle
                            // icon={<Icon src="icon__page_profile.svg" />}
                            text={"Оплата"}
                        />

                        <div className="tiles __small">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
