import React, { lazy } from "react";
import './styles.scss';
import Icon from "../../components/icon/icon";
import { getZoneByType, getZoneIcon, _ZONES } from "../../helper";


class Table extends React.Component {
    constructor(props) {
        super(props)
        this.id = this.props.id ? this.props.id : Math.random();

        this.cellId = 0
    }
    getHead = () => {
        let _head = [];
        this.props.data.head.map(headEl => {
            let txt = headEl.name;
            let cl = '__align_' + headEl.align;
            _head.push(<th className={cl} key={'table_head_' + this.id + '_' + this.cellId}>{txt}</th>)
            this.cellId++;
        })
        // console.customLog(_head)
        return _head
    }
    getBody = () => {
        let _body = [];
        let q = 0
        this.props.data.body.map(row => {
            let _row = [];
            let i = 0;
            row.map(col => {
                // if(typeof col === )
                // _row.push(col);
                let _col = this.getCell(col);

                let cl = '__align_' + this.props.data.head[i].align;

                _row.push(<td className={cl} key={'table_row_' + this.id + '_' + this.cellId + '_' + i}>{_col}</td>);
                i++;
            })
            _body.push(<tr key={'table_body_' + this.id + '_' + this.cellId + '_' + q}>{_row}</tr>);
            q++;
        })
        return _body;
    }
    getCell = col => {
        let _col = [];
        this.cellId++;
        let id = 'table_cell_' + this.id + "_" + this.cellId;
        switch (typeof col) {
            case "object":
                if (col.type === React.Fragment || col.props !== undefined) {
                    _col.push(<span className="__frag" key={id}>{col}</span>)
                }
                else if (Array.isArray(col)) {
                    _col.push(<span className="__arr" key={id}>{this.getCellArray(col)}</span>)
                } else {
                    _col.push(<span className="__obj" key={id}>{this.getCellObject(col)}</span>)
                }
                break;
            case "string":
            case "number":
            default:
                _col.push(<span className="__simple" key={id}>{col}</span>);

                break;
        }
        return _col;
    }
    getCellArray = col => {
        let _col = [];
        col.map(arrItem => {
            let zone = getZoneByType(arrItem.type).icon;
            _col.push(<span className={'__zone'} key={'table_cellArray_item_' + this.id + '_' + this.cellId}>
                {zone}
                {arrItem.value}
            </span>);
            this.cellId++;    
        })
        return _col
    }
    getCellObject = col => {
        let _col = [];
        Object.keys(col).map(key => {
            let value = col[key];
            if (key === 'payment_type') {
                let txt = "";
                _col.push(<span className={"__payment_" + value} key={'table_cellObject_item_' + this.id + '_' + this.cellId}>{col.text}</span>)
            }
            if (key === 'element') {
                _col.push(<span className={"__element"} key={'table_cellObject_item_' + this.id + '_' + this.cellId}>{col.element}</span>)
            }
            this.cellId++;
        })
        return _col
    }
    getCellElement = col => {

    }
    render() {
        return (
            <div className="table_wrapper">
                <div className="table_scroller">
                    <table className="table">
                        <thead className="table__header">
                            <tr>
                                {this.getHead()}
                            </tr>
                        </thead>
                        <tbody className="table__body">
                            {this.getBody()}</tbody>
                        <tfoot className="table__footer"></tfoot>
                    </table>
                </div>
            </div>
        )
    }
}

export default Table;
