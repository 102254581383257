import React from "react";
import { NavLink } from "react-router-dom";
import { animate, easeInOut } from "../billHeader/billHeader";
import Icon from "../icon/icon";
import './styles.scss';

class ButtonToTop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
        }
        // this.setEventListener()
    }
    componentDidMount(){
        window.addEventListener('scroll', e => {
            // console.customLog(window.scrollY, window.scrollY > 100, this.state.isActive)
            if(window.scrollY > 100){
                // console.customLog(window.scrollY, this.state.isActive, 'this.state.isActive')
                if(!this.state.isActive){
                    this.setState({isActive: true})
                }
            } else {
                if(this.state.isActive){
                    this.setState({isActive: false})
                }
            }
        })
    }
    handleClick = () => {
        // window.scrollTo(0, 0)
        this.animateScroll(window.scrollY, 0);
    }
    animateScroll = (from, to) => {
        let time = 400
        animate({
            duration: time,
            timing: easeInOut,
            draw: (progress) => {
                let result = (to - from) * progress + from;
                window.scrollTo(0, result);
            }
        });
    }
    render() {
        let curClass = this.state.isActive ? " __active" : ''
        return (
            <div className={"btnToTop " + curClass} onClick={this.handleClick}>
                <Icon src="icon__arr_btnToTop.svg" />
            </div>
        )
    }
}

export default ButtonToTop;
