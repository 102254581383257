import React from "react";
import Icon from "../../components/icon/icon";
import Tile from "../../components/tile/tile";
import { connect } from "react-redux";
import Table from "../../components/table/table";
import Button from "../../components/button/button";
import { getMonthByNumber, getPaymentStatus, getPaymentType, notify } from "../../helper";
import { setAppHistoryConsumptions, setAppHistoryPayments } from "../../store/slices/history";
import { getHistoryConsumptions, getHistoryConsumptionsChart, getHistoryConsumptionsItems, getHistoryPayments, getHistoryPaymentsItems } from "../history/helper";
import Chart from "../../components/chart/chart";
import TileEmptyBox from "../../components/tile/tileEmptyBox";

class HomeConsumptionTile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            chartData: false,
            paymentHistory: {
                account: '',
            }
        }
        this.staticId = 'tile_view_' + this.props.store.app.account.number
    }
    componentDidUpdate() {

        if (this.props.store.app.account.number !== this.state.paymentHistory.account) {
            this.state.paymentHistory.account = this.props.store.app.account.number
            this.staticId = 'tile_view_' + this.props.store.app.account.number
            console.customLog(this.state.paymentHistory.account)
            this.getHistory()
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    getHistory = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            getHistoryConsumptions(this.props.store.app.account.number, this.props.setAppHistoryConsumptions, () => {
                this.setState({ ...this.state, isLoading: false })
            }, () => {
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'Статистика потребления',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })
    }
    getHistoryChart = () => {
        let body = false;
        if (this.props.store.history.consumptions.length) {
            // this.setState({ ...this.state, isLoading: false })
            body = getHistoryConsumptionsChart(this.props.store.history.consumptions, 6);
        }
        return body
    }
    getChart = chartData => {
        if (chartData) {
            if (chartData._labels.length) {
                let obj = {}
                try {
                    obj = <Chart type={"line"} data={chartData} options={chartOptions} inTile={true} />
                } catch {
                    obj = this.state.isLoading ? null : <TileEmptyBox title="Ваш браузер не поддерживает данный модуль" />
                }
                return obj
            } else {
                return (
                    this.state.isLoading ? null : <TileEmptyBox />
                )
            }
        } else {
            return (
                this.state.isLoading ? null : <TileEmptyBox />
            )
        }
    }
    render() {
        // console.customLog(this.getHistoryChart())
        let chartData = this.getHistoryChart();
        // console.customLog('SET CONS', this.props.store.history.consumptions, chartData)

        return (
            <Tile
                className={"__stats"}
                isLoading={this.state.isLoading}
                header={{
                    icon: <Icon src={"tiles/icon__stat.svg"} />,
                    title: 'Статистика потребления',
                    actions: [
                        <Button
                        key={this.staticId + '_btn_0'}
                            text={"Подробнее"}
                            type={"secondary"}
                            to={'/' + this.props.store.app.account.number + '/history/use'}
                        />,
                    ]
                }}
            >
                {this.getChart(chartData)}
            </Tile>
        )
    }
}

var chartOptions = {
    responsive: true,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    scales: {
        y: {
            display: true,
            title: {
                display: true,
                text: 'кВт*ч'
            }
        },
    },
};

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppHistoryConsumptions: setAppHistoryConsumptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeConsumptionTile);
