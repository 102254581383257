import React from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/input/input";
import Tile from "../../../components/tile/tile";
import { ReactComponent as Brand } from "../../../assets/brand.svg"
import Button from "../../../components/button/button";
import API, { collectJSONFromInput, simplifyJSON } from "../../../API";
import Notification from "../../../components/notifications/notification";
import { Navigate } from "react-router-dom";
import { withRouter } from "../../../withRouter";
import { getNormalError } from "../../../helper";

class Recover_Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isPassed: false,
            targetEMail: '',
            notification: {
                show: false,
                icon: 'icon__notification_calendar.svg',
                type: 'success',
                description: 'Передача показаний по 26 число каждого месяца'
            }
        }
        this.input_accountRef = React.createRef();
        this.staticId = 'recoverIndex_static';
        console.customLog(this)
        console.customLog(Navigate)
    }
    handleSubmit = () => {

        console.customLog('subnit')
        let json = collectJSONFromInput([this.input_accountRef])
        json = simplifyJSON(json)
        console.customLog(json)
        this.setState({ ...this.state, isLoading: true, notification: { show: false } }, () => {
            API.post('/auth/recovery-password', json).then(res => {
                console.customLog(res.data);
                if (res.data.result) {
                    this.setState({ ...this.state, isPassed: true})
                }
            }).catch(err => {
                console.customLog(err);
                this.throwError(err.response.data.message);
            })
        })
    }
    throwError = (text) => {
        let error = getNormalError(text);
        this.setState({
            ...this.state, isLoading: false, notification: {
                show: true,
                icon: 'icon__page_profile.svg',
                type: 'warning',
                description: error,
            }
        })
    }
    render() {
        if (this.state.isPassed) {
            return <Navigate to={"/recover/code"}  replace={true} state={{account:this.input_accountRef.current.getInputData()}} />
        } else {

            return (
                <Tile className={"__facelifted"} >
                    <Form
                        onSubmit={this.handleSubmit}
                        header={{
                            image: <Brand />,
                            title: "Восстановить пароль",
                            // subtitle: "Чтобы создать новый пароль, пожалуйста, введите номер лицевого счёта,
                            back: true,
                        }}
                        notification={<Notification
                            iconName={this.state.notification.icon}
                            type={this.state.notification.type}
                            description={this.state.notification.description}
                            show={this.state.notification.show}
                        />}
                        footer={{
                            actionsAsCol: true,
                            actions: [
                                <Button type="mainPrimary" isLoading={this.state.isLoading} prop="button" text="Продолжить" key={this.staticId + '_btn_0'} />
                            ]
                        }}
                    >
                        <Input
                            title={"Номер лицевого счёта:"}
                            type={"text"}
                            name={"account"}
                            value={process.env.REACT_APP_TESTDATA_RECOVER_ACCOUNT}
                            tip={"Чтобы создать новый пароль, пожалуйста, введите номер лицевого счёта"}
                            ref={this.input_accountRef}
                        />
                    </Form>
                </Tile>
            )
        }
    }
}

export default withRouter(Recover_Index);
