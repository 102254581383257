import React from "react";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import { _SERVICE_DATA } from "../../App";
import Switcher from "../../components/switcher/switcher";
import Tile from "../../components/tile/tile";
import { withRouter } from "../../withRouter";

class AuthReg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        if (this.props.location.pathname === '/') {
            return <Navigate to="/auth" />
        }
        return (
            <div className="_preview">

                <div className="container">
                    <div className="_inner">
                        <div className="_row">
                            <div className="_col _teaser">
                                <div className="_title">
                                    Добро пожаловать!
                                </div>
                                <div className="_subtitle">
                                    Оплачивать счета за электроэнергию теперь легко
                                </div>
                            </div>
                            <div className="_col">
                                <div className="tiles">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AuthReg);
