import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Switcher from "../../components/switcher/switcher";

import {
    Outlet,
    Navigate
} from 'react-router-dom';
import Button from "../../components/button/button";
import { withRouter } from "../../withRouter";

class Requests extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false
        }
        this.staticId = 'requestsIndex_static';
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)

    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        if (this.props.location.pathname === '/requests') {
            return <Navigate to="/requests/list" />
        }
        return (
            <div className={"page _requests" + cl}>
                <div className="container">
                    <div className="_inner">
                        <PageTitle
                            // icon={<Icon src="icon__page_requests.svg" />}
                            text={"Центр поддержки"}
                        />
                        <Switcher items={[
                            {
                                link: '/requests/list',
                                text: 'Обращения',
                                actions: [],
                            },
                            {
                                link: '/requests/contacts',
                                text: 'Контакты',
                                actions: [],
                            },
                        ]}
                            actions={
                                this.props.location.pathname !== '/requests/create' ? [
                                    <Button
                                        key={this.staticId + "_btn_0"}
                                        type={"primary"}
                                        text={"Новое обращение"}
                                        icon={<Icon src={"icon__requests_write.svg"} />}
                                        to={'/requests/create'}
                                    />
                                ] : []}
                        />

                        <Outlet />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Requests);
