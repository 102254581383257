import React from "react"
import API from "../../API"
import ZoneList from "../../components/zone/zoneList"
import { getFormattedDate, getFormattedNumber, getFormattedPeriod, getMonthByNumber, getPaymentStatus, getPaymentType, getViewStatus, getZone, getZoneElement, getZoneIcon, sortZonesByDefault } from "../../helper"

function getHistoryPayments(number, setter, cb = () => { }, cbErr = () => { }) {
    getHistory('/history/payments/' + number, setter, cb, cbErr)
}
function getHistoryMeters(number, setter, cb = () => { }, cbErr = () => { }) {
    getHistory('/history/meters/' + number, setter, cb, cbErr)
}
function getHistoryConsumptions(number, setter, cb = () => { }, cbErr = () => { }) {
    getHistory('/history/consumptions/' + number, setter, cb, cbErr)
}
function getHistoryCalculations(number, setter, cb = () => { }, cbErr = () => { }) {
    getHistory('/history/calculations/' + number, setter, cb, cbErr)
}

function getHistory(url, setter, cb = () => { }, cbErr = () => { }) {
    API.get(url).then(res => {
        // console.customLog(res)
        setter(res.data)
        setTimeout(() => { cb(res) }, 100)
    }).catch(err => {
        console.customLog(err)
        cbErr(err)
    })
}


function getHistoryConsumptionsChart(arr, slice = false) {
    let curPeriod = new Date();
    let data = {
        _labels: [],
        labels: [],
        datasets: []
    }
    curPeriod.setMonth(curPeriod.getMonth() - slice - 1)

    arr.map(el => {
        let date = el.period.split('-');
        let dateMonth = getMonthByNumber(date[0]);
        let dateYear = date[1];

        let label = dateMonth + ' ' + dateYear;

        if (slice) {

            let targetPeriod = new Date();
            let per = el.period.split('-');

            targetPeriod.setMonth(per[0] - 1);
            targetPeriod.setFullYear(per[1]);

            if (targetPeriod > curPeriod) {

            } else {
                return
            }
        }

        data.labels.push(label);
        data._labels.push(el);

        // let _zones = sortZonesByDefault(el.zones);
        let _zones = [];
        let law = false
        el.zones.map(el => {
            if (el.name) {
                law = true;
            }
        })
        if (law) {
            el.zones.map(_zone => {
                let zone = Object.assign({}, _zone, getZone(_zone.name))
                _zones.push(zone)
            })
        } else {
            _zones = sortZonesByDefault(el.zones);
        }
        // debugger

        _zones.map(zone => {
            // let correctZone = getZone(zone.name)
            let dataset = data.datasets.filter(el => el._type === zone.type)[0];
            if (!dataset) {
                // console.customLog(correctZone.type)
                data.datasets.push({
                    _type: zone.type,
                    label: zone.name,
                    data: [],
                    backgroundColor: zone.color,
                    borderColor: zone.color,
                    cubicInterpolationMode: 'monotone',
                    pointBorderColor: '#FFFFFF',
                    pointBorderWidth: 2,
                    pointRadius: 4,
                    pointHoverBorderColor: '#FFFFFF',
                    pointHoverRadius: 6,
                    pointHoverBorderWidth: 3,
                })
            }
        })
    })
    data._labels.map(el => {
        data.datasets.map(dataset => {
            let val = null
            el.zones.map(zone => {
                let correctZone = getZone(zone.name)
                // debugger
                if (dataset._type === correctZone.type) {
                    val = (zone.consumed)
                }
            })
            dataset.data.push(val)
        })
    })

    // console.customLog(data)
    data.labels.reverse()

    data.datasets.map(dataset => {
        dataset.data.reverse()
    })
    return data;
}
function getHistoryCalculationsChart(arr, slice = false) {
    let curPeriod = new Date();
    let data = {
        _labels: [],
        labels: [],
        datasets: []
    }
    curPeriod.setMonth(curPeriod.getMonth() - slice - 1)


    arr.map(el => {
        let date = el.period.split('-');
        let dateMonth = getMonthByNumber(date[0]);
        let dateYear = date[1];

        let label = dateMonth + ' ' + dateYear;

        if (slice) {

            let targetPeriod = new Date();
            let per = el.period.split('-');

            targetPeriod.setMonth(per[0] - 1);
            targetPeriod.setFullYear(per[1]);

            if (targetPeriod > curPeriod) {

            } else {
                return
            }
        }

        data.labels.push(label);
        data._labels.push(el);
    })


    data.datasets.push({
        label: 'Начислено, ₽',
        // data: [],
        data: data._labels.map(el => el.accrued),
        backgroundColor: '#f44236',
        borderColor: '#f44236',
        cubicInterpolationMode: 'monotone',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverBorderColor: '#FFFFFF',
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3,
    })
    data.datasets.push({
        label: 'Оплачено, ₽',
        data: data._labels.map(el => el.paid),
        backgroundColor: '#d4e5ff',
        borderColor: '#d4e5ff',
        cubicInterpolationMode: 'monotone',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverBorderColor: '#FFFFFF',
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3,
    })

    // console.customLog(data)
    data.labels.reverse()

    data.datasets.map(dataset => {
        dataset.data.reverse()
    })
    return data;
}

function getHistoryItems(arr, type = true, slice = null, func = () => { }) {
    let body = [];
    let i = 0;
    let curPeriod = new Date();
    curPeriod.setMonth(curPeriod.getMonth() - slice - 1)

    // debugger

    arr.map(el => {
        let row = func(el);

        if (i > 3 && !type) {
            return
        }
        i++;
        if (slice) {
            let targetPeriod = new Date();
            let per = el.period.split('-');

            targetPeriod.setMonth(per[0] - 1);
            targetPeriod.setFullYear(per[1]);

            // console.customLog(el.period, curPeriod, targetPeriod, curTargetPeriod)
            if (targetPeriod > curPeriod) {
                body.push(row)
            } else {
                return
            }
        } else {
            body.push(row);
        }
    })
    return body;
}

function getHistoryPaymentsItems(arr, type = true, slice = null) {
    let _arr = [...arr];

    _arr.sort(function (a, b) {
        // console.customLog({a,b})
        return new Date(b.date) - new Date(a.date);
    });

    function getRow(el) {
        let row = [];

        row[0] = getFormattedPeriod(el.period);
        row[1] = <>{getFormattedDate(new Date(el.date), true)}</>
        row[2] = getFormattedNumber(el.amount)

        if (type) {
            row[3] = el.bank
            row[4] = getPaymentType(el.status)
            row[5] = getPaymentStatus(el.paymentType)
        } else {
            row[3] = getPaymentType(el.status)
            row[4] = getPaymentStatus(el.paymentType)
        }

        return row;
    }

    let body = getHistoryItems(_arr, type, slice, getRow)

    return body;
}
function getHistoryMetersItems(arr, type = true, slice = null) {
    let _arr = [...arr];

    _arr.sort(function (a, b) {
        // console.customLog({a,b})
        return new Date(b.date) - new Date(a.date);
    });


    function getRow(el) {
        let row = [];
        let zones = [];

        el.values.map(zone => {
            zones.push({
                name: zone.name,
                value: zone
            })
        })

        row[0] = getFormattedPeriod(el.period);
        row[1] = <>{getFormattedDate(new Date(el.date), true)}</>
        row[2] = <ZoneList id={'historyMeters_0'} zones={zones} />
        row[3] = el.type
        row[4] = getViewStatus(el.status);

        return row;
    }

    let body = getHistoryItems(_arr, type, slice, getRow);

    return body;
}
function getHistoryCalculationsItems(arr, type = true, slice = null) {
    function getRow(el) {
        let row = [];
        let zones_1 = [];
        let zones_2 = [];

        el.zones.map(zone => {
            zones_1.push({
                name: zone.name,
                value: zone.tariff,
                formatted: true
            })
            zones_2.push({
                name: zone.name,
                value: zone.consumed
            })
        })

        row[0] = getFormattedPeriod(el.period);
        row[1] = <ZoneList id={'historyCalculations_0'} zones={zones_1} />
        row[2] = <ZoneList id={'historyCalculations_1'} zones={zones_2} />
        row[3] = getFormattedNumber(el.correctionAmount);
        row[4] = getFormattedNumber(el.accrued);
        row[5] = getFormattedNumber(el.paid);

        return row;
    }

    let body = getHistoryItems(arr, type, slice, getRow);

    return body;
}
function getHistoryConsumptionsItems(arr, type = true, slice = null) {
    function getRow(el) {
        let row = [];
        let zones_1 = [];
        let zones_2 = [];

        // debugger
        el.zones.map(zone => {
            zones_1.push({
                name: zone.name,
                value: zone.tariff,
                formatted: true
            })
            zones_2.push({
                name: zone.name,
                value: zone.consumed
            })
        })

        row[0] = getFormattedPeriod(el.period);
        row[1] = <ZoneList id={'historyConsumption_0'} zones={zones_1} />
        row[2] = <ZoneList id={'historyConsumption_1'} zones={zones_2} />

        return row;
    }

    let body = getHistoryItems(arr, type, slice, getRow);

    return body;
}

export {
    getHistoryPayments,
    getHistoryMeters,
    getHistoryConsumptions,
    getHistoryCalculations,
    getHistoryMetersItems,
    getHistoryPaymentsItems,
    getHistoryConsumptionsItems,
    getHistoryConsumptionsChart,
    getHistoryCalculationsItems,
    getHistoryCalculationsChart
}