import React, { lazy } from "react";
import './styles.scss';

class Tile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
        // console.customLog('TILE MOUNTED')
    }
    toggle = type => {
        this.setState({ ...this.state, isActive: type }, () => { console.customLog(this.state.isActive) })
    }
    // componentDidUpdate(){
    //     console.customLog('TILE UPDATED')
    // }
    render() {
        let cl = this.props.className ? " " + this.props.className : ""
        cl += this.state.isActive ? " __visible" : ""
        cl += this.props.isLoading ? " __loading" : ""
        cl += this.props.noPadding ? " __noPadding" : ""
        cl += this.props.gapContent ? " __gapContent" : ""
        cl += this.props.hide ? " __hidden" : ""
        return (
            <div className={"tile" + cl}>
                {this.props.header ?
                    <div className="tile__header">
                        <div className="tile__header_title">
                            {this.props.header.icon}
                            {this.props.header.title}
                        </div>
                        {this.props.header.note ?
                            <div className="tile__header_note">
                                {this.props.header.note}
                            </div>
                            : null}
                        {this.props.header.actions ?
                            <div className="tile__header_actions">
                                {this.props.header.actions}
                            </div>
                            : null}
                    </div>
                    : null}
                <div className="tile__content">
                    <div className="tile__content_inner">
                        {this.props.children}
                    </div>
                </div>
                {this.props.footer ?
                    this.props.footer.custom ? this.props.footer.custom :
                        <div className={"tile__footer " + (this.props.footer.note ? "" : "__alt") + (this.props.footer.alignEnd ? " __end" : "")}>
                            {this.props.footer.note ?
                                <div className="tile__footer_note">
                                    {this.props.footer.note}
                                </div>
                                : null}
                            <div className="tile__footer_actions">
                                {this.props.footer.actions}
                            </div>
                        </div>
                    : null}
            </div>
        )
    }
}

export default Tile;
