import React from "react";
import Icon from "../../components/icon/icon";
import Tile from "../../components/tile/tile";

class Requests_Questions extends React.Component {
    render() {
        return (
            <div className="tiles __small __align_fs">
                <Tile
                    header={{
                        title: "Вопросы по личному кабинету",
                        icon: <Icon src="icon__page_profile.svg" />,
                    }}
                />
            </div>
        )
    }
}

export default Requests_Questions;
