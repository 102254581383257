import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Switcher from "../../components/switcher/switcher";

import {
    Outlet,
    Navigate
} from 'react-router-dom';
import Button from "../../components/button/button";
import { connect } from "react-redux";
import Select from "../../components/select/select";
import { setAppHistoryLimit } from "../../store/slices/history";
import { withRouter } from "../../withRouter";

class History extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false
        }
        console.customLog(this)
    }
    handleLogout = () => {

        this.props.setLogout()
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        if (this.props.location.pathname === '/' + this.props.store.app.account.number + '/history') {
            return <Navigate to={"/" + this.props.store.app.account.number + "/history/payment"} />
        }
        return (
            <div className={"page _history" + cl}>
                <div className="container">
                    <div className="_inner">
                        <PageTitle
                            // icon={<Icon src="icon__page_profile.svg" />}
                            text={"История"}
                        />
                        <Switcher items={[

                            {
                                link: '/' + this.props.store.app.account.number + '/history/payment',
                                text: 'История платежей',
                                actions: [],
                            },
                            {
                                link: '/' + this.props.store.app.account.number + '/history/view',
                                text: 'История показаний',
                                actions: [],
                            },
                            {
                                link: '/' + this.props.store.app.account.number + '/history/stat',
                                text: 'Статистика расчётов за электроэнергию (ЭЭ)',
                                actions: [],
                            },
                            {
                                link: '/' + this.props.store.app.account.number + '/history/use',
                                text: 'Статистика потребления',
                                actions: [],
                            },
                        ]}
                            actions={[
                                <Select key={1}
                                    onChange={(e) => { this.props.setAppHistoryLimit(e.value) }}
                                    options={[
                                        {
                                            value: 6,
                                            text: 'за 6 месяцев',
                                            default: true,
                                        },
                                        {
                                            value: 12,
                                            text: 'за 1 год'
                                        },
                                        {
                                            value: false,
                                            text: 'за всё время'
                                        },
                                    ]} />
                            ]}
                        />

                        <Outlet limit={1} />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, service) => ({
    service,
    store: state
});

const mapDispatchToProps = { setAppHistoryLimit: setAppHistoryLimit };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(History));
