import React from "react";
import { connect } from "react-redux";
import {
    Navigate
} from 'react-router-dom';
import Button from "../../components/button/button";
import { setAccountSelect, setBackButtonVisibility, setBillHeaderVisibility } from "../../store/slices/app";
import { withRouter } from "../../withRouter";
import { ReactComponent as Back } from "../../assets/ref__404.svg"
import { setLogout } from "../../store/slices/auth";
import { setCookie } from "../../helper";

class NotFound extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }
    handleClick = () => {
        this.props.setLogout();
    }
    render() {
        if (this.props.inHome === false) {
            setCookie('backUrl', this.props.location.pathname)
            return <Navigate to="/" />
        }
        return (
            <div className="notFound">
                <div className="notFound__back">
                    <Back />
                </div>
                <div className="notFound__outer">
                    <div className="notFound__inner">
                        <div className="notFound__title">
                            404
                        </div>
                        <div className="notFound__description">
                            Страница не найдена
                        </div>
                        <div className="notFound__actions">
                            {this.props.noAuth ?
                                <Button type={"primary"} text={"Войти"} onClick={this.handleClick} />
                            : 
                                <Button type={"primary"} to={"/"} text={"На главную"} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAccountSelect: setAccountSelect,
    setLogout: setLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotFound));
