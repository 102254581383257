import React from "react";
// import Date from "../../../../components/date/date";
import Icon from "../../../../components/icon/icon";
import Status from "../../../../components/status/status";
import { getFormattedDate } from "../../../../helper";
import './styles.scss';

class RequestList extends React.Component {
    getList = () => {
        return this.props.items.map(item => {
            return this.getItem(item)
        })
    }
    getItem = item => {
        let curDate = getFormattedDate(new Date(item.answers[0].date), true, true)
        let isActive = this.props.curItemId === item.id ? ' __active' : "";
        let status = item.answers[item.answers.length - 1].status
        let unseen = item.answers.filter(el => el.unseen)[0] ? 1 : 0
        console.customLog(item)
        return (
            <div className={"request__list_item " + isActive} key={'requestList_item_' + item.id} onClick={e => { this.props.action(item.id) }}>
                <div className="request__top">
                    <div className="request__date">{curDate}</div>
                    {status !== 'отправлено' ?
                        <div className="request__status">{this.getStatus(status)}</div>
                        : null}
                </div>
                <div className="request__title">
                    {item.answers[0].text}
                </div>
                <div className="request__action">
                    <Icon src="icon__notification_action.svg" />
                </div>
                    {unseen ?
                        <div className="request__new">Новые сообщения</div>
                        : null}
            </div >
        )
    }

    getStatus = (text) => {
        let st = ""
        switch (text.toLowerCase()) {
            case "требует уточнения":
                st = "wait"
                break;
            case "на рассмотрении":
                st = "wait"
                break;
            case "отправлено":
                st = false
                break;
            case "закрыто":
                st = "success"
                break;
        }
        if (st) {
            let _text = text.split('');
            _text[0] = _text[0].toUpperCase();
            _text = _text.join('')
            return (
                // <div className={"__status" + st + " __small"}>{_text}</div>
                <Status type={st} small={true}>{_text}</Status>
            )
        }
    }
    render() {
        return (
            <div className="request__list">
                {this.getList()}
            </div>
        )
    }
}

export default RequestList;
