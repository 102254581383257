import React from "react";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import API from "../../API";
import { _SERVICE_DATA } from "../../App";
import Switcher from "../../components/switcher/switcher";
import Tile from "../../components/tile/tile";
import { withRouter } from "../../withRouter";

class AuthRegContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            year: ''
        }
        this.linksId = 0
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
        this.getYear()
    }
    getYear = () => {
        API.get('/api/service/time').then(res => {
            // this.initTime(res.data.currentTime)

            console.customLog('TIME', res.data.currentTime)
            let time = res.data.currentTime;
            let t = time.split('.')[0];
            let date = new Date(t)
            this.setState({ ...this.state, year: date.getFullYear() })
        }).catch(err => {
            // console.customLog("TIME", err)
        })
    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        if (this.props.location.pathname === '/') {
            return <Navigate to="/auth" />
        }
        return (
            <div className={"page _auth" + cl}>
                <Outlet />
                <div className="_footer">
                    <div className="container">
                        <div className="_inner">
                            <div className="_links">
                                {_SERVICE_DATA.footer.links.map(el => {
                                    this.linksId++;
                                    return (
                                        <a href={el.link} key={'authRegLinks_' + this.linksId} target="_blank">{el.text}</a>
                                    )
                                })}
                            </div>
                            <div className="_copyright">
                                {_SERVICE_DATA.footer.copyright}&#8209;{this.state.year}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AuthRegContainer);
