import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import API from "../../API";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import PageTitle from "../../components/pageTitle/pageTitle";
import QrPage from "../../components/qrPage/qrPage";
import PaymentForm from "../../components/paymentForm/paymentForm";
import Tile from "../../components/tile/tile";
import { getFormattedNumber, getFormattedNumberPrev, getFormattedPrice, getFormattedPriceValue, getRawNumber, notify, sortZonesByDefault } from "../../helper";
import { setAccountPaymentDetails, setAccountPaymentDetailsRec, setAccountTransmission } from "../../store/slices/app";
import { setAppHistoryMeters } from "../../store/slices/history";
import { getHistoryMeters } from "../history/helper";

class PaymentAction extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            zones: [],
            inputs: [],
            errorInputs: [],
            isLoading: true,
            isActive: false,
            isBtnLoading: false,
            isBtnDisabled: true,
            isInputsValid: false,
            cbx: {
                bill: true,
                agree: false,
            }
        };
        this.acc_id = this.props.store.app.account.id
    }
    handleClick = () => {

    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)

    }
    componentDidUpdate = () => {
        if (this.props.store.app.account.number !== this.state.account) {
            this.state.account = this.props.store.app.account.number
            this.getHistory()
        }
        // if(this.acc_id !== this.props.store.app.account.id){
        //     this.checkBtn()
        //     this.acc_id = this.props.store.app.account.id
        // }
    }
    getHistory = () => {
        this.setState({ ...this.state, inputs: [], errorInputs: [], isLoading: true, cbx: { ...this.state.cbx, agree: false } }, () => {
            API.get('/api/profile/transmission-details/' + this.props.store.app.account.number)
                .then(res => {
                    console.customLog("GETHISORY", res)
                    let _data = { ...res.data }
                    // debugger
                    _data.zones = sortZonesByDefault(_data.zones);
                    // debugger
                    this.props.setAccountTransmission(_data)
                    this.getPaymentDetails()
                    // this.setState({ ...this.state, isLoading: false })
                }).catch(err => console.customLog(err))
        })
    }
    getPaymentDetails = () => {
        API.get('/api/pay/paymentDetails/' + this.props.store.app.account.number)
            .then(res => {
                console.customLog("GET PAYMENT DETAILS", res)
                this.props.setAccountPaymentDetails(res.data)
                // this.props.setAccountTransmission(res.data)
                // this.getPaymentDetails()
                this.getHistoryMeters()
                // this.setState({ ...this.state, isLoading: false })
            }).catch(err => console.customLog(err))

    }
    getHistoryMeters = () => {
        getHistoryMeters(this.props.store.app.account.number, this.props.setAppHistoryMeters, () => {
            if (this.props.store.app.account.meterNumber) {

                let lastHistoryItem = this.props.store.history.meters[0];
                let transItem = this.props.store.app.transmission;

                const elseFunc = () => {
                    this.setState({
                        ...this.state, isLoading: false, alreadySended: false, inputs: [], notification: {
                            type: 'info',
                            description: 'Передача показаний по 26 число каждого месяца',
                        }
                    }, () => { this.checkInputs() })
                }

                if (lastHistoryItem) {
                    if (Array.isArray(lastHistoryItem.period)) {
                        let lastHistoryItem_date = lastHistoryItem.period.split('-');
                        let transItem_date = transItem.period.split('-');

                        if (lastHistoryItem_date[0] === transItem_date[1] && lastHistoryItem_date[1] === transItem_date[0]) {
                            this.setState({ ...this.state, isLoading: false, alreadySended: true, inputs: lastHistoryItem.values, notification: { type: 'success', description: 'Показания за текущий месяц уже переданы' } }, () => { this.checkInputs() })
                        } else {
                            elseFunc()
                        }
                    } else {
                        elseFunc()
                    }
                } else {
                    elseFunc()
                }
            } else {
                this.setState({
                    ...this.state, isLoading: false, isInputsValid: true
                }, () => {
                    this.checkForm();
                })
            }
            // let val = getFormattedNumberPrev(this.props.store.app.account.balance.debt);
            let deb = -(this.props.store.app.account.balance.debt + this.props.store.app.account.balance.penalty - this.props.store.app.account.balance.accepted)
            let val = getFormattedNumberPrev(-deb);
            this.props.setAccountPaymentDetailsRec(val)
            // setTimeout(, 50)
            // this.checkBtn()
            // debugger

        }, () => {
            this.setState({ ...this.state, isLoading: false })
            notify({
                title: 'Показания счётчиков',
                description: 'Ошибка при загрузке данных',
                type: 'warning'
            })
        })
    }
    checkInputs = () => {
        let isValid = true,
          wasBlank = false, wasFill = false
        // console.customLog(this.state.inputs, this.state.errorInputs, this.state.isInputsValid, this.state.inputs, this.props.store.app.transmission.zones)
        for (let i = 0; i < this.state.inputs.length; i++) {
            if (this.state.errorInputs[i]) {
                wasBlank = true
            } else wasFill = true
        }        
        if (this.state.inputs.length !== this.props.store.app.transmission.zones.length) {
            wasBlank = true
        }
        isValid = !wasBlank || !wasFill
        // debugger
        // console.customLog(isValid)

        this.setState({ ...this.state, isInputsValid: isValid }, this.checkBtn)
    }
    handleMainChange = (e) => {
        let val = e.target.value;
        let formVal = val.split(',');
        let targetVal;
        if (formVal[0]) {
            targetVal = parseInt(formVal[0]) + (formVal[1] !== undefined ? ',' + formVal[1] : "");
        } else {
            targetVal = 0
        }
        console.customLog(val, formVal)
        this.props.setAccountPaymentDetailsRec(getFormattedNumber(targetVal))
        // debuggera
        setTimeout(() => {
            this.checkForm()
        }, 10)
    }
    handleMainBlur = (e) => {
        let val = getFormattedNumberPrev(e.target.value)
        this.props.setAccountPaymentDetailsRec(val)
        setTimeout(() => { console.customLog(this.props.store.app.paymentDetails.rec) }, 1000)
        this.checkForm()
    }
    handleMainFocus = (e) => {
        let val = e.target.value.replaceAll(' ', '')
        this.props.setAccountPaymentDetailsRec(val)
    }
    handleCbx = (e, name) => {
        if (name === 'bill') {
            this.setState({ ...this.state, cbx: { ...this.state.cbx, bill: !this.state.cbx.bill } }, this.checkForm)
        }
        if (name === 'agree') {
            this.setState({ ...this.state, cbx: { ...this.state.cbx, agree: !this.state.cbx.agree } }, this.checkForm)
        }
    }
    checkForm = () => {
        this.checkInputs()
    }
    onSubmit = (isSBP) => {
        this.setState({ ...this.state, isBtnLoading: true }, () => {
            // console.customLog(this.state.inputs, getRawNumber(this.props.store.app.paymentDetails.rec), this.state.cbx, this.props.store)
            let json = {
                accountNumber: this.props.store.app.account.number,
                amount: getRawNumber(this.props.store.app.paymentDetails.rec).toString(),
                // indications: this.state.inputs,
                period: this.props.store.app.transmission.period,
                rejectReply: !this.state.cbx.bill,
                deepLink: window.location.href,
                osType: ''
            }
            if (this.props.store.app.account.meterNumber) {
                json.indications = this.state.inputs
            }
            if (!isSBP)
              API.post('/api/pay/make-paymnet', json).then(res => {
                console.customLog(res)
                this.setState({ ...this.state, isBtnLoading: false }, () => {
                    if (res.data.formUrl) {
                        window.location.href = res.data.formUrl
                    }
                })
              }).catch(err => {
                this.setState({ ...this.state, isBtnLoading: false }, () => { })
                notify({
                    type: 'warning',
                    title: 'Оплата',
                    description: 'Ошибка при совершении платежа',
                })
                console.customLog(err)
              })
            else 
              API.post('/api/pay/make-pay-sbp', json).then(res => {
                console.customLog(res)
                if (!res.data.address) throw new Error('no qrcId')
                this.setState({ showQR: res.data.address, isBtnLoading: false })
              }).catch(err => {
                this.setState({ ...this.state, isBtnLoading: false }, () => { })
                notify({
                    type: 'warning',
                    title: 'Оплата',
                    description: 'Ошибка связи с СБП',
                })
                console.customLog(err)
              })

        })
    }
    checkBtn = () => {
        // this.checkInputs()
        let isMeterIsset = this.props.store.app.account.meterNumber ? true : false;
        // console.customLog('check', this.state.isInputsValid, !this.state.cbx.agree)
        // debugger
        if (!this.state.cbx.agree) {
            this.setState({ ...this.state, isBtnDisabled: true })
            return;
        }
        if (isMeterIsset) {
            if (!this.state.isInputsValid) {
                this.setState({ ...this.state, isBtnDisabled: true })
                return;
            }
        }
        console.customLog(getRawNumber(this.props.store.app.paymentDetails.rec), 'rec')
        // debugger
        if (getRawNumber(this.props.store.app.paymentDetails.rec) > 0) {
            this.setState({ ...this.state, isBtnDisabled: false })
        } else {
            this.setState({ ...this.state, isBtnDisabled: true })
        }
    }
    render() {
        return (
            <Tile
                isLoading={this.state.isLoading}
            >
                {/* {!this.state.isLoading ? */}
                {!!this.state.showQR && <QrPage 
                    address={this.state.showQR}
                    sum={getRawNumber(this.props.store.app.paymentDetails.rec)}
                    callback={() => this.setState({showQR: null},
                        ()=>window.history.back())}
                />}
                <PaymentForm
                    data={this.props.store.app.transmission}
                    details={this.props.store.app.paymentDetails}
                    inputs={this.state.inputs}
                    inputErrors={this.state.errorInputs}
                    onSubmit={this.onSubmit}
                    handleMainFocus={this.handleMainFocus}
                    handleMainBlur={this.handleMainBlur}
                    handleMainChange={this.handleMainChange}
                    handleCbx={this.handleCbx}
                    cbx={this.state.cbx}
                    isBtnLoading={this.state.isBtnLoading}
                    isBtnDisabled={this.state.isBtnDisabled}
                    setInputs={(inputs, errorInputs) => {
                        this.setState({ ...this.state, inputs, errorInputs }, () => {
                            this.checkForm();
                        });
                    }}
                />
                {/* : null} */}
            </Tile>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAccountTransmission: setAccountTransmission,
    setAppHistoryMeters: setAppHistoryMeters,
    setAccountPaymentDetails: setAccountPaymentDetails,
    setAccountPaymentDetailsRec: setAccountPaymentDetailsRec,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAction);
