import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import API from "../../API";
import { _SERVICE_DATA } from "../../App";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Tile from "../../components/tile/tile";
import TileInfoBox from "../../components/tile/tileInfoBox";
import { notify } from "../../helper";
import { setAppAccounts, setNewAccountNumber } from "../../store/slices/app";
import { withRouter } from "../../withRouter";

class Profile_Add extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true,
            isInputError: false,
            isButtonLoading: false,
            needRedirect: false,
        }
        this.staticId = 'profileAdd_static';
        console.customLog(this)
    }
    componentDidMount() {
        // this.update()
    }
    add = () => {
        let data = {
            'account': this.props.store.app.newAccountNumber
        }
        API.post('/api/profile/add-account', data).then(res => {
            console.customLog(res)
            if (res.status === 200) {
                this.handleSuccess()
            } else {
                this.handleError()
            }
        }).catch(err => {
            console.customLog(err)
            this.handleError()
        })
    }
    check = () => {
        if (this.props.store.app.newAccountNumber) {
            if (this.props.store.app.newAccountNumber.length === 9) {
                this.setState({ ...this.state, isInputError: false, isButtonLoading: true }, () => {
                    this.add()
                })
            } else {
                this.setState({ ...this.state, isInputError: true })
                notify({
                    title: 'Лицевые счета',
                    description: 'Номер лицевого счёта должен cодержать 9&nbsp;символов',
                    type: 'alert'
                })
            }
        } else {
            this.setState({ ...this.state, isInputError: true })
            notify({
                title: 'Лицевые счета',
                description: 'Номер лицевого счёта пустой',
                type: 'warning'
            })
        }
    }
    handleClick = () => {
        this.check()
    }
    handleChange = (e) => {
        let val = e.target.value
        if (val.length > 9) {
            return
        }
        this.props.setNewAccountNumber(val)
    }
    handleSuccess = () => {
        this.props.setNewAccountNumber(null)
        this.setState({ ...this.state, isButtonLoading: false, needRedirect: true })
        notify({
            title: 'Лицевые счета',
            description: 'Лицевой счёт успешно добавлен',
            type: 'success'
        })
        this.props.navigate('/profile/accounts')
    }
    handleError = () => {
        this.setState({ ...this.state, isButtonLoading: false })
        notify({
            title: 'Лицевые счета',
            description: 'Введен неверный  лицевой счёт',
            type: 'warning'
        })
    }
    getAccountTiles = () => {
        return (
            <Tile
                isLoading={!this.state.isLoaded}
                header={{
                    title: "Добавить новый лицевой счёт",
                    icon: <Icon src="brand.svg" />,
                }}
                gapContent={true}
            >
                <Form
                    footer={{
                        actions: [
                            <Button
                                type="primary"
                                text="Добавить"
                                onClick={this.handleClick}
                                key={this.staticId + '_btn_0'}
                                isLoading={this.state.isButtonLoading}
                            />,
                        ]
                    }}
                >
                    <Input
                        title={"Номер лицевого счета:"}
                        type={"number"}
                        hardValue={this.props.store.app.newAccountNumber}
                        onChange={this.handleChange}
                        isError={this.state.isInputError}
                    // status={el.paperBill}
                    // isDisabled={true}
                    />
                </Form>
            </Tile>
        )
    }
    render() {
        return (
            <div className="tiles __small">
                {this.getAccountTiles()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppAccounts: setAppAccounts,
    setNewAccountNumber: setNewAccountNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile_Add));

