import { createSlice } from '@reduxjs/toolkit'

export const AppSlice = createSlice({
  name: 'app',
  initialState: {
    accounts: [],
    account: {},
    newAccountNumber: null,
    transmission: {},
    notificationFeed: [],
    paymentDetails: {
      rec: null
    },
    notificationFeedLastLostId: null,
    profile: {
      id: '',
      email: '',
      phone: '',
    },
    ui: {
      backButton: true,
      billHeader: true,
      requestIndicator: false,
      alreadyShowedPhoneModal: false,
      alreadyShowedBillModal: false
    }
  },
  reducers: {
    setAppAccounts: (state, action) => {
      let i = 0;
      action.payload.map(el => {
        el.isSelected = false;
        el.id = i;
        if (el.main) {
          el.isSelected = true;
          state.account = el
        }
        i++;
      })
      // action.payload.sort((x,y) => {
      //   return (x === y)? 0 : x? -1 : 1;
      // })
      // console.customLog(action.payload)
      action.payload.sort((prev, next) => parseInt(prev.number) - parseInt(next.number))
      action.payload.sort((prev, next) => next.main - prev.main)
      // console.customLog(action.payload)
      state.accounts = action.payload;
    },
    setAppAccount: (state, action) => {
      state.accounts.map(account => {
        if (account.number === action.payload.number) {
          if (account.isSelected) {
            action.payload.isSelected = true;
            state.account = action.payload
          }
          // account = action.payload
        }
      })
    },
    setAccountSelect: (state, action) => {
      console.customLog(action.payload)
      state.accounts.map(account => {
        account.isSelected = false
        console.customLog(account.number, action.payload, account.number === action.payload)
        if (account.number === action.payload) {
          account.isSelected = true
          state.account = account
        }
      })
    },
    setAccountTransmission: (state, action) => {
      state.transmission = action.payload
    },
    setAccountPaymentDetails: (state, action) => {
      // action.payload.rec = action.payload.amount
      state.paymentDetails = action.payload
    },
    setAccountPaymentDetailsRec: (state, action) => {
      state.paymentDetails.rec = action.payload
    },
    setProfileInfo: (state, action) => {
      state.profile.id = action.payload.id
      state.profile.email = action.payload.email
      state.profile.phone = action.payload.phone
    },
    setProfileEmail: (state, action) => {
      state.profile.email = action.payload
    },
    setProfilePhone: (state, action) => {
      state.profile.phone = action.payload
    },
    setNewAccountNumber: (state, action) => {
      state.newAccountNumber = action.payload
    },
    setNotificationFeed: (state, action) => {
      state.notificationFeed = action.payload
    },
    addToNotificationFeed: (state, action) => {
      state.notificationFeed.push(action.payload)
    },
    setBackButtonVisibility: (state, action) => {
      state.ui.backButton = action.payload
    },
    setBillHeaderVisibility: (state, action) => {
      state.ui.billHeader = action.payload
    },
    setRequestIndicator: (state, action) => {
      state.ui.requestIndicator = action.payload
    },
    setAlreadyShowedPhoneModal: (state, action) => {
      state.ui.alreadyShowedPhoneModal = action.payload
    },
    setAlreadyShowedBillModal: (state, action) => {
      state.ui.alreadyShowedBillModal = action.payload
    },
    setNotificationFeedItemStatus: (state, action) => {
      state.notificationFeed.map(el => {
        if (el.id === action.payload.id) {
          el.show = action.payload.show
          if (!action.payload.show) {
            state.notificationFeedLastLostId = el.id
          }
        }
      })
    },
    resetState: (state, action) => {
      state = {
        accounts: [],
        account: {},
        newAccountNumber: null,
        transmission: {},
        notificationFeed: [],
        paymentDetails: {
          rec: null
        },
        notificationFeedLastLostId: null,
        profile: {
          id: '',
          email: '',
          phone: '',
        },
        ui: {
          backButton: true,
          billHeader: true,
          requestIndicator: false,
          alreadyShowedPhoneModal: false,
          alreadyShowedBillModal: false
        }
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  resetState,
  setAppAccount,
  setAppAccounts,
  setProfileInfo,
  setProfilePhone,
  setProfileEmail,
  setAccountSelect,
  setRequestIndicator,
  setNotificationFeed,
  setNewAccountNumber,
  addToNotificationFeed,
  setAccountTransmission,
  setBackButtonVisibility,
  setBillHeaderVisibility,
  setAccountPaymentDetails,
  setAlreadyShowedBillModal,
  setAlreadyShowedPhoneModal,
  setAccountPaymentDetailsRec,
  setNotificationFeedItemStatus,
} = AppSlice.actions

export default AppSlice.reducer