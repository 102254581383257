import React from "react";
import API from "../../API";
import { animate, easeInOut } from "../../components/billHeader/billHeader";
import Button from "../../components/button/button";
import Icon from "../../components/icon/icon";
import Tile from "../../components/tile/tile";
import TileEmptyBox from "../../components/tile/tileEmptyBox";
import { getMutatedRequests, notify, setLocalRequests, updateLocalRequests } from "../../helper";
import { withRouter } from "../../withRouter";
import RequestItem from "./components/request/requestItem";
import RequestList from "./components/request/requestList";


class Requests_Indications extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: false,
            items: [],
            currentItem: false,
            curDialogId: false
        }
        this.staticId = 'requestsIndications_static_';
        this.requestItemRef = new React.createRef()
    }
    componentDidMount() {
        this.update()
    }
    update = () => {
        this.setState({
            ...this.state,
            isLoading: true,
            curDialogId: parseInt(window.location.hash.replace("#", ""))
        }, () => {
            API.get("/question/").then((res) => {
                console.customLog(res.data)
                setLocalRequests(res.data)
                this.updateRequests(res.data)
                console.customLog(this.state)
            }).catch(err => {
                console.customLog(err)
                notify({
                    title: 'Обращение',
                    description: 'Ошибка при загрузке обращений',
                    type: "error"
                })
            })
        })
    }
    handleAction = (id) => {
        console.customLog('action ', id)
        let curItem = this.state.items.filter(el => el.id === id)[0];
        this.requestItemRef.current.toggle(false)
        setTimeout(() => {
            this.setState({ ...this.state, currentItem: curItem }, () => {
                window.location.hash = curItem.id;
                this.requestItemRef.current.toggle(true)
                this.setState({ ...this.state, isLoading: true }, () => {
                    updateLocalRequests(curItem.id)
                    this.updateRequests();
                })
                this.setState({ ...this.state, curDialogId: curItem.id }, () => {
                    if (window.innerWidth < 769) {
                        let from = window.scrollY;
                        let to = document.querySelector('.content').offsetTop + 50
                        this.animateScroll(from, to)
                    }
                })
            })
        }, 400)
        console.customLog(this.requestItemRef.current)
    }
    goBack = () => {
        window.history.back()
        this.setState({ ...this.state, curDialogId: false })
    }
    animateScroll = (from, to) => {
        if (this.isScrolling) {
            return
        }
        this.isScrolling = true
        let time = 400
        setTimeout(() => { this.isScrolling = false }, time)
        animate({
            duration: time,
            timing: easeInOut,
            draw: (progress) => {
                let result = (to - from) * progress + from;
                window.scrollTo(0, result)
            }
        });
    }
    updateRequests = (data) => {
        let _data = getMutatedRequests(data);
        // debugger

        let curItem;
        if (this.state.curDialogId) {
            curItem = _data.filter(el => el.id === this.state.curDialogId)[0]
        } else {
            // curItem = _data[0]
            // debugger
            // updateLocalRequests(curItem.id)
        }
        this.setState({ ...this.state, items: _data, currentItem: curItem, isLoading: false }, () => {
            if (!this.state.curDialogId) {
                if (_data.length) {
                    this.handleAction(_data[0].id)
                }
            }
        })
    }
    render() {
        return (
            <div className={"tiles __small_bar __tile_flex_fs __align_fs __special_request" + (this.state.curDialogId ? " __dialog" : "")}>
                <Tile noPadding={this.state.items.length} isLoading={this.state.isLoading}>
                    {this.state.items.length ?
                        <RequestList items={this.state.items} curItemId={this.state.currentItem?.id} action={this.handleAction} />
                        :
                        this.state.isLoading ? null : <TileEmptyBox />
                    }
                </Tile>
                {this.state.items.length ?
                    <Tile ref={this.requestItemRef} >
                        {window.innerWidth < 769 ?
                            <div style={{ display: 'flex' }}>
                                <Button className="_btn_highlight __back" text="Назад" onClick={this.goBack} />
                            </div>
                            : null}
                        {this.state.currentItem ?
                            <RequestItem
                                id={this.staticId}
                                item={this.state.currentItem}
                                update={this.update} />
                            : null
                        }
                    </Tile>
                    : null}
            </div>
        )
    }
}

export default withRouter(Requests_Indications);
