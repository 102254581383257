import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Switcher from "../../components/switcher/switcher";

import {
    Outlet,
    Navigate
} from 'react-router-dom';
import Tile from "../../components/tile/tile";
import TileInfoBox from "../../components/tile/tileInfoBox";
import { getFormattedPrice, getZoneTypeByArrLength } from "../../helper";
import { connect } from "react-redux";
import { setAccountPaymentDetails } from "../../store/slices/app";
import API from "../../API";

class Billing_Balance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: true,
            account: ''
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }, () => {
            this.getPaymentDetails()
        }) }, 1)
    }
    componentDidUpdate() {
        if (this.state.account !== this.props.store.app.account.number) {
            setTimeout(() => { this.setState({ ...this.state, isLoading: true, account: this.props.store.app.account.number }) }, 1)
        } else {
            if (this.state.isLoading) {
                setTimeout(() => { this.setState({ ...this.state, isLoading: false }) }, 100)
            }
        }
    }
    getPaymentDetails = () => {
        API.get('/api/pay/paymentDetails/' + this.props.store.app.account.number)
            .then(res => {
                console.customLog("GET PAYMENT DETAILS", res)
                this.props.setAccountPaymentDetails(res.data)
            }).catch(err => console.customLog(err))

    }

    getTitle = () => {
        console.customLog(this);
        let title = 'Переплата';
        // let deb = -this.props.store.app.paymentDetails.amount
        let deb = -(this.props.store.app.account.balance.debt + this.props.store.app.account.balance.penalty - this.props.store.app.account.balance.accepted)

        if (deb == 0) {
            title = 'Баланс'
        }
        if (deb < 0) {
            title = "Долг"
        }
        return title;
    }

    render() {
        let isMeterIsset = this.props.store.app.account.meterNumber ? true : false
        // let deb = this.props.store.app.paymentDetails.amount
        let deb = -(this.props.store.app.account.balance.debt + this.props.store.app.account.balance.penalty - this.props.store.app.account.balance.accepted)
        return (
            <div className="tiles __small">

                <Tile
                    isLoading={this.state.isLoading}
                    header={{
                        title: "Баланс",
                        icon: <Icon src="tiles/icon__bankcard.svg" />,
                    }}
                    gapContent={true}
                >
                    <TileInfoBox
                        title={this.getTitle() + ":"}
                        description={getFormattedPrice(Math.abs(deb))}
                    />
                    <TileInfoBox
                        title={"В том числе Пени:"}
                        description={getFormattedPrice(this.props.store.app.account.balance.penalty)}
                    />
                    <TileInfoBox
                        title={"Госпошлина:"}
                        description={getFormattedPrice(this.props.store.app.account.balance.duty)}
                    />
                    <TileInfoBox
                        title={"Судебные издержки:"}
                        description={getFormattedPrice(this.props.store.app.account.balance.sud)}
                    />
                    <TileInfoBox
                        title={"Оплата за отключение/включение:"}
                        description={getFormattedPrice(this.props.store.app.account.balance.paymentDisconnection)}
                    />
                </Tile>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAccountPaymentDetails: setAccountPaymentDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing_Balance);