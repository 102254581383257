import React from "react";
import { connect } from "react-redux";
import Button from "../../components/button/button";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Tile from "../../components/tile/tile";
import TileEmptyBox from "../../components/tile/tileEmptyBox";

class PaymentDecline extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <Tile>
                <TileEmptyBox>

                    <Icon src={"icon__paymentpage_error.svg"} />
                    <div className="tile__emptyBox_title">Ошибка платежа</div>
                    <div className="tile__emptyBox_actions">
                        <Button text="Повторить" to={'/' + this.props.store.app.account.number +"/payment"} type="primary" />
                        <Button text="На главную" to={"/" + this.props.store.app.account.number} type="secondary" />
                    </div>

                    {/* <div className="paymentStatus">
                        <Icon src={"icon__paymentpage_error.svg"} />
                        <div className="paymentStatus__title">
                            Что-то пошло не так
                        </div>
                        <div className="paymentStatus__subtitle">
                            Повторите попытку позже
                        </div>
                        <div className="paymentStatus__action">
                        </div>
                        
                    </div> */}
                </TileEmptyBox>
            </Tile >
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDecline)

