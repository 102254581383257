import React from "react";
import { _SERVICE_DATA } from "../../App";
import Icon from "../../components/icon/icon";
import Notification from "../../components/notifications/notification";
import Tile from "../../components/tile/tile";
import TileContactBox from "../../components/tile/tileContactBox";

class Requests_Contacts extends React.Component {
    render() {
        return (
            <div className="tiles __small">
                <Tile
                    header={{
                        title: "Горячая линия",
                        // icon: <Icon src="icon__page_profile.svg" />,
                    }}
                >
                    <TileContactBox
                        workHours={_SERVICE_DATA.contacts.hotline.workHours}
                        schedule={_SERVICE_DATA.contacts.hotline.schedule}
                        phone={_SERVICE_DATA.contacts.hotline.phone}
                        phoneRaw={_SERVICE_DATA.contacts.hotline.phone_raw}
                    />
                </Tile>
                <Tile
                    header={{
                        title: "Техподдержка личного кабинета",
                        // icon: <Icon src="icon__page_profile.svg" />,
                    }}
                >
                    <TileContactBox
                        workHours={_SERVICE_DATA.contacts.support.workHours}
                        schedule={_SERVICE_DATA.contacts.support.schedule}
                        phone={_SERVICE_DATA.contacts.support.phone}
                        phoneRaw={_SERVICE_DATA.contacts.support.phone_raw}
                        email={_SERVICE_DATA.contacts.support.email}
                    />
                </Tile>
            </div>
        )
    }
}

export default Requests_Contacts;
