import React, { lazy } from "react";
import { getRawHTML } from "../../helper";
import Icon from "../icon/icon";
import './styles.scss';

class NotificationFeedItem extends React.Component {
    handleClick = () => {
        if (typeof this.props.action === 'function') {
            this.props.action();
        }
    }
    getIcon = () => {
        let icon;
        switch (this.props.type) {
            case 'success':
                icon = 'icon__notifeed_success.svg';
                break;
            case 'info':
                icon = 'icon__notifeed_info.svg';
                break;
            case 'alert':
                icon = 'icon__notifeed_info.svg';
                break;
            case 'error':
            case 'warning':
                icon = 'icon__notifeed_error.svg';
                break;
        }
        return <Icon src={icon} />
    }
    render() {
        let cl = " __" + this.props.type;
        cl += this.props.action ? " __act" : ""
        cl += this.props.show ? "" : " __hided"
        return (
            <div className={"notificationFeed__item" + cl} id={this.props.id}>
                <div className={"_inner"} onClick={this.handleClick}>
                    {this.getIcon()}

                    <div className="_col">
                        <div className="_title">
                            {this.props.title}
                        </div>
                        {this.props.description ?
                            <div className="_description" dangerouslySetInnerHTML={getRawHTML(this.props.description)}>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default NotificationFeedItem;
