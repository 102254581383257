import React from "react";
import { connect } from "react-redux";
import API from "../../API";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Modal from "../../components/modal/table";
import Tile from "../../components/tile/tile";
import TileInfoBox from "../../components/tile/tileInfoBox";
import { notify } from "../../helper";
import { setAppAccounts } from "../../store/slices/app";

class Profile_Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: this.props.store.app.account.number,
            isLoaded: false,
            checkDeleteNumber: '',
            isButtonLoading: false,
        }
        this.cbx = {
            paperBill: true,
            sendEmail: true,
        }
        this.modal = new React.createRef();
        this.modalBill = new React.createRef();
        this.staticId = 'profile_accounts_' + this.props.store.app.account.number
    }
    componentDidMount() {
        this.update()
    }
    componentDidUpdate() {

        if (this.props.store.app.account.number !== this.state.account) {
            this.staticId = 'tile_view_' + this.props.store.app.account.number
        }
    }
    update = () => {
        this.setState({ ...this.state, isLoaded: false }, () => {

            API.get('/api/profile/accounts').then(res => {
                this.props.setAppAccounts(res.data)
                this.setState({ ...this.state, isLoaded: true }, () => {
                    // setTimeout(() => {
                    //     this.checkBill()
                    // }, 1000)
                })
            }).catch(err => {
                console.customLog(err);
                this.setState({ ...this.state, isLoaded: true })
                notify({
                    title: 'Лицевые счета',
                    description: 'Ошибка при загрузке списка лицевых счетов',
                    type: 'warning'
                })
                // this.throwError();
            })
        })
    }
    check = (id) => {
        this.setState({ ...this.state, checkDeleteNumber: id }, () => {
            this.modal.current.show();
        })
    }
    checkBill = () => {
        this.props.store.app.accounts.map(el => {
            if (el.main) {
                if (el.paperBill) {
                    this.modalBill.current.show()
                }
            }
        })
    }
    declineModal = () => {
        this.modal.current.hide();
    }
    delete = () => {
        this.setState({ ...this.state, isButtonLoading: true }, () => {
            API.get('/api/profile/delete/' + this.state.checkDeleteNumber).then(res => {
                console.customLog(res)
                this.handleSuccess()
            }).catch(err => {
                console.customLog(err)
                this.handleError()
            })
        })
    } // 180013808
    handleSuccess = () => {
        this.modal.current.hide();
        this.setState({ ...this.state, isButtonLoading: false }, () => {
            this.update()
        })
        notify({
            title: 'Лицевые счета',
            description: 'Лицевой счёт удален',
            type: 'success'
        })
    }
    handleError = () => {
        this.setState({ ...this.state, isButtonLoading: false })
        notify({
            title: 'Лицевые счета',
            description: 'Ошибка при удалении лицевого счёта',
            type: 'warning'
        })
    }
    handleCbxSuccess = () => {
        notify({
            title: 'Лицевые счета',
            description: 'Данные изменены',
            type: 'success'
        })
        setTimeout(() => {
            this.update()
        }, 100)
    }
    handleCbxError = () => {
        notify({
            title: 'Лицевые счета',
            description: 'Нельзя отказаться от бумажного и электронного счетов одновременно',
            type: 'warning'
        })
        setTimeout(() => {
            this.update()
        }, 100)
    }
    handleCheckbox = (name = false, value = false) => {

        // "paperBill": true,
        // "sendEmail": false,
        if (name) {
            this.cbx[name] = value
            // debugger
            if (name === "paperBill" && value === false) {
                this.modalBill.current.show()
                return
            }
        }

        API.put('/api/profile/account/' + this.props.store.app.account.number, this.cbx).then(res => {
            console.customLog(res)
            if (res.status === 200) {
                this.handleCbxSuccess();
            } else {
                this.handleCbxError();
            }
        }).catch(err => {
            this.handleCbxError();
            console.customLog(err)
        })

    }
    changeBillSchema = () => {
        this.cbx = {
            paperBill: false,
            sendEmail: true,
        }
        this.handleCheckbox()
        this.modalBill.current.hide()
    }
    getAccountTiles = () => {
        let i = 0
        return this.props.store.app.accounts.map(el => {
            let id = this.staticId + '_' + i
            if (el.main) {
                this.cbx.paperBill = el.paperBill
                this.cbx.sendEmail = el.sendEmail
            }
            i++;
            return (
                <Tile
                    key={id}
                    isLoading={!this.state.isLoaded}
                    className={el.main ? " __primary" : " __secondary"}
                    header={{
                        title: "Лицевой счёт " + el.number,
                        icon: <Icon src="brand.svg" />,
                        note: <span style={{ color: '#157EFB' }}>{el.main ? "основной" : ''}</span>,
                        actions: el.main ? null : [
                            <Button
                                key={id + '_btn_0'}
                                type="icon"
                                className=" __trash"
                                icon={<Icon src={"icon__trash.svg"} />}
                                isIcon={true}
                                onClick={() => { this.check(el.number) }}
                            // to={'/history/use'}
                            />,
                        ]
                    }}
                    gapContent={true}
                >

                    <TileInfoBox
                        title={"Адрес:"}
                        description={el.address}
                    />
                    <Input
                        title={"Доставлять бумажный счёт"}
                        type={"checkbox"}
                        id={id + '_cbx_0'}
                        status={el.paperBill}
                        isDisabled={!el.main}
                        noBorder={true}
                        onChange={() => this.handleCheckbox('paperBill', !el.paperBill)}
                    />
                    <Input
                        title={"Доставлять счёт на E-mail"}
                        type={"checkbox"}
                        id={id + '_cbx_1'}
                        status={el.sendEmail}
                        isDisabled={!el.main}
                        // isDisabled={true}
                        noBorder={true}
                        onChange={() => this.handleCheckbox('sendEmail', !el.sendEmail)}
                    />
                </Tile>
            )
        })
    }
    render() {
        return (
            <>
                <div className="tiles __small __special_profileTiles">
                    {this.getAccountTiles()}
                </div>

                <Modal
                    ref={this.modalBill}
                    icon={<Icon src="icon__confirm_modal.svg" />}
                    title={<>Я отказываюсь от бумажного счёта.<br />Согласен(а) получать счета на e-mail</>}
                    actions={[
                        <Button
                            key={this.staticId + '_btn_1'}
                            onClick={() => { this.changeBillSchema() }}
                            type={"primary"}
                            text={"Продолжить"}
                        />,
                        <Button
                            key={this.staticId + '_btn_2'}
                            onClick={() => { this.modalBill.current.hide() }}
                            type={"secondary"}
                            text={"Отменить"}
                        />,
                    ]}
                />
                <Modal
                    ref={this.modal}
                    icon={<Icon src="icon__trash_red.svg" />}
                    title={`Удалить лицевой счёт ${this.state.checkDeleteNumber}?`}
                    actions={[
                        <Button
                            key={this.staticId + '_btn_3'}
                            onClick={() => { this.delete() }}
                            type={"primary"}
                            text={"Да, удалить"}
                            className={'__warning'}
                            isLoading={this.state.isButtonLoading}
                        />,
                        <Button
                            key={this.staticId + '_btn_4'}
                            onClick={() => { this.declineModal() }}
                            type={"secondary"}
                            text={"Отменить"}
                        />,
                    ]}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppAccounts: setAppAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile_Accounts);

