import React from "react";
import API from "../../API";
import Button from "../../components/button/button";
import Chat from "../../components/chat/chat";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Tile from "../../components/tile/tile";
import { notify } from "../../helper";
import { withRouter } from "../../withRouter";
import RequestForm from "./components/request/requestForm";

class Requests_Create extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false
        }
        console.customLog(this.props)
    }

    send = (data) => {
        this.setState({ ...this.state, isSending: true }, () => {

            API.post('/question/send/', data).then(res => {
                console.customLog(res)
                this.setState({ ...this.state, isSending: false }, () => {
                    notify({
                        title: 'Центр поддержки',
                        description: 'Обращение успешно создано',
                        type: 'success'
                    })
                    this.props.navigate('/requests')

                })
            }).catch((err) => {
                console.customLog(err)
                notify({
                    title: 'Центр поддержки',
                    description: 'Произошла ошибка при создании обращения',
                    type: 'error'
                })
            })
        })
    }
    render() {
        return (
            <div className="tiles __small">
                <Tile
                    header={{
                        title: "Новое обращение",
                        icon: <Icon src="icon__page_profile.svg" />,
                    }}
                    noPadding={false}
                >
                    <RequestForm isSending={this.state.isSending} onSend={this.send} />
                    {/* <Chat
                        messages={this.exampleMessages}
                        actions={{
                            add: <Button type="white" isIcon={true} isRound={true} icon={
                                <Icon src={"icon__chat_attachment.svg"} />
                            } />,
                            input: <Input type="text" placeholder={"Ваше сообщение"} />,
                            send: <Button type="primary" isIcon={true} isRound={true} icon={
                                <Icon src={"icon__chat_attachment.svg"} />
                            } />,
                        }}
                    /> */}
                </Tile>
            </div>
        )
    }
}

export default withRouter(Requests_Create);
