import React from "react";
import { getFormattedNumber, getMonthByNumber, getZone, getZoneElement } from "../../helper";
import Icon from "../icon/icon";
import Input from "../input/input";
import Zone from "../zone/zone";
import './styles.scss';

class Meter extends React.Component {
    constructor(props) {
        super(props)
        this.inputs = [...this.props.inputs];
        this.inputErrors = [...this.props.inputErrors];
    }
    componentDidUpdate = () => {
        this.inputs = [...this.props.inputs];
        this.inputErrors = [...this.props.inputErrors];
    }
    getPreLastMonthTitle = () => {
        if (typeof this.props.data.lastPeriod == 'undefined') {
            return null
        }
        let lastPeriod = this.props.data.lastPeriod.split('-');
        let period = this.props.data.period.split('-');
        let year = lastPeriod[0]
        let month = lastPeriod[1]
        month = getMonthByNumber(month)
        let ret = `${month} ${year}`

        if (year === period[0]) {
            ret = month
        }

        let law = true;
        this.props.data.zones.map(el => {
            if (!el.indication) {
                law = false
            }
        })

        return law ? <th className="__right">{ret}</th> : null
    }
    getLastMonthTitle = () => {
        if (typeof this.props.data.period == 'undefined') {
            return null
        }
        let period = this.props.data.period.split('-');
        let lastPeriod = this.props.data.lastPeriod.split('-');
        let year = period[0]
        let month = period[1]
        month = getMonthByNumber(month)

        let ret = `${month} ${year}`

        if (year === lastPeriod[0]) {
            ret = month
        }
        return ret
        // return "Март"
    }
    getZoneType = type => {
        return getZoneElement(type)
    }
    getMeter = () => {
        return (
            <div className="meter__box">
                <div className="meter__box_number">
                    {this.props.meter.number}
                </div>
                <div className="meter__box_name">
                    {this.props.meter.name}
                </div>
            </div>
        )
    }
    getZones = () => {
        console.customLog('STATE', this.props.inputs)
        if (typeof this.props.data.zones == 'undefined') {
            return null
        }
        let q = this.props.data.zones.length;
        let z = 0;

        return this.props.data.zones.map(el => {
            let id = z;
            z++;
            // console.customLog(this.props.inputs[id])
            return (
                <tr key={this.props.accountNumber + "_" + z}>
                    {/* <td>{this.getZoneType(el.name)}</td> */}
                    <td className="__zone"><Zone originalType={el.originalType} label={true} /></td>
                    {this.props.alt ? null :
                        <td className="__right">{getFormattedNumber(el.tariff)}</td>
                    }
                    {el.indication ? <td className="__right">{el.indication}</td> : <></>}
                    <td className="__right __input">
                        {<Input
                            type="number"
                            placeholder="0"
                            hardValue={this.props.inputs[id] !== undefined ? this.props.inputs[id] : ''}
                            isError={this.props.inputErrors[id]}
                            onChange={(e) => this.handleChange(e, id, el.indication ? el.indication : 0)}
                        />}
                    </td>
                </tr>
            )
        })
    }
    handleChange = (e, id, min) => {
        // debugger
        if (parseInt(e.target.value).toString() != e.target.value 
            // || e.target.value.length < min.length 
            // || parseInt(e.target.value) < min 
        ) {
            this.inputErrors[id] = true
        } else {
            this.inputErrors[id] = false
        }

        this.inputs[id] = e.target.value
        this.props.setInputs(this.inputs, this.inputErrors)
    }
    render() {
        let cl = "";
        cl += this.props.alt ? " __alt" : "";
        return (
            <div className={"meter" + cl}>
                <div className="meter__inner">
                    {this.props.meter ?
                        <div className="meter__col">
                            <table className="meter__table">
                                <thead>
                                    <tr>
                                        <th>Счётчик</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.getMeter()}
                                </tbody>
                            </table>
                        </div>
                        : null}
                    <div className="meter__col">
                        <table className="meter__table">
                            <thead>
                                <tr>
                                    <th className="__zone">Зона</th>
                                    {this.props.alt ? null :
                                        <th className="__right">Тариф,&nbsp;₽</th>
                                    }
                                    {this.getPreLastMonthTitle()}
                                    <th className="__left __input">{this.getLastMonthTitle()}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getZones()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Meter;
