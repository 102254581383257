import React, { lazy } from "react";
import './styles.scss';


class Tooltip extends React.Component {
    type = 'top'
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className={"tooltip __" + this.type + (this.props.isActive ? " __active" : "")} >
                <div className="tooltip__inner" >
                    {this.props.text}
                </div>
            </div>
        )
    }
}

export default Tooltip;
