import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Switcher from "../../components/switcher/switcher";

import {
    Outlet,
    Navigate
} from 'react-router-dom';
import Button from "../../components/button/button";
import { connect } from "react-redux";
import { setLogout } from '../../store/slices/auth'
import Modal from "../../components/modal/table";
import { withRouter } from "../../withRouter";
import { resetState } from "../../store/slices/app";
import { _SERVICE_DATA } from "../../App";

class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false
        }
        this.staticId = 'profileIndex_static';
    }
    handleLogout = () => {
        this.props.setLogout()
        this.props.navigate('/auth')
        setTimeout(() => {
            this.props.resetState()
        }, 1000)
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";

        if (this.props.location.pathname === '/profile') {
            return <Navigate to="/profile/personal" />
        }
        return (
            <div className={"page _profile" + cl}>
                <div className="container">
                    <div className="_inner">
                        <PageTitle
                            // icon={<Icon src="icon__page_profile.svg" />}
                            text={"Учётная запись"}
                            actions={[<Button
                                key={this.staticId + '_btn_0'}
                                onClick={this.handleLogout}
                                type={"secondary"}
                                text={"Выйти из учётной записи"}
                            />]}
                        />
                        <Switcher items={[
                            {
                                link: '/profile/personal',
                                text: 'Персональные данные',
                                actions: [],
                            },
                            {
                                link: '/profile/accounts',
                                text: 'Лицевые счета',
                                actions: this.props.store.app.accounts.length < _SERVICE_DATA.app.maxAccountCount && [<Button
                                    key={this.staticId + '_btn_1'}
                                    type={"primary"}
                                    text={"Добавить лицевой счёт"}
                                    icon={<Icon src={"Icon__account_add.svg"} />}
                                    to={'/profile/add'}
                                />],
                            },
                            {
                                link: '/profile/password',
                                text: 'Сменить пароль',
                                actions: [],
                            },
                        ]}
                        />

                        <Outlet />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setLogout: setLogout,
    resetState: resetState
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
