import axios from 'axios'
import { deleteCookie, getCookie, setCookie } from './helper';


// console.customLog(result.parsed)

console.customLog = console.log
window._APP_LOGS = [];
// debugger
if(process.env.REACT_APP_DEBUG_MODE === 'false'){
    console.customLog = function(){
        window._APP_LOGS.push(arguments)
    }
}

console.customLog(process.env, 'dotenv')

// export const _SITE_URL = 'https://svet.kaluga.ru/test2';
export const _SITE_URL = process.env.REACT_APP_WS_URL + process.env.REACT_APP_WS_URI;
export const _SITE = process.env.REACT_APP_WS_URL + process.env.REACT_APP_WS_URI + process.env.REACT_APP_WS_URI_SERVICE;
// const _SITE = 'https://svet.kaluga.ru/test2/service';
const options = {
    headers: {
        Authorization: ""
    }
};

const API = axios.create({
    baseURL: _SITE,
    // timeout: 2000,
    // headers: {

    // 'Access-Control-Allow-Origin': 'Authorization',
    //     "Access-Control-Allow-Credentials": "true"
    // }
    // mode: 'no-cors',
});

export const SELF_API = axios.create({
    baseURL: '/'
})
export const CALENDAR_API = axios.create({
    baseURL: process.env.REACT_APP_CALENDAR_URL
})
export const setAuthTokenByCookie =() => {
    let token = getCookie('token');
    setAuthToken(token)
}
export const deleteAuthToken = () => {
    deleteCookie('token');
}
export const checkAuthToken = () => {
    return getCookie('token') ? true : false;
}
export const setAuthToken = token => {
    // console.customLog('TOKEN: ' + token)
    // console.customLog(axios)
    setCookie('token', token, { 'max-age': process.env.REACT_APP_TOKEN_LIFESPAN });

    API.defaults.headers.common['Authorization'] = "Bearer " + token;
    console.customLog(API.defaults.headers.common)
    // console.customLog(axios.defaults.headers.common)
    // options.headers["Authorization"] = "Bearer " + token
}

export const collectJSONFromInput = items => {
    let json = [];

    items.map(el => {
        json.push(el.current.getInputData())
    })

    return json;
}

export const simplifyJSON = json => {
    let arr = {};
    json.map(el => {
        arr[el.name] = el.value
    })
    return arr;
}

export const _OPTIONS = options;

export default API;