import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import './styles.scss';

class BackButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isHidden: true,
            targetPath: '/'
        }
    }
    componentDidUpdate() {
        this.check()
    }
    componentDidMount() {
        this.check();
    }
    check() {
        if (this.props.path === '/' + this.props.store.app.account.number) {
            if (!this.state.isHidden) {
                this.setState({ ...this.state, isHidden: true })
            }
        } else {
            let targetPath = this.props.path.split("/")
            // targetPath = '/' + targetPath[targetPath.length - 1];
            targetPath = "/" + this.props.store.app.account.number
            if (this.state.isHidden) {
                this.setState({ ...this.state, isHidden: false })
            }
            if (this.state.targetPath !== targetPath) {
                this.setState({ ...this.state, targetPath })
            }
        }
    }
    render() {
        console.customLog('this.props.store.app.ui.backButton', this.props.store.app.ui)
        let curClass = this.props.store.app.ui.backButton ? this.state.isHidden ? " __hidden" : "" : " __hidden";
        return (
            <div className={"backButton" + curClass}>
                <div className="container">
                    <div className="_inner">
                        <NavLink to={this.state.targetPath} className="btn __back">
                            Назад
                        </NavLink>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(BackButton)
// export default BackButton;
