import React from "react";
import { NavLink } from "react-router-dom";
import './styles.scss';

class ChatItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: this.props.messages,
        }
    }
    getDate = () => {
        let date = this.props.datetime
        // date.setTime(this.props.date)
        console.customLog(date)
        return date.getHours() + ":" + date.getMinutes();
    }
    render() {
        let cl = "";
        cl += this.props.isForeign ? "" : " __own";
        return (
            <div className={"chatItem" + cl}>
                <div className={"chatItem__inner"}>
                    <div className={"chatItem__text"}>
                        {this.props.text}
                    </div>
                    <div className={"chatItem__date"}>
                        {this.getDate()}
                    </div>
                </div>
            </div>
        )
    }
}

export default ChatItem;
