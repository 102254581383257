import React from "react";
import { connect } from "react-redux";
import Button from "../../components/button/button";
import Chart from "../../components/chart/chart";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Table from "../../components/table/table";
import Tile from "../../components/tile/tile";
import TileEmptyBox from "../../components/tile/tileEmptyBox";
import { notify } from "../../helper";
import { setAppHistoryCalculations } from "../../store/slices/history";
import { getHistoryCalculations, getHistoryCalculationsChart, getHistoryCalculationsItems } from "./helper";

class History_Stat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isLoading: true,
            paymentHistory: {
                account: ''
            }
        }
    }

    componentDidMount() {
        this.state.paymentHistory.account = this.props.store.app.account.number
        this.getHistory()
    }
    componentDidUpdate() {
        if (this.props.store.app.account.number !== this.state.paymentHistory.account) {
            this.state.paymentHistory.account = this.props.store.app.account.number
            this.getHistory()
        }
    }
    getHistory = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            getHistoryCalculations(this.props.store.app.account.number, this.props.setAppHistoryCalculations, () => {
                this.setState({ ...this.state, isLoading: false })
            }, () => {
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'Статистика расчётов',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })
    }
    getHistoryItems = () => {
        let body = getHistoryCalculationsItems(this.props.store.history.calculations, true, this.props.store.history.limit);
        return body;
    }
    getChartData = () => {
        let data = getHistoryCalculationsChart(this.props.store.history.calculations, this.props.store.history.limit)
        return data;
    }
    getChart = () => {
        let obj = {}
        try {
            obj = <Chart type={"bar"} data={this.getChartData()} options={chartOptions} inTile={true} />
        } catch {
            obj = this.state.isLoading ? null : <TileEmptyBox title="Ваш браузер не поддерживает данный модуль" />
        }
        return obj
    }
    render() {
        let tableData = this.getHistoryItems();
        return (
            <div className="tiles __big">
                <Tile isLoading={this.state.isLoading}>
                    {tableData.length ?
                        <Table data={{
                            head: [
                                { name: 'Период', align: "left" },
                                { name: 'Тариф, ₽/кВт.ч', align: "left" },
                                { name: 'Потреблено, кВт.ч', align: "left" },
                                { name: 'Коррекция суммы, ₽', align: "right" },
                                { name: 'Начислено ЭЭ, ₽', align: "right" },
                                { name: 'Оплачено ЭЭ, ₽', align: "right" },
                            ],
                            body: tableData
                        }} />
                        :
                        this.state.isLoading ? null : <TileEmptyBox />
                    }
                </Tile>
                {tableData.length ?
                    <Tile isLoading={this.state.isLoading}>
                        <Table data={{
                            head: [
                                { name: 'Статистика расчётов за ЭЭ', align: "stretchCenter" },
                            ],
                            body: [
                                [
                                    {
                                        element: this.getChart()
                                    }
                                ]
                            ]
                        }} />
                    </Tile>
                    : null}
            </div >
        )
    }
}
var chartOptions = {
    responsive: true,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    scales: {
        y: {
            display: true,
            title: {
                display: true,
                text: 'Рублей'
            }
        },
    },
};

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppHistoryCalculations: setAppHistoryCalculations,
};

export default connect(mapStateToProps, mapDispatchToProps)(History_Stat);
