import React from "react";
import { connect } from "react-redux";
import API from "../../API";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Table from "../../components/table/table";
import Tile from "../../components/tile/tile";
import TileEmptyBox from "../../components/tile/tileEmptyBox";
import TileInfoBox from "../../components/tile/tileInfoBox";
import { getMonthByNumber, notify } from "../../helper";
import { setAppHistoryMeters } from "../../store/slices/history";
import { getHistoryMeters, getHistoryMetersItems } from "./helper";

class History_View extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isLoading: true,
            paymentHistory: {
                account: ''
            }
        }
    }
    componentDidMount() {
        this.state.paymentHistory.account = this.props.store.app.account.number
        this.getHistory()
    }
    componentDidUpdate() {
        if (this.props.store.app.account.number !== this.state.paymentHistory.account) {
            this.state.paymentHistory.account = this.props.store.app.account.number
            this.getHistory()
        }
    }
    getHistory = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            getHistoryMeters(this.props.store.app.account.number, this.props.setAppHistoryMeters, (res) => {
                console.customLog(res)
                this.setState({ ...this.state, isLoading: false })
            }, (err) => {
                console.customLog(err)
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'История показаний',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })
    }
    getHistoryItems = () => {
        let body = getHistoryMetersItems(this.props.store.history.meters, true, this.props.store.history.limit);
        return body;
    }
    render() {
        let tableData = this.getHistoryItems()
        return (
            <div className="tiles">
                <Tile isLoading={this.state.isLoading}>
                    {tableData.length ?
                        <Table data={{
                            head: [
                                { name: 'Период', align: "left" },
                                { name: 'Дата', align: "left" },
                                { name: 'Показание', align: "left" },
                                { name: 'Тип', align: "left" },
                                { name: 'Статус показаний', align: "center" },
                            ],
                            body: tableData
                        }} />
                        :
                        this.state.isLoading ? null : <TileEmptyBox />
                    }
                </Tile>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppHistoryMeters: setAppHistoryMeters,
};

export default connect(mapStateToProps, mapDispatchToProps)(History_View);
