import React from "react";
import Form from "../../../components/form/form";
import Tile from "../../../components/tile/tile";
import { ReactComponent as Brand } from "../../../assets/brand.svg"
import Button from "../../../components/button/button";
import { withRouter } from "../../../withRouter";

class Recover_Success extends React.Component {
    constructor(props) {
        super(props)
        this.staticId = 'recoverSuccess_static';
    }
    render() {
        return (
            <Tile className={"__facelifted"} >
                <Form
                    header={{
                        image: <Brand />,
                        title: "Восстановить пароль",
                        // back: true,
                    }}
                    footer={{
                        actionsAsCol: true,
                        actions: [
                            <Button type="mainPrimary" to="/auth" text="К авторизации" key={this.staticId + '_btn_0'} />
                        ]
                    }}
                >
                    <div className="form__success">
                        <div className="form__success_option">
                            <div className="_value">Пароль успешно изменен</div>
                        </div>
                    </div>
                </Form>
            </Tile>
        )
    }
}

export default withRouter(Recover_Success);
