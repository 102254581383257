import React from "react";
import { Link, NavLink } from "react-router-dom";
// import { ReactComponent as AppAsset } from '../../assets/ref__app_asset.png';
import AppAsset from '../../assets/ref__app_asset.png';
import './styles.scss';
import Icon from "../icon/icon";
import { _SERVICE_DATA } from "../../App";
import API from "../../API";

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            year: ''
        }
    }
    componentDidMount() {
        this.getYear()
    }
    getYear = () => {
        API.get('/api/service/time').then(res => {
            // this.initTime(res.data.currentTime)
            
            console.customLog('TIME', res.data.currentTime)
            let time = res.data.currentTime;
            let t = time.split('.')[0];
            let date = new Date(t)
            this.setState({...this.state, year: date.getFullYear()})
        }).catch(err => {
            // console.customLog("TIME", err)
        })
    }
    render() {
        let i = 0;
        return (
            <footer>
                <div className="container">
                    <div className="_inner">
                        <div className="_row">
                            <div className="_col __content">
                                <div className="_links">
                                    {/* <NavLink to="agree">
                                    Политика обработки персональных данных
                                </NavLink>
                                &nbsp;&nbsp;&nbsp;&nbsp;<a href="#">Руководство пользователя</a> */}
                                    {_SERVICE_DATA.footer.links.map(el => {
                                        i++;
                                        return (
                                            <a href={el.link} key={'footer_links_' + i} target="_blank">
                                                {el.text}
                                            </a>
                                        )
                                    })}
                                </div>
                                <p className="_copyright">{_SERVICE_DATA.footer.copyright}&#8209;{this.state.year}</p>
                            </div>

                            <div className="_col">
                                <NavLink to={"/app"} className="_app">
                                    <div className="_image">
                                        <img src={AppAsset} />
                                    </div>
                                    <div className="_title">
                                        {_SERVICE_DATA.footer.mobileApp.title}
                                    </div>
                                    <div className="_actions">
                                        <div className="_block">
                                        {/* <a href={_SERVICE_DATA.footer.mobileApp.googlePlay_link} target="_blank"> */}
                                            <Icon src={'ref__app_download_google.svg'} />
                                        {/* </a> */}
                                        </div>
                                        <div className="_block">
                                        {/* <a href={_SERVICE_DATA.footer.mobileApp.appStore_link} target="_blank"> */}
                                            <Icon src={'ref__app_download_apple.svg'} />
                                        {/* </a> */}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
