import React from "react";
import Icon from "../icon/icon";
import './styles.scss';

class Date extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="date">
                <Icon src={"icon__date_calendar.svg"} />
                <span className="date__text">
                    {this.props.date}
                </span>
            </div>
        )
    }
}

export default Date;
