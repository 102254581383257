import React from "react";
// import ReactInputMask from "react-input-mask";
import { getPhoneByMask } from "../../helper";
import Icon from "../icon/icon";
import './styles.scss';

class Input extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordChanged: false
        }
        this.inputRef = React.createRef();
    }
    componentDidUpdate() {
        if (this.props.hardValue !== undefined) {
            this.inputRef.current.value = this.props.hardValue
        }
    }
    onBlur = (e) => {
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(e)
        }
    }
    onFocus = (e) => {
        if (typeof this.props.onFocus === 'function') {
            this.props.onFocus(e)
        }
    }
    onChange = (e) => {
        if (this.props.type === 'number' && this.props.formatted) {
            let val = e.target.value
            if (val === '') {
                e.target.value = ''
                this.props.onChange(e)
            }
            if (val.match(/^\d{1,}(\,\d{0,2})?$/)) {
                e.target.value = val
                this.props.onChange(e)
            }
            return;
        }
        if (this.props.type === 'number') {
            let val = parseInt(e.target.value)
            if (!isNaN(val)) {
                e.target.value = val
            } else {
                e.target.value = ''
            }
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(e)
        }
    }
    getInputData = () => {
        return {
            name: this.props.name,
            value: this.inputRef.current.value
        }
    }
    getNumberInput = () => {
        return (
            <>
                {this.props.title ?
                    <label>{this.props.title}</label>
                    : null}
                <input
                    type="text"
                    // pattern="[1-9]"
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    value={this.props.hardValue}
                    ref={this.inputRef}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readonly ? 'readonly' : false}
                />
            </>
        )
    }
    getTextInput = () => {
        return (
            <>
                {this.props.title ?
                    <label>{this.props.title}</label>
                    : null}
                <input
                    type="text"
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    value={this.props.hardValue}
                    ref={this.inputRef}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readonly ? 'readonly' : false}
                />
            </>
        )
    }
    getEmailInput = () => {
        return (
            <>
                {this.props.title ?
                    <label>{this.props.title}</label>
                    : null}
                <input
                    type="email"
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    value={this.props.hardValue}
                    ref={this.inputRef}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readonly ? 'readonly' : false}
                />
            </>
        )
    }
    getPhoneInput = () => {
        return (
            <>
                {this.props.title ?
                    <label>{this.props.title}</label>
                    : null}
                <input
                    type="text"
                    value={this.props.hardValue}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    ref={this.inputRef}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readonly ? 'readonly' : false}
                />
            </>
        )
    }
    getCheckboxInput = () => {
        let forId = 'cbx_' + (this.props.id ? this.props.id : Math.random())
        return (
            <>
                <input type="checkbox"
                    id={forId}
                    // defaultChecked={this.props.status}
                    checked={this.props.status}
                    ref={this.inputRef}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readonly ? 'readonly' : false}
                />
                <label htmlFor={forId}>{this.props.title}</label>
            </>
        )
    }
    getPasswordInput = () => {
        return (
            <>
                {this.props.title ?
                    <label>{this.props.title}</label>
                    : null}
                <input
                    type={this.state.passwordChanged ? "text" : "password"}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    value={this.props.hardValue}
                    ref={this.inputRef}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    readOnly={this.props.readonly ? 'readonly' : false}
                />
                <div className={`input__action${this.state.passwordChanged ? " __active" : ""}`} onClick={this.passwordChangeType}>
                    <div className="input__action_on">
                        <Icon src={"icon__eye.svg"} />
                    </div>
                    <div className="input__action_off">
                        <Icon src={"icon__eye_off.svg"} />
                    </div>
                </div>
            </>
        )
    }
    getContentInput = () => {
        return (
            <>
                {this.props.title ?
                    <label>{this.props.title}</label>
                    : null}
                {this.props.value}
            </>
        )
    }
    getError = () => {

    }
    passwordChangeType = () => {
        this.setState({ ...this.state, passwordChanged: !this.state.passwordChanged })
    }
    render() {
        let cl = " __" + this.props.type
        cl += this.props.isDisabled ? " __disabled" : "";
        cl += this.props.noBorder ? " __noBorder" : "";
        cl += this.props.readonly ? " __readOnly" : "";
        cl += this.props.isError ? " __error" : "";
        cl += this.props.className ? " " + this.props.className : "";
        return (
            <div className={"input" + cl}>
                <div className="input_wrapper">
                    {this.props.type === "number" ? this.getNumberInput() : null}
                    {this.props.type === "text" ? this.getTextInput() : null}
                    {this.props.type === "phone" ? this.getPhoneInput() : null}
                    {this.props.type === "email" ? this.getEmailInput() : null}
                    {this.props.type === "checkbox" ? this.getCheckboxInput() : null}
                    {this.props.type === "password" ? this.getPasswordInput() : null}
                    {this.props.type === "content" ? this.getContentInput() : null}
                    {this.props.tip ?
                        <div className="input__tip">{this.props.tip}</div> : null
                    }
                </div>
                {this.getError()}
            </div>
        )
    }
}

export default Input;
