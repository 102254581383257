import React from "react";
import { connect } from "react-redux";
import Button from "../../components/button/button";
import Icon from "../../components/icon/icon";
import PageTitle from "../../components/pageTitle/pageTitle";
import Tile from "../../components/tile/tile";
import TileEmptyBox from "../../components/tile/tileEmptyBox";

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <Tile>
                <TileEmptyBox>
                    <Icon src={"icon__paymentpage_success.svg"} />
                    <div className="tile__emptyBox_title">Оплата проведена успешно!</div>
                    <div className="tile__emptyBox_actions">
                        <Button text="На главную" to={"/" + this.props.store.app.account.number} type="secondary" />
                    </div>

                    {/* <div className="paymentStatus">
                        <Icon src={"icon__paymentpage_success.svg"} />
                        <div className="paymentStatus__title">
                            Оплата проведена успешно!
                        </div>
                        <div className="paymentStatus__action">
                            <Button text="На главную" to="/" type="primary" />
                        </div>
                    </div> */}
                </TileEmptyBox>
            </Tile>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)

// export default ;
