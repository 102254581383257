import React from "react";
import { connect } from "react-redux";
import Button from "../../components/button/button";
import Form from "../../components/form/form";
import Icon from "../../components/icon/icon";
import Input from "../../components/input/input";
import Table from "../../components/table/table";
import Tile from "../../components/tile/tile";
import TileEmptyBox from "../../components/tile/tileEmptyBox";
import { notify } from "../../helper";
import { setAppHistoryPayments } from "../../store/slices/history";
import { getHistoryPayments, getHistoryPaymentsItems } from "./helper";

class History_Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isLoading: false,
            paymentHistory: {
                account: ''
            }
        }
    }
    componentDidMount() {
        this.state.paymentHistory.account = this.props.store.app.account.number
        this.getHistory()
    }
    componentDidUpdate(){
        if (this.props.store.app.account.number !== this.state.paymentHistory.account) {
            this.state.paymentHistory.account = this.props.store.app.account.number
            this.getHistory()
        }
    }
    handleClick = () => {

    }
    getHistory = () => {
        this.setState({ ...this.state, isLoading: true }, () => {
            getHistoryPayments(this.props.store.app.account.number, this.props.setAppHistoryPayments, () => {
                this.setState({ ...this.state, isLoading: false })
            }, () => {
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'История платежей',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })


    }
    getHistoryItems = () => {
        let body = getHistoryPaymentsItems(this.props.store.history.payments, true, this.props.store.history.limit);
        return body;
    }
    render() {
        let tableData = this.getHistoryItems()
        return (
            <div className="tiles">
                <Tile isLoading={this.state.isLoading}>
                    {tableData.length ?
                        <Table data={{
                            head: [
                                { name: 'Период', align: "left" },
                                { name: 'Дата платежа', align: "left" },
                                { name: 'Сумма, ₽', align: "right" },
                                { name: 'Банк', align: "left" },
                                { name: 'Тип платежа', align: "left" },
                                { name: 'Статус платежа', align: "left" },
                            ],
                            body: tableData
                        }} /> : 
                        this.state.isLoading ? null : <TileEmptyBox/>
                    }
                </Tile>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppHistoryPayments: setAppHistoryPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(History_Payment);