import React from 'react';
import { ReactSVG } from 'react-svg'
import './styles.scss';

class Icon extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactSVG className={"ico " + (this.props.className ? this.props.className : "")} src={require("../../assets/" + this.props.src)} />
        )
    }
}
export default Icon;
