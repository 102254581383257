import Date from "./components/date/date";
import Icon from "./components/icon/icon";
import Status from "./components/status/status";
import { addToNotificationFeed, setNotificationFeedItemStatus, setRequestIndicator } from "./store/slices/app";
import store from "./store/store";

const _ZONES = [
    {
        originalType: 'основной',
        type: 'main',
        name: 'Основной тариф',
        color: '#00CCFE',
        icon: 'icon__meter_peak_blue.svg',
    },
    {
        originalType: 'день',
        type: 'day',
        name: 'День',
        color: '#FFCF5C',
        icon: 'icon__meter_day.svg',
    },
    {
        originalType: 'ночь',
        type: 'night',
        name: 'Ночь',
        color: '#2D81FF',
        icon: 'icon__meter_night.svg',
    },
    {
        originalType: 'пик',
        type: 'peak',
        name: 'Пик',
        color: '#fb1515',
        icon: 'icon__meter_peak_red.svg',
    },
    {
        originalType: 'полупик',
        type: 'half_peak',
        name: 'Полупик',
        color: '#2FD34A',
        icon: 'icon__meter_peak_green.svg',
    },
];

function getIconByName(name){
    return <Icon src={name} />
}

const _ZONE_TYPE_GROUP = {
    0: ['main'],
    1: ['day', 'night'],
    2: ['peak', 'half_peak', 'night'],
}

function getZoneIcon(type) {
    return _ZONES.filter(el => el.type === type)[0].icon;
}
function getZone(originalType) {
    return _ZONES.filter(el => el.originalType === originalType)[0];
}
function getZoneByType(type) {
    return _ZONES.filter(el => el.type === type)[0];
}
function getZoneElement(type, small = false, value = false) {
    let zone = getZone(type);

    return (
        <div className="zoneType">
            {zone.icon}
            {!small ?
                <span className="zoneType__name">
                    {zone.name}
                </span>
                : null}
            {value ?
                <span className="zoneType__value">
                    {value}
                </span>
                : null}
        </div>
    )
}

const _ZONES_TYPES = ['Однозонный', 'Двухзонный', 'Трёхзонный']

function getZoneTypeByArrLength(length) {
    return _ZONES_TYPES[length - 1];
}

function sortZonesByDefault(zones) {
    let _zones = [];
    // debugger
    _ZONE_TYPE_GROUP[zones.length - 1].map(zone => {
        zones.map(el => {
            let original = getZone(el.name)
            let obj = Object.assign({}, el, original)
            if (zone === original.type) {
                _zones.push(obj)
            }
        })
    })
    // debugger
    return _zones;
}

const _PAYMENT_STATUS = {
    0: { type: 'error', text: 'Ошибка платежа' },
    1: { type: 'wait', text: 'В обработке' },
    2: { type: 'accept', text: 'Оплаченный' },
    3: { type: 'success', text: 'Зачисленный' },
}

function getPaymentStatus(status) {
    let type = 0
    switch (status) {
        case 'processing':
            type = 1;
            break;
        case 0:
            type = 3;
            break;
        case 'accepted':
            type = 2;
            break;
        default:
            type = 0;
            break;
    }
    // debugger
    // return _PAYMENT_STATUS[type];
    let _type = _PAYMENT_STATUS[type]['type'];
    let _text = _PAYMENT_STATUS[type]['text']

    return <Status type={_type} needIcon={true}>{_text}</Status>;

}

function getPaymentType(type) {
    // return _PAYMENT_TYPE[type - 1];
    switch (type) {
        case 1:
            return 'Электроэнергия';
        case 3:
            return 'Пени';
        case 4:
            return 'Отключение/включение';
        case 5:
            return 'Госпошлина';
        case 7:
            return 'Пени по суду';
        case 8:
            return 'Возмещение судебных издержек';
        default:
            return '';
    }
}

const _VIEW_STATUS = {
    0: { type: 'error', text: 'Ошибка' },
    // 1: { payment_type: 'wait', text: 'В обработке' },
    1: { type: 'wait', text: 'Получены' },
    2: { type: 'success', text: 'Приняты к расчету' },
}

function getViewStatus(status) {
    let type = 0
    switch (status) {
        case 0:
            type = 1;
            break;
        case 1:
            type = 2;
            break;
        default:
            type = 0;
            break;
    }
    // debugger
    let _type = _VIEW_STATUS[type]['type'];
    let _text = _VIEW_STATUS[type]['text']

    return <Status type={_type} needIcon={true}>{_text}</Status>;
}

const _MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

function getMonthByNumber(number) {
    return _MONTHS[number - 1];
}

function notify({ title, description, type, action }) {
    let id = Math.random();
    let notif = { title, description, type, action }
    store.dispatch(addToNotificationFeed({
        id: id,
        ...notif
    }))
    setTimeout(() => {
        store.dispatch(setNotificationFeedItemStatus({
            id: id,
            show: true
        }))
        setTimeout(() => {
            store.dispatch(setNotificationFeedItemStatus({
                id: id,
                show: false
            }))
        }, 5000)
    }, 10)
}

function getFormattedDate(date, needElement = false, needTime = false) {
    let formattedDate = [
        (date.getDate()).toString().padStart(2, 0),
        (date.getMonth() + 1).toString().padStart(2, 0),
        date.getFullYear()
    ]
    let elDate = [formattedDate.join('.')]
    if (needTime) {
        let formattedTime = [
            date.getHours().toString().padStart(2, 0),
            date.getMinutes().toString().padStart(2, 0)
        ]
        elDate.push(formattedTime.join(':'))
    }
    if (needElement) {
        return <Date date={elDate.join(' в ')} />
    }

    return elDate.join(' в ')
}

function getFormattedPeriod(period, isInverted = false) {
    period = period.split('-');

    let periodMonth = ''
    let periodYear = ''

    if (isInverted) {
        periodMonth = getMonthByNumber(period[1])
        periodYear = period[0];
    } else {
        periodMonth = getMonthByNumber(period[0])
        periodYear = period[1];
    }
    return periodMonth + ' ' + periodYear;
}

function setCookie(name, value, options = {}) {
    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

function getNormalError(serverError) {
    let trueError = "Неизвестная ошибка"
    console.customLog({ serverError })
    switch (serverError) {
        case "account id is invalid":
            trueError = 'Неверный номер лицевого счёта'
            break;
        case " account or code is invalid. or both":
            trueError = 'Неверный код'
            break;
        case "account is activated already":
            trueError = 'Лицевой счёт уже зарегистрирован'
            break;
        case "account is not confirms to 9-digits format":
            trueError = 'Лицевой счёт должен быть 9-ти значным'
            break;
        case "activation message not delivered. Check eMail, please":
            trueError = 'E-mail некорректен'
            break;
        case "An errror has occurred.":
            trueError = 'Возникла ошибка'
            break;
        case "code is invalid":
            trueError = 'Неверный код'
            break;
        case "login/password pair is inconsistent":
            trueError = 'Неверный логин и/или пароль'
            break;
    }
    return trueError;
}

function getFormattedNumber(number) {
    if (!number && number !== 0) {
        return ''
    }
    let n = number.toLocaleString(
        'ru-RU',
        { minimumFractionDigits: 2 })
    return n;
}

function getFormattedNumberPrev(number) {
    return getFormattedNumber(parseFloat(getRawNumber(number)));
}

function getFormattedPrice(number) {
    let n = number.toLocaleString(
        'ru-RU',
        {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'RUB'
        })
    return n;
}

function getRawNumber(number) {
    // console.customLog(number)
    if (!number) {
        return ''
    }
    let n = parseFloat(number.toString().replaceAll(' ', '').replace(',', '.'))
    return n
}

function newFile(data, fileName, type) {
    // base64 string
    var blob = new Blob([data], { type: type });
    var url = window.URL || window.webkitURL;
    let link = url.createObjectURL(blob);
    var a = document.createElement("a");
    debugger
    a.setAttribute("download", fileName);
    a.setAttribute("href", link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

function setLocalRequests(data) {
    let currentData = getFromLocalStorage();
    let isUpdated = false;
    let mutatedData = [];
    if (currentData) {
        if (data.length === currentData.length) {
            let i = 0;
            data.map(dialog => {
                if (dialog.answers.length !== currentData[i].answers.length) {
                    isUpdated = true;
                }
                i++;
            })
        } else {
            isUpdated = true
        }
    }

    console.customLog(data)
    // debugger
    if (isUpdated || !currentData) {
        // debugger
        // data.map(el => {
        //     let dialog = { ...el };
        //     let answers = []
        //     dialog.answers.map(_el => {
        //         let answer = {}
        //         answer.date = _el.date
        //         answer.unseen = _el.unseen
        //         answers.push(answer)
        //     })
        //     dialog.answers = answers
        //     mutatedData.push(dialog)
        // })
        // debugger
        mutatedData = [...data];
        writeToLocalStorage(mutatedData)
    }
}
function getMutatedRequests(data = false) {
    let currentData = getFromLocalStorage();
    let _data = false
    if (data) {
        let _data = [...data];
        if (currentData) {
            _data.map(dialog => {
                currentData.map(curDialog => {
                    if (dialog.id === curDialog.id) {
                        dialog.answers.map(answer => {
                            curDialog.answers.map(curAnswer => {
                                if (answer.date === curAnswer.date) {
                                    answer.unseen = curAnswer.unseen
                                }
                            })
                        })
                    }
                })
            })
        }
        return _data;
    } else {
        return currentData;
    }
}
function updateLocalRequests(id) {
    let data = getFromLocalStorage()
    data.map(dialog => {
        if (dialog.id === id) {
            dialog.answers.map(answer => answer.unseen = 0)
        }
    })
    writeToLocalStorage(data)
}
function checkLocalRequests(count) {
    let data = getFromLocalStorage()

    if (count) {
        store.dispatch(setRequestIndicator(true))
    } else {
        if (data) {
            let law = false
            data.map(dialog => {
                dialog.answers.map(answer => {
                    if (answer.unseen) {
                        law = true
                    }
                })
            })
            if (law) {
                store.dispatch(setRequestIndicator(true))
            } else {
                store.dispatch(setRequestIndicator(false))
            }
        } else {
            store.dispatch(setRequestIndicator(false))
        }
    }
}
function writeToLocalStorage(object) {
    localStorage.requests = JSON.stringify(object);
}
function getFromLocalStorage() {
    if (typeof localStorage.requests === 'string') {
        return JSON.parse(localStorage.requests);
    } else {
        return undefined
    }
}

function getPhoneRaw(val) {
    let _val = val
        .replaceAll('+7', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
        .replaceAll('_', '')
    // debugger
    console.customLog(_val)
    return _val
}

function getPhoneByMask(val) {
    // debugger
    console.log('val', val)
    if(!val){
        return '+7('
    }
    let _val = `+7(${val.substring(0, 3)})${val.substring(3, 6)}-${val.substring(6, 8)}-${val.substring(8, 10)}`
    // console.customLog(val, _val);
    if (_val.endsWith(')--')){
        _val = _val.substring(0, _val.length - 3)
    }
    if (_val.endsWith('--')){
        // console.customLog('1112232132132132');
        _val = _val.substring(0, _val.length - 2)
    }
    if (_val.endsWith('-')){
        _val = _val.substring(0, _val.length - 1)
    }
    // console.customLog(val, _val, _val.endsWith('-'))
    return _val
}

function getRawHTML(text){
    return {__html: text}
}

export {
    _ZONES,
    _ZONES_TYPES,
    _ZONE_TYPE_GROUP,
    getIconByName,
    setCookie,
    getCookie,
    deleteCookie,
    newFile,
    notify,
    getPhoneRaw,
    getPhoneByMask,
    getMonthByNumber,
    getPaymentStatus,
    getPaymentType,
    getViewStatus,
    getFormattedDate,
    getFormattedPeriod,
    getZone,
    getZoneIcon,
    getZoneByType,
    getZoneElement,
    getZoneTypeByArrLength,
    sortZonesByDefault,
    getNormalError,
    getFormattedNumber,
    getFormattedPrice,
    getRawNumber,
    getFormattedNumberPrev,
    writeToLocalStorage,
    getFromLocalStorage,
    setLocalRequests,
    updateLocalRequests,
    checkLocalRequests,
    getMutatedRequests,
    getRawHTML
};