import React from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/input/input";
import Tile from "../../../components/tile/tile";
import { ReactComponent as Brand } from "../../../assets/brand.svg"
import Button from "../../../components/button/button";
import Notification from "../../../components/notifications/notification";
import { connect } from "react-redux";
import { setLogged } from '../../../store/slices/auth'
import { setAppAccounts } from "../../../store/slices/app";
import API, { collectJSONFromInput, setAuthToken, simplifyJSON, _OPTIONS } from "../../../API"
import store from "../../../store/store";
import TileContactBox from "../../../components/tile/tileContactBox";
import { _SERVICE_DATA } from "../../../App";

class Support extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            notification: {
                show: true,
                type: 'success',
                description: 'Работает'
            }
        }
    }
    check = () => {

    }
    render() {
        return (
            <Tile className={"__facelifted"} >
                <Form
                    onSubmit={this.handleSubmit}
                    header={{
                        image: <Brand />,
                        title: "Техподдержка личного кабинета",
                        back: true,
                    }}
                >
                    <TileContactBox
                        vertical={true}
                        workHours={_SERVICE_DATA.contacts.support.workHours}
                        schedule={_SERVICE_DATA.contacts.support.schedule}
                        phone={_SERVICE_DATA.contacts.support.phone}
                        phoneRaw={_SERVICE_DATA.contacts.support.phone_raw}
                        email={_SERVICE_DATA.contacts.support.email}
                    />
                </Form>
            </Tile>
        )
    }
}


const mapStateToProps = (state) => ({
    state
});

const mapDispatchToProps = { setLogged, setAppAccounts };

export default connect(mapStateToProps, mapDispatchToProps)(Support);
