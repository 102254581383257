import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../icon/icon";
import './styles.scss';

class Button extends React.Component {
    constructor(props) {
        super(props)
    }
    handleClick = () => {
        if (!this.props.isLoading && !this.props.isDisabled) {
            if (typeof this.props.onClick === 'function') {
                this.props.onClick()
            }
        }
    }
    getChild = () => {

        return (
            <>
                {this.props.icon}
                {!this.props.isIcon ?
                    <div className="_text" > {this.props.text}</div>
                    : null}
                {
                    this.props.isLoading !== undefined ?
                        <div className="btn__load"></div>
                        : null
                }
            </>
        )
    }
    render() {
        let curClass = this.props.type !== undefined ? " __" + this.props.type : ""
        curClass += this.props.isLoading ? " __loading" : "";
        curClass += this.props.isDisabled ? " __disabled" : "";
        curClass += this.props.isIcon ? " __asIcon" : "";
        curClass += this.props.isRound ? " __rounded" : "";
        curClass += this.props.iconContent ? " __iconContent" : "";

        if (this.props.to) {
            return (
                <NavLink to={this.props.to} replace={this.props.replace ? true : false} className={"btn" + curClass + " " + this.props.className}>
                    {this.props.icon}
                    <div className="_text">{this.props.text}</div>
                </NavLink>
            )
        }
        if (this.props.href) {
            return (
                <a href={this.props.href} target={this.props.target} className={"btn" + curClass + " " + this.props.className}>
                    {this.props.icon}
                    <div className="_text">{this.props.text}</div>
                </a>
            )
        }

        if (this.props.prop === 'button') {
            return (
                <button className={"btn" + curClass + " " + this.props.className} onClick={this.handleClick}>
                    {this.getChild()}
                </button>
            )
        }

        return (
            <div className={"btn" + curClass + " " + this.props.className} onClick={this.handleClick}>
                {this.getChild()}
            </div>
        )
    }
}

export default Button;
