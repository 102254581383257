import React from "react";
import Icon from "../../components/icon/icon";
import Tile from "../../components/tile/tile";
import { connect } from "react-redux";
import Table from "../../components/table/table";
import Button from "../../components/button/button";
import { getMonthByNumber, getPaymentStatus, getPaymentType, notify } from "../../helper";
import { setAppHistoryPayments } from "../../store/slices/history";
import { getHistoryPayments, getHistoryPaymentsItems } from "../history/helper";
import TileEmptyBox from "../../components/tile/tileEmptyBox";

class HomeHistoryTile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            isLoading: true,
            paymentHistory: {
                account: ''
            }
        }
        this.staticId = 'tile_history_' + this.props.store.app.account.number
    }
    componentDidUpdate() {

        if (this.props.store.app.account.number !== this.state.paymentHistory.account) {
            this.state.paymentHistory.account = this.props.store.app.account.number
            this.getHistory()
        }
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)
    }
    getHistory = () => {

        this.setState({ ...this.state, isLoading: true }, () => {
            getHistoryPayments(this.props.store.app.account.number, this.props.setAppHistoryPayments, () => {
                this.setState({ ...this.state, isLoading: false })
            }, () => {
                this.setState({ ...this.state, isLoading: false })
                notify({
                    title: 'История платежей',
                    description: 'Ошибка при загрузке данных',
                    type: 'warning'
                })
            })
        })
    }
    getHistoryItems = () => {
        // console.customLog("HISTORY", this.props.store.history.payments)
        let body = getHistoryPaymentsItems(this.props.store.history.payments, false);
        return body;
    }
    render() {
        return (
            <Tile
                className={"__history"}
                isLoading={this.state.isLoading}
                header={{
                    icon: <Icon src={"tiles/icon__history.svg"} />,
                    title: 'История платежей',

                    actions: [
                        <Button
                            key={this.staticId + "_btn_0"}
                            text={"Подробнее"}
                            type={"secondary"}
                            to={'/' + this.props.store.app.account.number + '/history'}
                        />,
                    ]
                }}
            >
                {this.props.store.history.payments.length ? <Table
                        id={'tile_history_' + this.props.store.app.account.number}
                    data={{
                        head: [
                            { name: 'Период', align: "left" },
                            { name: 'Дата платежа', align: "left" },
                            { name: 'Сумма, ₽', align: "right" },
                            { name: 'Тип платежа', align: "left" },
                            { name: 'Статус платежа', align: "left" },
                        ],
                        body: this.getHistoryItems(false)
                    }} /> :
                    this.state.isLoading ? null : <TileEmptyBox />}
            </Tile>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {
    setAppHistoryPayments: setAppHistoryPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHistoryTile);
