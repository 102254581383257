import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Button from "../button/button";
import Icon from "../icon/icon";
import './styles.scss';
import { setAccountSelect } from "../../store/slices/app";
import { withRouter } from "../../withRouter";
import { _SERVICE_DATA } from "../../App";


class BillHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isHidden: true,
            targetPath: '/',
            disabledPrev: false,
            disabledNext: false,
            // targetPath: '/'
        }
        // console.customLog(this)
        this.scrollerRef = React.createRef();
        this.scrollerRef__inner = React.createRef();
        this.headerRef = React.createRef();
        this.isScrolling = false
        this.itemWidth = window.innerWidth > 496 ? 300 : 200
        // this.bills = props.bills
        // this.bills = 
        setTimeout(() => {
            // this.handleClick(this.getSelectedItem().id);
            // console.customLog(this.getSelectedItem())
        }, 200)
    }
    componentDidMount() {
        this.headerRef.current.addEventListener('mouseleave', this.mouseLeave)
        this.checkHidden();

        // if(this.props.location.path.replace('/', '') !== this.props.store.app.account.number){

        // }
    }
    componentWillUnmount() {
        this.headerRef.current.removeEventListener('mouseleave', this.mouseLeave)
    }
    mouseLeave = () => {
        // console.customLog('MOUSE LEAVE',this.getSelectedItem())
        this.handleClick(this.getSelectedItem());
    }
    componentDidUpdate() {
        // this.handleClick(this.getSelectedItem().id);
        this.checkHidden();
        this.mouseLeave();
    }
    checkHidden = () => {
        // console.customLog(this.props)
        if (this.props.path.includes('/profile') || this.props.path.includes('/requests')) {
            if (!this.state.isHidden) {
                this.setState({ ...this.state, isHidden: true })
            }
        } else {
            if (this.state.isHidden) {
                this.setState({ ...this.state, isHidden: false })
            }
        }
    }
    getItems = () => {
        let i = 0
        return this.props.bills.map(el => {
            let q = i;
            i++;
            // let cl = i === 1 ? ' __active' : '';
            let cl = el.isSelected === true ? ' __active' : '';
            let adress = el.address.split(', ');
            adress.shift()
            adress = adress.join(', ')
            return (
                <div className={"billHeader__item" + cl} key={el.id} onClick={() => { this.handleClick(q, el.number) }}>
                    <div className="billHeader__item_image">
                        <Icon src="icon__billHeader_item.svg" />
                    </div>
                    <div className="billHeader__item_box">
                        <div className="billHeader__item_number">
                            {el.number}
                        </div>
                        <div className="billHeader__item_address">
                            {adress}
                        </div>
                    </div>
                    {el.main ? <div className="billHeader__item_main">основной</div> : null}
                </div>
            )
        })
    }
    getSelectedItem = () => {
        let q = 0;
        let z = 0;
        this.props.bills.map(el => {
            // z = 0;
            if (el.isSelected) {
                z = q;
            }
            q++;
        })
        return z;
    }
    handleClick = (i, number = false) => {
        let targetScroll = 0
        if (i) {
            targetScroll = i * (this.itemWidth - 48) + 48;
        }

        if (number) {
            this.props.setAccountSelect(number)

            let path = this.props.path
            path = path.split('/');
            // console.customLog(path)
            path[1] = number;
            console.customLog(path.join('/'))
            // debugger
            this.props.navigate(path.join('/'))
            setTimeout(() => {
            }, 400)

        }

        let from = this.scrollerRef.current.scrollLeft
        let to = targetScroll;

        this.animateScroll(from, to)
    }
    scrollPrev = () => {
        let from = this.scrollerRef.current.scrollLeft
        let to = from - this.itemWidth;
        this.animateScroll(from, to)
    }
    scrollNext = () => {
        let from = this.scrollerRef.current.scrollLeft
        let to = from + this.itemWidth;
        this.animateScroll(from, to)
    }
    animateScroll = (from, to) => {
        if (this.isScrolling) {
            return
        }
        this.isScrolling = true
        let time = 400
        setTimeout(() => { this.isScrolling = false }, time)
        animate({
            duration: time,
            timing: easeInOut,
            draw: (progress) => {
                let result = (to - from) * progress + from;
                this.scrollerRef.current.scrollLeft = result
            }
        });
    }
    render() {
        let isAddButton = this.props.store.app.accounts.length < _SERVICE_DATA.app.maxAccountCount
        let cl = this.props.store.app.ui.billHeader ? this.state.isHidden ? " __hidden" : "" : " __hidden";
        let boxCl = "";
        if (this.scrollerRef.current) {
            console.customLog('this.scrollerRef.current', this.scrollerRef.current.clientWidth)
            boxCl = this.scrollerRef.current.clientWidth < this.scrollerRef__inner.current.clientWidth ? " __needArrows" : "";
        }
        if(!isAddButton){
            boxCl += " __withoutAdd"
        }
        return (
            <div className={"billHeader" + cl} ref={this.headerRef}>
                <div className="container">
                    <div className="_inner">
                        <div className="billHeader__title">
                            Лицевые счета
                        </div>
                        <div className={"billHeader__box" + boxCl}>
                            <div className="billHeader__scroll_btn_area __prev">
                                <Button
                                    className={"billHeader__btn billHeader__scroll_btn __prev"}
                                    type="primary"
                                    isIcon={true}
                                    icon={<Icon src="icon__billHeader_scroll_btn.svg" />}
                                    onClick={this.scrollPrev}
                                />
                            </div>
                            <div className="billHeader__scroller" ref={this.scrollerRef}>
                                <div className="billHeader__list" ref={this.scrollerRef__inner}>
                                    {this.getItems()}
                                </div>
                            </div>
                            <div className="billHeader__scroll_btn_area __next">
                                <Button
                                    className="billHeader__btn billHeader__scroll_btn __next"
                                    type="primary"
                                    isIcon={true}
                                    icon={<Icon src="icon__billHeader_scroll_btn.svg" />}
                                    onClick={this.scrollNext}
                                />
                            </div>
                        </div>
                        {isAddButton &&
                            <div className="billHeader__actions">
                                <Button className="billHeader__btn" type="primary" text="+" to={'/profile/add'} />
                            </div>
                        }
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = { setAccountSelect };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillHeader));

export function easeInOut(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function animate(options) {

    var start = performance.now();

    requestAnimationFrame(function animate(time) {
        // timeFraction от 0 до 1
        var timeFraction = (time - start) / options.duration;
        if (timeFraction > 1) timeFraction = 1;

        // текущее состояние анимации
        var progress = options.timing(timeFraction)

        options.draw(progress);

        if (timeFraction < 1) {
            requestAnimationFrame(animate);
        }

    });
}

