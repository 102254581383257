import React from "react";
import API from "../../../../API";
import Button from "../../../../components/button/button";
import Icon from "../../../../components/icon/icon";
import { getFormattedDate, notify } from "../../../../helper";
import './styles.scss';

class RequestForm extends React.Component {
    constructor(props) {
        super(props)
        this.fileRef = React.createRef()
        this.state = {
            file: false,
            text: '',
            // isBtnDisabled: true
        }
    }

    componentDidMount() {
        this.check();
    }

    input = (e) => {
        let val = e.target.value;
        this.setState({ ...this.state, text: val }, this.check)
    }

    fileDelete = () => {
        this.setState({ ...this.state, file: false }, this.check)
        this.fileRef.current.value = ''
    }
    upload = () => {
        console.customLog(this.fileRef);
        let file = this.fileRef.current.files[0]

        // console.customLog(file)
        this.check()

        const getBase64 = (file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                // console.customLog(reader.result.toString());
                let _content = reader.result.toString().split('base64,')[1]
                // debugger
                // console.customLog(_content)
                this.setState({
                    ...this.state, file: {
                        filename: file.name,
                        content: _content,
                        contentType: file.type,
                    }
                })
            };
            reader.onerror = function (error) {
                notify({
                    title: "Файл",
                    description: "Произошла ошибка при загрузке файла",
                    type: "error"
                })
            };
        }

        getBase64(file)
    }
    clickUpload = () => {
        this.fileRef.current.click()
    }

    send = () => {
        console.customLog(this.state.file);
        this.props.onSend({ files: [this.state.file], text: this.state.text })
    }

    check = () => {
        let file = this.fileRef.current.files[0]
        if (this.state.text.length < 10 || this.state.text.length > 1000) {
            this.setState({ ...this.state, isBtnDisabled: true })
            // notify({
            //     title: "Новое обращение",
            //     description: "Некорректный текст обращения",
            //     type: 'warning'
            // })
        } else {
            this.setState({ ...this.state, isBtnDisabled: false })
            if (file) {
                if (file.size > 2097152) {
                    this.setState({ ...this.state, isBtnDisabled: true })
                    notify({
                        title: "Новое обращение",
                        description: "Некорректный размер файла",
                        type: 'warning'
                    })
                } else {
                    this.setState({ ...this.state, isBtnDisabled: false })
                }
            }
        }
    }

    render() {
        console.customLog(this.state)
        return (
            <div className="requestForm">
                <div className="requestForm__input">
                    <textarea onInput={this.input} rows={4} placeholder="Напишите сообщение (не менее 10 не более 1 000 символов)"></textarea>
                </div>
                <div className="requestForm__footer">
                    <div className="requestForm__files">
                        <input type="file" ref={this.fileRef} onChange={this.upload} accept={".jpg,.jpeg,.png,.tiff,.doc,.docx,.xls,.xlsx,.pdf"} />
                        {this.state.file ?
                            <div className="_file">
                                <div className="_file_delete" onClick={this.fileDelete}>
                                    <Icon src={"icon__file_delete.svg"} />
                                </div>
                                <div className="_file_title">
                                    {this.state.file.filename}
                                </div>
                            </div>
                            :
                            <Button
                                icon={<Icon src={"icon__btn_upload.svg"} />}
                                type={"secondary"}
                                text={"Прикрепить файл"}
                                onClick={this.clickUpload}
                            />
                        }
                        {!this.state.file ?
                            <div className="requestForm__tip">
                                <div className="requestForm__tip_top">
                                    Не более 2 Мб
                                </div>
                                <div className="requestForm__tip_bot">
                                    Допустимые типы файлов: <br/> jpg, jpeg, png, tiff, doc, docx, xls, xlsx, pdf
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="requestForm__actions">
                        <Button type={"primary"} isDisabled={this.state.isBtnDisabled} isLoading={this.props.isSending} text={"Отправить"} onClick={this.send} />
                    </div>
                </div>
            </div>
        )
    }
}

export default RequestForm;
