import React from "react";
import { connect } from "react-redux";
import Notification from "./notification";
import NotificationFeedItem from "./notificationFeedItem";

import './styles.scss';

class NotificationFeed extends React.Component {
    positionAtBottom = process.env.REACT_APP_NOTIFEED_POSITION_AT_BOTTOM;
    constructor(props) {
        super(props);
        this.innerRef = React.createRef();
        this.lostIds = []
    }
    componentDidUpdate() {
        // this.check()
        this.check()
    }
    componentDidMount() {
    }
    check = () => {
        // console.customLog("UPAD OOFSET", this.innerRef.current.style)
        // let index = this.props.store.app.notificationFeed.map(e => e.id).indexOf(this.props.store.app.notificationFeedLastLostId);
        // console.customLog(index)
        this.lostIds.push(this.props.store.app.notificationFeedLastLostId)
        // console.customLog(this.props.store.app.notificationFeed)
        // let offsetBox = document.querySelector('.notificationsFeed__list').clientHeight
        // let offsetItem = document.querySelector('.notificationsFeed__list >*:last-child').clientHeight;
        // let offset = offsetBox - offsetItem
        // let offset = (index) * 70 + 70;
        let offset = 0
        let items = document.querySelectorAll('.notificationsFeed__list > *')
        if (!items) {
            return
        }
        // debugger
        items.forEach(el => {
            let id = el.getAttribute('id');
            // debugger
            let index = this.lostIds.indexOf(parseFloat(el.getAttribute('id')));
            console.customLog("INDEX", index, this.lostIds, id)
            if (index > -1) {
                // debugger
                offset += el.clientHeight + 8
            }
            // this.lostIds.map(id => {
            // })
        })
        if (!this.positionAtBottom) {
            offset = -offset;
        }
        this.innerRef.current.style.transform = 'translateY(' + offset + 'px)'
        // this.innerRef.current.style.backgroundColor = 'red'
    }
    buildItems = () => {
        return this.props.store.app.notificationFeed.map(el => {
            return (
                <NotificationFeedItem key={el.id} id={el.id} {...el} />
            )
        })
    }
    render() {
        let cl = "";
        cl += this.positionAtBottom ? " __bottom" : "";
        return (
            <div className={"notificationsFeed" + cl}>
                <div className="container">

                    <div className="notificationsFeed__list" ref={this.innerRef}>
                        {this.buildItems()}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    store: state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFeed);
