import React from "react";
import Form from "../../../components/form/form";
import Input from "../../../components/input/input";
import Tile from "../../../components/tile/tile";
import { ReactComponent as Brand } from "../../../assets/brand.svg"
import Button from "../../../components/button/button";
import Notification from "../../../components/notifications/notification";
import API, { collectJSONFromInput, setAuthToken, simplifyJSON } from "../../../API";
import { Navigate } from "react-router-dom";
import { getNormalError, getPhoneByMask, getPhoneRaw } from "../../../helper";

class Reg_Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isPassed: false,
            isInputsError: false,
            phone: process.env.REACT_APP_TESTDATA_REG_PHONE ? process.env.REACT_APP_TESTDATA_REG_PHONE : "",
            notification: {
                show: false,
                icon: 'icon__notification_calendar.svg',
                type: 'success',
                description: 'Передача показаний по 26 число каждого месяца'
            }
        }
        this.staticId = 'regIndex_static';
        this.input_accountRef = React.createRef();
        this.input_emailRef = React.createRef();
        this.input_phoneRef = React.createRef();
        this.input_passwordRef = React.createRef();
        this.input_passwordSuccessRef = React.createRef();
    }
    handleSubmit = () => {
        this.setState({...this.state, isInputsError: false})
        console.customLog('subnit')
        let json = collectJSONFromInput([this.input_accountRef, this.input_phoneRef, this.input_emailRef, this.input_passwordRef, this.input_passwordSuccessRef])
        
        if(json[3].value !== json[4].value) {
            this.setState({...this.state, isInputsError: true}, () => {
                this.throwError('Пароли не совпадают')
            })
            return;
        }
        json = simplifyJSON(json)
        
        this.setState({ ...this.state, isLoading: true, notification: { show: false } }, () => {
            API.post('/auth/sign-up', json).then(res => {
                console.customLog(res.data);
                if (res.data.token) {
                    setAuthToken(res.data.token)
                    this.setState({ ...this.state, isLoading: false, isPassed: true })
                }
            }).catch(err => {
                console.customLog(err);
                this.throwError(err.response.data.message);
            })
        })
    }
    throwError = (text) => {
        let error = getNormalError(text);
        this.setState({
            ...this.state, isLoading: false, notification: {
                show: true,
                icon: 'icon__page_profile.svg',
                type: 'warning',
                description: error,
            }
        })
    }
    render() {
        console.customLog(this.state.isInputsError)
        if (this.state.isPassed) {
            return <Navigate to={"/registration/code"} replace={true} state={{ account: this.input_accountRef.current.getInputData() }} />
        } else {

            return (
                <Tile className={"__facelifted"} >
                    <Form
                        onSubmit={this.handleSubmit}
                        header={{
                            image: <Brand />,
                            title: "Регистрация",
                            back: true,
                        }}
                        notification={<Notification
                            iconName={this.state.notification.icon}
                            type={this.state.notification.type}
                            description={this.state.notification.description}
                            show={this.state.notification.show}
                        />}
                        footer={{
                            actionsAsCol: true,
                            actions: [
                                <Button type="mainPrimary" prop="button" text="Продолжить" isLoading={this.state.isLoading} key={this.staticId + '_btn_0'} />
                            ]
                        }}
                    >
                        <Input
                            title={"Номер лицевого счёта:"}
                            type={"text"}
                            name={"account"}
                            value={process.env.REACT_APP_TESTDATA_REG_ACCOUNT}
                            ref={this.input_accountRef}
                        />
                        <Input
                            title={"E-mail:"}
                            type={"email"}
                            name={"email"}
                            value={process.env.REACT_APP_TESTDATA_REG_EMAIL}
                            ref={this.input_emailRef}
                        />
                        <Input
                            title={"Телефон:"}
                            type={"phone"}
                            name={"phone"}
                            hardValue={getPhoneByMask(this.state.phone)}
                            ref={this.input_phoneRef}
                            onChange={e => this.setState({...this.state, phone: getPhoneRaw(e.target.value)})}
                        />
                        <Input
                            title={"Пароль:"}
                            type={"password"}
                            name={"password"}
                            value={process.env.REACT_APP_TESTDATA_REG_PASSWORD}
                            isError={this.state.isInputsError}
                            ref={this.input_passwordRef}
                        />
                        <Input
                            title={"Подтвердите пароль:"}
                            type={"password"}
                            name={"passwordSuccess"}
                            value={process.env.REACT_APP_TESTDATA_REG_PASSWORD}
                            isError={this.state.isInputsError}
                            ref={this.input_passwordSuccessRef}
                        />
                    </Form>
                </Tile>
            )
        }
    }
}

export default Reg_Index;
