import React from "react";
import { connect } from "react-redux";
import API from "../../API";
import Button from "../../components/button/button";
import Chart from "../../components/chart/chart";
import Icon from "../../components/icon/icon";
import Meter from "../../components/meter/meter";
import Notification from "../../components/notifications/notification";
import Table from "../../components/table/table";
import Tile from "../../components/tile/tile";
import HomeHistoryTile from './tile_history'
import HomeConsumptionTile from "./tile_consumption"
import HomeViewTile from "./tile_view"
import HomeInfoTile from "./tile_info"
import { getFormattedPrice, getZoneTypeByArrLength } from "../../helper";
import { withRouter } from "../../withRouter";
import { setAccountSelect, setAlreadyShowedBillModal, setAlreadyShowedPhoneModal } from "../../store/slices/app";
import Modal from "../../components/modal/table";

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isActive: false,
            paymentHistory: {
                account: '',
                data: []
            }
        }
        this.needPhoneModal = React.createRef();
        this.disablePaperBillModal = React.createRef();
        this.staticId = 'home_' + this.props.store.app.account.number
        // console.customLog(this.props)
    }
    componentDidMount() {
        setTimeout(() => { this.setState({ ...this.state, isActive: true }) }, 1)

        if (!this.props.params.accountId) {
            this.props.navigate('/' + this.props.store.app.account.number)
        } else {
            if (this.props.params.accountId !== this.props.store.app.account.number) {
                this.staticId = 'home_' + this.props.store.app.account.number
                this.props.setAccountSelect(this.props.params.accountId)
            }
        }
    }
    onInfoLoad = () => {
        setTimeout(() => {
            if (this.props.store.app.account.main) {
                if (!parseInt(this.props.store.app.profile.phone)) {
                    if (!this.props.store.app.ui.alreadyShowedPhoneModal) {
                        this.needPhoneModal.current.show()
                        this.props.setAlreadyShowedPhoneModal(true)
                    }
                } else {
                    if (this.props.store.app.account.paperBill) {
                        if (!this.props.store.app.ui.alreadyShowedBillModal) {
                            this.disablePaperBillModal.current.show()
                            this.props.setAlreadyShowedBillModal(true)
                        }
                    }
                }
            }
        }, 500)
    }
    render() {
        let cl = this.state.isActive ? " __visible" : "";
        return (
            <>
                <div className={"page _home" + cl}>
                    <div className="container">
                        <div className="_inner">
                            <div className="tiles __grid">
                                <HomeViewTile />
                                <HomeInfoTile onLoad={this.onInfoLoad} />
                                <HomeConsumptionTile />
                                <HomeHistoryTile />
                            </div>
                        </div>
                    </div>
                </div >

                <Modal
                    ref={this.needPhoneModal}
                    icon={<Icon src="icon__confirm_modal.svg" />}
                    title={'Рекомендуем заполнить поле «Телефон»'}
                    actions={[
                        <Button
                        key={this.staticId + '_btn_0'}
                            onClick={() => {
                                this.needPhoneModal.current.hide();
                                setTimeout(() => {
                                    this.props.navigate('/profile/personal')
                                }, 400)
                            }}
                            type={"primary"}
                            text={"Продолжить"}
                        />,
                        <Button
                        key={this.staticId + '_btn_1'}
                            onClick={() => { this.needPhoneModal.current.hide() }}
                            type={"secondary"}
                            text={"Отмена"}
                        />
                    ]}
                />
                <Modal
                    ref={this.disablePaperBillModal}
                    icon={<Icon src="icon__confirm_modal.svg" />}
                    title={'Предлагаем отказаться от бумажного счёта'}
                    actions={[
                        <Button
                        key={this.staticId + '_btn_2'}
                            onClick={() => {
                                this.disablePaperBillModal.current.hide();
                                setTimeout(() => {
                                    this.props.navigate('/profile/accounts')
                                }, 400)
                            }}
                            type={"primary"}
                            text={"Продолжить"}
                        />,
                        <Button
                        key={this.staticId + '_btn_3'}
                            onClick={() => { this.disablePaperBillModal.current.hide() }}
                            type={"secondary"}
                            text={"Отмена"}
                        />
                    ]}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state,
});

const mapDispatchToProps = {
    setAccountSelect: setAccountSelect,
    setAlreadyShowedPhoneModal: setAlreadyShowedPhoneModal,
    setAlreadyShowedBillModal: setAlreadyShowedBillModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
